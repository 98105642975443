import { apiEnvMap, PRD_HOST } from '@/config'

import { hybStorage } from './storage'

// product 标示
export const isPrdEnv = window.location.href.indexOf(PRD_HOST) > -1

export const defaultApiDomain =
  hybStorage.get('dev_env') === 'formal'
    ? apiEnvMap.formal
    : apiEnvMap[hybStorage.get('dev_env') || 'qa1']
export const defaultApiEnv = hybStorage.get('dev_env') || 'qa1'

export function getEnvironmentFromUrl(url: string) {
  const matches = url.match(/cloud-test\.huiyunban\.cn\/([\w-]+)-(\w+)/)
  if (url.includes('huiyunban.cn')) {
    if (matches && matches.length === 3) {
      const [, , env] = matches
      switch (env) {
        case 'qa1':
        case 'qa2':
        case 'qa3':
        case 'qa4':
          return env
        case 'sandbox':
          return 'sandbox'
        default:
          return 'qa1'
      }
    } else if (url.includes(PRD_HOST)) {
      return 'formal'
    }
    return 'qa1'
  } else {
    if (matches && matches.length === 3) {
      const [, , env] = matches
      switch (env) {
        case 'qa1':
        case 'qa2':
        case 'qa3':
        case 'qa4':
          return env
        case 'sandbox':
          return 'sandbox'
        default:
          return 'qa1'
      }
    } else if (url.includes(PRD_HOST)) {
      return 'formal'
    }
    return 'qa1'
  }
}

/**
 *  获取 项目环境  true 为线上环境  false/测试环境和预上线环境
 * @returns true/false
 */
export function getProjectEnv() {
  return location.href.indexOf(PRD_HOST) > -1
}

export function getApiEnv() {
  const defaultEnv = getEnvironmentFromUrl(location.href)
  const env = hybStorage.get('dev_env') || defaultEnv
  return env
}

// 非线上环境 初始化api接口环境
export const initApiDevEnv = (newEnv?: string) => {
  // 线上环境，不初始化环境
  if (!getProjectEnv()) {
    const env = getApiEnv()
    setApiEnv(newEnv || env)
    if (newEnv && newEnv !== env && env !== 'formal') {
      location.reload()
    }
  }
}

export const setApiEnv = (env: string) => {
  hybStorage.set('dev_env', env)
}
