import classNames from 'classnames'
import { omit } from 'lodash-es'

import { renderFormItem } from '../../utils'

export const publicEvents = {
  /**
   * 通用onChange时间
   * @param e 控件值
   * this.extra 额外参数
   */
  onChange: function (e?: any, type?: string) {
    const trigger = renderFormItem(type || this.type)?.trigger || 'onChange'
    this.onBeforeChange?.(e, this.extra)
    typeof this[trigger] === 'function' && this[trigger]?.(e, this.extra)
    if (this.onAfterChange || this.elementProps?.onAfterChange) {
      this.globalProps?.onAfterChangeExec?.(
        this.onAfterChange || this.elementProps?.onAfterChange,
        this.globalProps?.state,
        this.globalProps?.option,
        e
      )
    }
  }
}

/**
 * 所有控件公共属性（参数组装）
 * @param props 所有参数
 * @param className h-*
 * @returns elementProps
 */
export const publicProps = (props: any, className: string) => {
  const _props = Object.entries(props)
    .filter(([key, value]: [string, unknown]) => value !== undefined)
    .reduce((obj: any, [key, value]: [string, unknown]) => {
      return {
        ...obj,
        [key]: value
      }
    }, {})
  const elementProps = {
    ...omit(_props, ['elementProps', 'formItemProps', 'globalProps']),
    ...props.elementProps,
    //  className取值
    className: classNames(
      { [`h-${className}`]: !!className },
      props.className,
      props.elementProps?.className
    ),
    placeholder: props.disabled ? '' : props.placeholder,
    // 公共onChange
    onChange: (e: any) => publicEvents.onChange.call(props, e)
  }
  return elementProps
}
