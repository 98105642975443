import { List, Checkbox } from 'antd-mobile'
import { cloneDeep } from 'lodash-es'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import HCell from '../../HYBVant/HCell'
import HPopup from '../../HYBVant/HPopup'

import './index.less'

const CheckboxSelect: React.FC<any> = ({ onConfirm, value, ...elementProps }: any) => {
  const [show, setShow] = useState(false)
  const [values, setValues] = useState<any[]>([])
  const [name, setName] = useState('')

  const valuesRef = useRef<any>()
  valuesRef.current = values

  const setCheckbox = (item: any) => {
    const matchIndex = values?.findIndex((_: any) => item.value === _)
    if (matchIndex > -1) {
      const _values = cloneDeep(valuesRef.current)
      _values.splice(matchIndex)
      setValues(_values)
    } else {
      const _values = valuesRef.current ? cloneDeep(valuesRef.current) : []
      _values.push(item.value)
      setValues(_values)
    }
  }
  const joinNamesByValues = useCallback(
    (value_data) => {
      return elementProps?.dataList
        ?.filter((x: any) => value_data?.includes(x?.value))
        .map((item: any) => item.name)
        .join('；')
    },
    [elementProps?.dataList]
  )

  useEffect(() => {
    setValues(value)
    setName(joinNamesByValues(value))
  }, [value, joinNamesByValues])

  console.log(value, 'checkbox:', values, elementProps?.dataList)

  return (
    <>
      <HCell value={name} onClick={() => setShow(true)} />
      <HPopup
        visible={show}
        onCancel={() => setShow(false)}
        onOk={() => {
          setShow(false)
          setName(joinNamesByValues(values))
          onConfirm?.(values)
        }}
      >
        <div className='hselect-popup-ridio-context'>
          <List>
            {elementProps?.dataList.map((x) => (
              <List.Item
                key={`${x.id}_${x.value}`}
                title={x.name}
                clickable
                arrowIcon={false}
                onClick={() => !x?.disabled && setCheckbox(x)}
                extra={<Checkbox disabled={x?.disabled} checked={values?.includes(x.value)} />}
              />
            ))}
          </List>
        </div>
      </HPopup>
    </>
  )
}

export default CheckboxSelect
