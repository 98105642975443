import http from '@/utils/http'
export const employeeSelectorApi = (data?: any): any =>
  http.post(`/selector/`, { selector_code: 'employee', ...data })

// export const getemployeeApi = (data: any): any => http.post(`/hrm/employees`, { data })

// export const employeeApi = (data: any): any => http.post(`/hrm/simple/employees`, { data })

// export const organizeSearchApi = (data: any): any =>
//   http.post(`/hrm/organizations/search`, { data })

// export const organizeNoAuthSearchApi = (data: any): any =>
//   http.post(`/hrm/organizations/search/all`, { data })

// export const postSearchApi = () => http.post('/hrm/post/search', { data: { paged: false } })

// export const positionSearchApi = () => http.post('/hrm/positions', { data: { paged: false } })

// export const rankSearchApi = () => http.post('/hrm/position/ranks', { data: { paged: false } })

export const organizeSearchMap: any = {
  Auth: (data: any): any => http.post(`/hrm/organizations/search`, data, { loading: false }),
  NoAuth: (data: any): any => http.post(`/hrm/organizations/search/all`, data, { loading: false })
}
