export const dateFormat = 'YYYY-MM-DD'
export const monthFormat = 'YYYY-MM'
export const weekFormat = 'MM-DD'
export const yearFormat = 'YYYY'

export const dateFormatMap: Record<string, any> = {
  date: dateFormat,
  month: monthFormat,
  week: weekFormat,
  year: yearFormat
}

export const dateTypeMap: Record<string, any> = {
  date: 'date',
  month: 'year-month',
  week: 'date',
  year: 'date'
}

export const getFormButtons = ({
  hasDelete,
  deleteInfo
}: {
  hasDelete?: boolean
  deleteInfo?: Function
}) => {
  const btns = [
    {
      key: 'remove',
      title: '删除',
      className: 'delete-btn',
      onClick: deleteInfo
    },
    {
      key: 'submit',
      title: '保存'
    }
  ]
  return hasDelete ? btns : btns.slice(1)
}
