import { observer } from 'mobx-react-lite'

import { getTypeof, isNotEmptyArray } from '@/utils'

import HCell from '../../HYBVant/HCell'
import usePopup from '../../HYBVant/HPopup/hooks/usePopup'

import PersonnelSelectPopup from './PersonnelSelectPopup'

import './index.less'
import { getemployeeApi } from './PersonnelSelectPopup/services'

interface PersonnelSelectProps {
  value?: any
  onChange?: (value: any) => void
  type?: string
  [key: string]: any
}

function PersonnelSelect(props: PersonnelSelectProps) {
  const { value, onChange, onAfterChange } = props
  const { open, onOpen, onOk, onCancel } = usePopup({
    afterOk: (data?: any) => {
      onChange?.(data)
      onAfterChange?.(data)
      return Promise.resolve()
    }
  })
  console.log('open.value', value, onChange)
  const title = (() => {
    if (!isNotEmptyArray(value)) {
      return ''
    }
    return `${value
      .slice(0, 3)
      .map?.((item: any) => item?.name)
      ?.join?.('、')}${value?.length > 3 ? `等${value?.length}人` : ''}`
  })()
  const bool: boolean = !props?.disabled && !props?.readed

  return (
    <>
      <HCell
        value={title}
        readed={props?.readed}
        onClick={() => bool && onOpen()}
        disabled={props?.disabled}
      />
      {open && (
        <PersonnelSelectPopup {...props} visible={open} onConfirm={onOk} onCancel={onCancel} />
      )}
    </>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
PersonnelSelect.convertValue = async (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
    // console.log('[config?.names[0]]:', values, [config?.names[0]], values[config?.names[0]])
    // 单选
    if (config?.mode === 'single' && values?.[config?.names[0]] && values?.[config?.names[1]]) {
      return {
        [config?.names[0]]: [
          {
            id: values?.[config?.names[0]],
            name: values?.[config?.names[1]]
          }
        ]
      }
    }
  }
  if (config?.name) {
    return values?.[config.name]
  }
  if (value?.length === 1 && value?.[0]?.id && !value?.[0]?.name && config?.mode === 'single') {
    const { success, data } = await getemployeeApi({
      employee_ids: [value?.[0]?.id],
      with_no_auth: true
    })
    if (success && data?.[0]?.name) {
      return [
        {
          id: data[0].id,
          name: data[0].name
        }
      ]
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
PersonnelSelect.transform = (value: any, config?: any) => {
  if (Array.isArray(value) && Array.isArray(config?.names) && config?.names?.length === 2) {
    // 单选
    if (config?.mode === 'single') {
      return {
        [config?.names[0]]: value?.[0]?.id,
        [config?.names[1]]: value?.[0]?.name
      }
    }
    return {
      [config?.names[0]]: value.map((item: any) => item?.id),
      [config?.names[1]]: value.map((item: any) => item?.name)
    }
  }
  // 单选
  if (Array.isArray(value) && config?.mode === 'single') {
    return value?.[0]?.id
  }
  // 多选
  if (Array.isArray(value)) {
    return value.map((item: any) => item?.id)
  }
  return value
}

export default observer(PersonnelSelect)
