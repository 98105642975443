import { http } from '@/utils'
import { createAxios } from '@/utils/http'
import service from '@/utils/service'

// 获取信息
export const getUserInfo = (): any => createAxios().get(`/portal/accounts`, {})

// 租户列表
export const getTenantList = (
  data: { mobile: string; unionid: string } = { mobile: '', unionid: '' }
): any => createAxios().post(`/portal/accounts/tenants`, data)
// 切换租户
export const changeTenants = (tenant_id: number | undefined): any =>
  http.post(`/portal/accounts/${tenant_id}/quick_login`, {})

// 腾讯地图根据经纬度获取地址名称
export const getLocations = (data: any): any =>
  http.get(`/common/lbs/location`, { ...data, noloading: true })

// 获取字典值
export const getDictListGroup = (data: any): any =>
  http.post('/system/dictionary_selector/group', data, { loading: false })

export const getNoLoginDictListGroup = (data: any): any =>
  http.post('/hrm/other/dictionary_selector/group', data)

// 获取功能点权限
export const getPermission = (data: any): any => http.get('/portal/common/applications', data)

export const attendanceStockApi = (): any => http.get(`/attendance/stock/stock/total`, {})
// 获取字典管理列表
export const systemDictListGroup = (data: any): any => http.post('/system/dictionary_list', data)

// 新的权限
export const getPermissionApi = (data: any): any =>
  http.post('/console/role/employee/permission', data)
// 获取常用菜单
export const menuCommonlyUsedList = () => http.get(`/console/menu/commonly_used/list`, {})

export const untreatedCount = (): any => http.get(`/workbench/untreated_count`, {})
