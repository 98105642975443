import { SearchSelectApi } from './SearchSelectPopup/constant'

export default function () {
  const fetchData = () => {
    const fomatdata = (list: any[] = [], fieldNames: any) => {
      const { value, name, code } = fieldNames || {}
      const data = list?.map((x) => ({
        ...x,
        id: x[value || 'id'],
        name: x[name || 'name'],
        value: x[value || 'id'],
        label: x[name || 'name'],
        code: x[code || 'code']
      }))
      return {
        data
      }
    }

    const getFetchList = async (props: any) => {
      const { type, options, dataList } = props
      const list: any[] = options || dataList
      const localOrDictionary: boolean = list && Array.isArray(list)
      if (localOrDictionary) {
        return Promise.resolve({ data: list })
      }

      if (!localOrDictionary) {
        return await SearchSelectApi[type]?.()
      }
      return Promise.resolve({ data: list })
    }
    return {
      fomatdata,
      getFetchList
    }
  }

  const format = () => {
    const transformvalue = (popup: string, data: any) =>
      popup === 'array' ? data : data?.[0]?.value
    const transformtitle = (props: any) => {
      const { list, popup, checkedValue } = props
      const title =
        popup === 'array'
          ? checkedValue?.map?.((item: any) => item?.name)?.join?.('、')
          : list?.find((x: any) => x.value === checkedValue)?.name
      return title
    }
    const transformdefaultvalue = (props: any) => {
      const { list, popup, checkedValue } = props
      const value =
        popup === 'array' ? checkedValue : list?.filter((x: any) => x.value === checkedValue)
      return value
    }
    return {
      transformvalue,
      transformtitle,
      transformdefaultvalue
    }
  }

  return {
    fetchData,
    format
  }
}
