import { useRequest, useDeepCompareEffect } from 'ahooks'
import { Form } from 'antd-mobile'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import NewEditForm from '@/components/NewEditForm'
import jobStore from '@/store/components/entryJob'
import { getDateTextHandler, mapValuesAndTexts } from '@/utils/index'

import HPopup from '../../HYBVant/HPopup'

import config from './config.json'
import { saveHealth, deleteHrmHealth, healthsEmployee } from './service'
import './index.less'

const HealthCertificate: React.FC<any> = ({ visible, onCancel, modalData }) => {
  const [formIt] = Form.useForm()
  const { editHealthItem, employeeIds, healthCertificateStatus, changeAllHealthCertificate } =
    useLocalObservable(() => jobStore)
  // 保存健康证
  const { run: runSaveHealth, loading: saveHealthLoading } = useRequest(saveHealth, {
    manual: true,
    onSuccess: (result: any) => {
      const { code } = result
      if (code === 2000) {
        detailsOk()
      }
    },
    onError: ({ message }) => {
      console.log('error', message)
    }
  })
  // 删除健康证
  const { run: runDeleteHrmHealth, loading: deleteHrmHealthLoading } = useRequest(deleteHrmHealth, {
    manual: true,
    onSuccess: (result: any) => {
      const { code } = result
      if (code === 2000) {
        detailsOk()
      }
    },
    onError: ({ message }) => {
      console.log('error', message)
    }
  })
  const onFinish = (fieldValues: any) => {
    if (saveHealthLoading || deleteHrmHealthLoading) return
    const fieldValue: any = {
      employee_ids: employeeIds
    }
    if (editHealthItem?.id && healthCertificateStatus === 'Edit') {
      fieldValue.id = editHealthItem?.id
    }
    fieldValue.healths = [
      {
        ...fieldValues,
        start_time: getDateTextHandler({
          dateInfo: fieldValues?.start_time,
          dateType: 'formatEchoDate'
        }),
        end_time: getDateTextHandler({
          dateInfo: fieldValues?.end_time,
          dateType: 'formatEchoDate'
        })
      }
    ]
    runSaveHealth(fieldValue)
  }
  const detailsOk = () => {
    runHhealthsEmployee({
      paged: false,
      cur_page: 0,
      page_size: 999,
      employee_id_list: employeeIds
    })
  }
  // 员工健康证
  const { run: runHhealthsEmployee } = useRequest(healthsEmployee, {
    manual: true,
    onSuccess: (result: any) => {
      const { data } = result
      changeAllHealthCertificate(data)
      onCancel()
    },
    onError: ({ message }) => {
      console.log('error', message)
      changeAllHealthCertificate([])
    }
  })

  useDeepCompareEffect(() => {
    const setFieldValues = (bool: boolean) => {
      const fields = editHealthItem
      const start_time = fields?.health_start_at || ''
      const end_time = fields?.health_end_at || ''
      const health_imgs = fields?.health_certificate_list || []
      const health_type = fields?.health_type || 'GENERAL_HEALTH_CERTIFICATE'
      const health_certificates = health_imgs?.map((x: any) => x?.oss_key) || []
      const health_info = bool
        ? {
            start_time,
            end_time,
            health_certificates,
            health_type,
            city_id: fields.city_codes
          }
        : { health_type }
      formIt.setFieldsValue(health_info)
    }
    setFieldValues(healthCertificateStatus === 'Edit')
  }, [healthCertificateStatus, editHealthItem])
  const deleteInfo = () => {
    if (healthCertificateStatus === 'Edit') {
      const fields = editHealthItem
      const id = fields?.id
      const ids = id ? [id] : null
      runDeleteHrmHealth({ id: ids })
    }
  }
  const getFormButtons = (hasDelete?: boolean) => {
    const btns = [
      {
        key: 'remove',
        title: '删除',
        className: 'delete-btn',
        onClick: deleteInfo
      },
      {
        key: 'submit',
        title: '保存'
      }
    ]
    return hasDelete ? btns : btns.slice(1)
  }

  return (
    <HPopup
      title='健康证'
      hasOk={false}
      className='lg'
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      <NewEditForm
        formInst={formIt}
        formInfo={config.formInfo ?? []}
        initialValues={{ health_type: 'GENERAL_HEALTH_CERTIFICATE' }}
        onFinish={onFinish}
        formButtons={getFormButtons(modalData?.status === 'Edit')}
      />
    </HPopup>
  )
}

export default observer(HealthCertificate)
