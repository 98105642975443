import { Dialog } from 'antd-mobile'
import { JSSDK, LocationResponse } from '../interface'
/**
 * h5应用原生实现（WebRTC）
 * 参考：https://developer.work.weixin.qq.com/document/path/94315
 */
class H5 implements JSSDK {
  silenceLogin(data: { corp_id: string; code: string }, config?: any): Promise<IResponseType<any>> {
    throw new Error('Method not implemented.')
  }
  apiAuthStatus: 'pending' | 'done' | 'error' | undefined = 'pending'
  locationWatchId: number | undefined
  checkAuth(authType: string): Promise<boolean> {
    switch (authType) {
      case 'location':
        console.log('h5.checkAuth start')
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              () => resolve(true), // 如果成功获取位置，则表示有权限
              (e) => {
                console.log('错误回调：', e)
                resolve(true)
              },
              {
                timeout: 1000
              }
            )
          } else {
            reject(new Error('Geolocation is not supported by this browser.'))
          }
        })
      default:
        return Promise.resolve(false)
    }
  }
  getLocation(): Promise<any> {
    console.log('h5环境获取位置 start')
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (res: any) => resolve(res?.coords), // 如果成功获取位置，则表示有权限
          (err: any) => reject(err) // 如果失败，则可能没有权限
        )
      } else {
        reject(new Error('Geolocation is not supported by this browser.'))
      }
    })
  }
  getHighLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (res: any) => resolve(res?.coords),
          (err: any) => reject(err),
          { enableHighAccuracy: true }
        )
      } else {
        reject(new Error('Geolocation is not supported by this browser.'))
      }
    })
  }
  authorize(authType: string): Promise<boolean> {
    throw new Error('Method not implemented.')
  }
  async openSystemSetting(param?: any): Promise<void> {
    throw new Error('Method not implemented.')
  }
  config({ api, corpId }?: { api?: string | string[]; corpId?: string }): Promise<any> {
    throw new Error('Method not implemented.')
  }
  getAuthCode({
    corpId,
    agentId
  }: {
    corpId: string
    agentId?: string
  }): Promise<{ code: string; agentId?: string }> {
    throw new Error('Method not implemented.')
  }
  startLocation(): Promise<LocationResponse> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        this.locationWatchId = navigator.geolocation.watchPosition(
          (res: any) => resolve(res?.coords),
          (err: any) => reject(err),
          { enableHighAccuracy: true }
        )
      } else {
        reject(new Error('Geolocation is not supported by this browser.'))
      }
    })
  }
  stopLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        this.locationWatchId && navigator.geolocation.clearWatch(this.locationWatchId)
      } else {
        reject(new Error('Geolocation is not supported by this browser.'))
      }
    })
  }
  scanCode(): Promise<string | undefined> {
    throw new Error('Method not implemented.')
  }
  takePhoto(param?: any): Promise<string> {
    throw new Error('Method not implemented.')
  }
  setTitle(params?: any): Promise<void | {}> {
    throw new Error('Method not implemented.')
  }
  getImageInfo(src?: string): Promise<string> {
    throw new Error('Method not implemented.')
  }
}
export default new H5()
