import ButtonGroup from './UILibrary/HYBElement/ButtonGroup'
import CalendarCascader from './UILibrary/HYBElement/CalendarCascader'
import CalendarDatePicker from './UILibrary/HYBElement/CalendarDatePicker'
import Cascader from './UILibrary/HYBElement/Cascader'
import CheckboxSelect from './UILibrary/HYBElement/CheckboxSelect'
import CityCascader from './UILibrary/HYBElement/CityCascader'
import Concat from './UILibrary/HYBElement/Contact'
import DatePickerAll from './UILibrary/HYBElement/DatePickerAll'
import DatetimePickerBox from './UILibrary/HYBElement/DatetimePickerBox'
import Education from './UILibrary/HYBElement/Education'
import FlowPath from './UILibrary/HYBElement/FlowPath'
import FormFileImage from './UILibrary/HYBElement/FormFileImage'
import FormImage from './UILibrary/HYBElement/FormImage'
import GroupSelect from './UILibrary/HYBElement/GroupSelect'
import HealthCertificate from './UILibrary/HYBElement/HealthCertificate'
import InputNumberRange from './UILibrary/HYBElement/InputNumberRange'
import InputSelect from './UILibrary/HYBElement/InputSelect'
import InputWithImage from './UILibrary/HYBElement/InputWithImage'
import ListSelect from './UILibrary/HYBElement/ListSelect'
import LocationSelect from './UILibrary/HYBElement/LocationSelect'
import ModelPage from './UILibrary/HYBElement/ModelPage'
import Organize from './UILibrary/HYBElement/Organize'
import OrganizeSelect from './UILibrary/HYBElement/OrganizeSelect'
import Personnel from './UILibrary/HYBElement/Personnel'
import PickerBox from './UILibrary/HYBElement/PickerBox'
import Reshuffle from './UILibrary/HYBElement/Reshuffle'
import RidioSelect from './UILibrary/HYBElement/RidioSelect'
import SearchSelect from './UILibrary/HYBElement/SearchSelect'
import TimePicker from './UILibrary/HYBElement/TimePicker'
import TodayPicker from './UILibrary/HYBElement/TodayPicker'
import HCalendar from './UILibrary/HYBVant/HCalendar'
import HCell from './UILibrary/HYBVant/HCell'
import HInput from './UILibrary/HYBVant/HInput'
import HRadioGroup from './UILibrary/HYBVant/HRadioGroup'
import HSwitch from './UILibrary/HYBVant/HSwitch'
import HTextArea from './UILibrary/HYBVant/HTextArea'

/**
 * 组件映射
 */
const mapping: { [key: string]: any } = {
  Cell: HCell,
  DatePicker: DatetimePickerBox,
  Input: HInput,
  Select: PickerBox,
  RadioGroup: HRadioGroup,
  Textarea: HTextArea,
  TextArea: HTextArea,
  Switch: HSwitch,
  FormImage,
  FormFileImage,
  Education,
  HealthCertificate,
  Concat,
  ModelPage,
  // ApplyPersonnelSelect,
  // PersonnelSelect,
  FlowPath,
  Cascader,
  OrganizeSelect,
  RidioSelect,
  TodayPicker,
  GroupSelect,
  LocationSelect,
  InputWithImage,
  CityCascader,
  Personnel,
  Organize,
  Reshuffle,
  ButtonGroup,
  SearchSelect,
  InputNumberRange,
  Calendar: HCalendar,
  InputSelect,
  CheckboxSelect,
  DatePickerAll,
  TimePicker,
  CalendarCascader,
  CalendarDatePicker,
  ListSelect
}
export default mapping
