import { Image } from 'antd-mobile'
import { AddSquareOutline } from 'antd-mobile-icons'
import { isEmpty } from 'lodash-es'
import { useLocalStore, observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import jobStore from '@/store/components/entryJob'

import Popup from './Popup'
import { dynamicFieldsList } from './service'
import './index.less'

const ModelPage: React.FC<any> = ({ onConfirm, value, ...rest }) => {
  const { qrcodeId: _qrcodeId, modelPage, changeModelPageConfig } = useLocalStore(() => jobStore)

  const { open, onCancel, onOpen } = usePopup({})

  const { code = '', isAsync = false, staffNameInfo = {}, title = '', qrCodeId = '' } = rest || {}

  const { list = [] } = modelPage?.[code || ''] || {}

  const add = () => {
    changeModelPageConfig(code, { status: 'Add' })
    onOpen()
  }

  const fetchDynamicFieldsList = async () => {
    const { code: asCode, data } = await dynamicFieldsList(
      staffNameInfo?.employee_id || '',
      code || ''
    )
    if (asCode === 2000) {
      changeModelPageConfig(code, {
        list: (data || [])?.map((x: any) => ({ ...x?.field_value, id: x?.id }))
      })
    }
  }

  const edit = (index: number) => {
    changeModelPageConfig(code, {
      status: 'Edit',
      list: list?.map((x: any, i: number) => {
        if (index === i) {
          x.active = 1
        } else {
          x.active = 0
        }
        return x
      })
    })
    onOpen()
  }

  useEffect(() => {
    isAsync && fetchDynamicFieldsList()
  }, [code, isAsync])

  useEffect(() => {
    onConfirm?.(list)
  }, [list?.length])

  console.log(qrCodeId, list, _qrcodeId, onConfirm, 'modelpage>>>>')

  return (
    <div className='hyb-com-concat'>
      {!isEmpty(list) && (
        <div className='hyb-com-concat-content-wrapper'>
          {(list || []).map((x: any, index: number) => (
            <div key={x.name} className='hyb-com-concat-content'>
              <div className='hyb-com-concat-content-col-1'>
                <div className='hyb-com-concat-content-1'>{x?.SUO_ZAI_GONG_SI}</div>
                <div className='hyb-com-concat-content-2'>{x?.ZHI_WEI}</div>
                <div className='hyb-com-concat-content-3'>
                  {x?.KAI_SHI_NIAN_YUE || '-'}&nbsp;&nbsp;至&nbsp;&nbsp;
                  {x?.JIE_SHU_NIAN_YUE || '-'}
                </div>
              </div>
              <div className='hyb-com-concat-content-col-2'>
                <Image
                  onClick={() => edit(index)}
                  className='hyb-com-concat-content-col-2-img'
                  src='https://s.huiyunban.cn/1747191709529280512.png'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='hyb-com-concat-tip'>
        <AddSquareOutline />
        <div className='hyb-com-concat-tip-title' onClick={add}>
          添加{title || ''}
        </div>
      </div>
      {open && <Popup visible={open} onCancel={onCancel} popupData={rest} />}
    </div>
  )
}

export default observer(ModelPage)
