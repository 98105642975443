import { Space } from 'antd-mobile'
import React, { lazy, Suspense } from 'react'

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */
const lazyLoad = (fn: () => Promise<{ default: any }>): React.ReactNode => {
  const Comp: React.LazyExoticComponent<any> = lazy(fn)
  return (
    <Suspense
      fallback={
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        />
      }
    >
      <Comp />
    </Suspense>
  )
}

export default lazyLoad
