import dayjs from 'dayjs'

import { ClockClass, InOrOutMap, SlotStatus, SlotStatusClass, ApprovalStatus } from '../constant'

export default (date?: string) => {
  const getDay = (item: any) => {
    const today = dayjs().format('YYYY-MM-DD')
    const currentDay = date || today
    const day = item?.time?.slice(0, 10)
    if (dayjs(day).isBefore(currentDay, 'day')) return '昨'
    if (dayjs(day).isAfter(currentDay, 'day')) return '次'
    return ''
  }
  const getTime = (item: any) => getDay(item) + item?.time?.slice(11)
  const getTitle = (item: any) => `${InOrOutMap[item?.in_or_out]} ${getTime(item)}`
  const getStatus = (item: any) => (item.type === 7 ? '外勤缺卡' : SlotStatus[item.solt_status])
  const getStatusClass = (item: any) => {
    // 审批中无效卡
    const isOuting =
      item.within_geofence === 0 && (item.approval_status === 1 || item.valid === 'INVALID')
    // 无审批无效卡
    const isOuting2 =
      item.within_geofence === 0 && item.approval_status === 0 && item.valid === 'INVALID'
    if (isOuting || isOuting2) {
      return SlotStatusClass[1]
    }
    return SlotStatusClass[item.solt_status]
  }
  const getExtraClass = (item: any) => {
    // 审批中无效卡
    const isOuting =
      item.within_geofence === 0 && (item.approval_status === 1 || item.valid === 'INVALID')
    // 无审批无效卡
    const isOuting2 =
      item.within_geofence === 0 && item.approval_status === 0 && item.valid === 'INVALID'
    if (isOuting || isOuting2) {
      return SlotStatusClass[1]
    }
    return 'success'
  }
  const getApproval = (item: any) => {
    // // 内勤无效卡
    // const isInner = item.within_geofence === 1 && item.valid === 'INVALID'
    // // 无审批无效卡
    // const isOuting =
    //   item.within_geofence === 0 && item.approval_status === 0 && item.valid === 'INVALID'
    // 审批中
    if (item.within_geofence === 0 && item.approval_status === 1) {
      return ApprovalStatus[2]
    }
    if (item.within_geofence === 0 && item.approval_status !== 0) {
      return item.valid === 'VALID' ? ApprovalStatus[1] : ApprovalStatus[0]
    }
    if (item.valid === 'INVALID') {
      return ApprovalStatus[3]
    }
    return ''
  }
  const getChild = (child: any) =>
    child.type === 6 || child.type === 7
      ? {
          title: `未打卡`,
          status: getStatus(child),
          statusClass: child.type === 7 ? SlotStatusClass[3] : SlotStatusClass[child.solt_status],
          clockClass: ClockClass[child.type]
        }
      : {
          title: `${getTime(child)}已打卡`,
          status: getStatus(child),
          statusClass: getStatusClass(child),
          clockClass: ClockClass[child.type],
          extraStatus: child.clock_type === 1 ? '补卡' : child.within_geofence === 0 ? '外勤' : '',
          extraStatusClass: child.clock_type === 1 ? 'success' : getExtraClass(child),
          content: child.content,
          approval: getApproval(child),
          remark: child.remark,
          attachments: child.attachments || []
        }

  const transform = (data: any) => {
    return data?.map((item: any) => {
      if (item.type === 1) {
        return {
          key: item.id,
          title: getTitle(item),
          activeClass: item.current_clock ? 'active' : '',
          children: item?.clock_details?.map(getChild)
        }
      }
      return {
        key: item.id,
        title: InOrOutMap[item?.in_or_out],
        activeClass: item.current_clock ? 'active' : '',
        children: [getChild(item)]
      }
    })
  }

  return {
    transform
  }
}
