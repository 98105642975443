export const renderFormItem = (type: any) => {
  const commonFormProps = {
    valueKey: 'value',
    trigger: 'onConfirm',
    valueFormat: (e: any) => e
  }
  const formItemProps: any = {
    // Search: {
    //   valueKey: 'value',
    //   trigger: 'onConfirm',
    //   valueFormat: (e) => e.detail.value
    // }
  }
  const _formItemProps = formItemProps[type] || commonFormProps
  console.log(type, _formItemProps)
  return _formItemProps
}
