import { isNotEmptyArray } from '@/utils'

import { COMMON_ATTENDANCE_RESULT_MAP } from '../../const'

import './index.less'

interface AttendanceResultProps {
  /** 考勤类型 */
  type?: string
  className?: string
  dataSource: {
    type?: string
    content?: string
  }[]
}

const AttendanceResult = (
  { dataSource = [], className = '' }: AttendanceResultProps = {} as AttendanceResultProps
) => {
  const list = isNotEmptyArray(dataSource)
    ? (dataSource as any[]).map((item: any) => {
        return {
          ...COMMON_ATTENDANCE_RESULT_MAP[item.type],
          ...item
        }
      })
    : []

  if (!isNotEmptyArray(list)) {
    return null
  }

  return (
    <div className={`hyb-card-item-tag-wrap ${className}`}>
      {list.map((item: any, index: number) => {
        const { className = '', content = '' } = item || {}
        return (
          <div className={`hyb-card-item-tag ${className}`} key={`${index}`}>
            {content || ''}
          </div>
        )
      })}
    </div>
  )
}

export default AttendanceResult
