import { Image } from 'antd-mobile'
import { AddSquareOutline } from 'antd-mobile-icons'
import { isEmpty } from 'lodash-es'
import { useLocalStore, observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import jobStore from '@/store/components/entryJob'

import Popup from './Popup'
import './index.less'

const Concat: React.FC<any> = ({ onConfirm, value }) => {
  const { concat = [], changeConcatStatus, changeConcat } = useLocalStore(() => jobStore)

  const { open, modalData, onCancel, onOpen } = usePopup({})

  const add = () => {
    changeConcatStatus('Add')
    onOpen({ status: 'Add' })
  }

  const edit = (index: number) => {
    concat.map((x, i) => {
      if (index === i) {
        x.active = 1
      } else x.active = 0
      return x
    })
    changeConcatStatus('Edit')
    onOpen({ status: 'Edit' })
  }

  useEffect(() => {
    if (onConfirm) onConfirm(concat)
  }, [concat])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      !value.some((x) => concat.includes(x)) && changeConcat(value)
    }
  }, [value])

  return (
    <div className='hyb-com-concat'>
      {!isEmpty(concat) && (
        <div className='hyb-com-concat-content-wrapper'>
          {concat.map((x, index) => (
            <div key={x.name} className='hyb-com-concat-content'>
              <div className='hyb-com-concat-content-col-1'>
                <div className='hyb-com-concat-content-1'>
                  {x.full_name} {x.relationship && <span>( {x.relationship} )</span>}
                </div>
                <div className='education-content-2'>{x.contact_number}</div>
              </div>
              <div className='hyb-com-concat-content-col-2'>
                <Image
                  onClick={() => edit(index)}
                  className='hyb-com-concat-content-col-2-img'
                  src='https://s.huiyunban.cn/1747191709529280512.png'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='hyb-com-concat-tip'>
        <AddSquareOutline />
        <div className='hyb-com-concat-tip-title' onClick={add}>
          添加紧急联系人
        </div>
      </div>
      {open && <Popup visible={open} onCancel={onCancel} modalData={modalData} />}
    </div>
  )
}

export default observer(Concat)
