import dingtalk from './dingtalk'
import feishu from './feishu'
import h5 from './h5origin/h5'
import { Bridge } from './interface'
import { bridgeToast, getUserAgent } from './utils'
import wxwork from './wxwork'

/**
 * 桥接对象 dd wx fs h5 ...
 */
export const bridge: Bridge = {
  ua: getUserAgent(),
  isWebView: ['dd', 'fs', 'wxwork'].includes(getUserAgent()),
  isWx: getUserAgent() === 'wx',
  isDd: getUserAgent() === 'dd',
  isFs: getUserAgent() === 'fs',
  isWxwork: getUserAgent() === 'wxwork',
  isH5: getUserAgent() === 'h5',
  AuthKey: {
    location: 'location',
    camera: 'camera'
  },
  jssdk: {
    // 钉钉相关函数
    dd: dingtalk,
    // 飞书相关函数
    fs: feishu,
    // 企业微信相关函数
    wxwork,
    // 微信小程序相关函数（待实现）
    wx: {},
    // h5相关函数（待实现）
    // @ts-ignore
    h5
  },
  invoke(functionStr: string) {
    const errMsg = process.env.NODE_ENV === 'development' ? '不支持当前运行环境' : ''
    try {
      const env = this.jssdk[this.ua]
      if (!env) {
        throw new Error(errMsg || `运行环境未支持: ${this.ua}`)
      }
      const func = env[functionStr]
      if (!func) {
        throw new Error(errMsg || `API未实现:[${this.ua}]bridge.${functionStr}`)
      }
      console.info('call success:', this.ua, '.', functionStr)
      return func.bind(env)
    } catch (e: any) {
      !['config'].includes(functionStr) && bridgeToast(errMsg || e.message)
    }
  },
  /**
   * 静默登录
   * @param data  { corp_id: corpId, code }
   * @param config ?: { baseURL: string }
   * @returns Promise<IResponseType>
   */
  async silenceLogin(data: any, config?: any) {
    return await this.invoke('silenceLogin')?.(data, config)
  },
  /**
   * 需要使用api能力先调用初始化
   * @param api camera: 摄像头 location: 地理定位
   */
  async config({ api = '', corpId }: { api: string | string[]; corpId?: string }) {
    try {
      return await this.invoke('config')?.({ api, corpId })
    } catch (e) {
      console.error('当前环境暂不支持', e)
      return Promise.reject(e)
    }
  },
  /**
   * 检查系统设置
   * @param authType CAMERA: 摄像头 LBS: 地理定位
   */
  async checkAuth(authType: string) {
    return this.invoke('checkAuth')?.(authType)
  },
  /**
   * 引导打开系统设置
   * @param any
   */
  async openSystemSetting(param: any) {
    return this.invoke('openSystemSetting')?.(param)
  },
  /**
   * 引导打开设置钉钉权限
   * @param authType 同checkAuth
   */
  async authorize(authType: string) {
    return this.invoke('authorize')?.(authType)
  },
  /**
   * 请求授权码
   * @returns Promise<token: string>
   */
  async getAuthCode({
    corpId,
    agentId,
    clientId
  }: {
    corpId?: string
    agentId?: string
    clientId?: string
  }) {
    return this.invoke('getAuthCode')?.({ corpId, agentId, clientId }).then((info: any) => {
      if (info?.code) {
        return info.code
      } else {
        throw new Error('Unexpected response format, no code property')
      }
    })
  },
  /**
   * 获取地理位置
   */
  async getLocation() {
    return this.invoke('getLocation')?.()
  },
  /**
   * 获取高精度地理位置
   */
  async getHighLocation() {
    return this.invoke('getHighLocation')?.()
  },
  /**
   * 开始定位
   */
  async startLocation() {
    return this.invoke('startLocation')?.()
  },
  /**
   * 停止定位
   */
  async stopLocation() {
    return this.invoke('stopLocation')?.()
  },
  /**
   * 扫描二维码
   */
  async scanCode() {
    return this.invoke('scanCode')?.()
  },
  /**
   * 拍照
   */
  async takePhoto(param?: any) {
    return this.invoke('takePhoto')?.(param)
  },
  /**
   * 设置标题
   * @param params string
   * @returns Promise
   */
  async setTitle(param: any) {
    return this.invoke('setTitle')?.(param)
  },
  /**
   * 获取图片信息
   * @param params { src }
   * @returns Promise
   */
  async getImageInfo(src: string) {
    return this.invoke('getImageInfo')?.(src)
  }
}
