import { Picker, Popup } from 'antd-mobile'

import { useMemo } from 'react'

import { HErrorBlock } from '@/components'

const convertData = (arr: any[], fieldNames: any) => {
  if (!(Array.isArray(arr) && arr.length)) {
    return arr
  }
  return arr.map((item) => ({
    ...item,
    label: item?.[fieldNames?.label || 'name'],
    value: item?.[fieldNames?.value || 'value']
  }))
}

export default ({
  show,
  setState,
  value,
  onConfirm,
  onCancel,
  dataList,
  defaultIndex,
  fieldNames
}: any) => {
  const columns = useMemo(() => {
    const tempList = convertData(dataList, fieldNames)
    if (Array.isArray(tempList) && tempList.length > 0) {
      if (!Array.isArray(tempList[0])) {
        return [tempList]
      }
    }
    return tempList
  }, [dataList])

  const handleConfirm = (val) => {
    typeof onConfirm === 'function' && onConfirm(val?.[0])
  }

  return (
    <div className='select-period-code-box'>
      {dataList?.length ? (
        <Picker
          visible={show}
          columns={columns}
          // defaultValue={defaultIndex ? [defaultIndex] : []}
          value={value}
          onConfirm={handleConfirm}
          onCancel={onCancel}
        />
      ) : (
        <Popup
          position='bottom'
          visible={show}
          onClose={() =>
            setState({
              show: false
            })
          }
        >
          <div className='select-no-data-box'>
            <div className='btn-no-data-box'>
              <div className='left-box' onClick={onCancel}>
                取消
              </div>
              <div className='right-box' onClick={onCancel}>
                确认
              </div>
            </div>
            <HErrorBlock />
          </div>
        </Popup>
      )}
    </div>
  )
}
