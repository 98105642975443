// import moment from 'moment'
import dayjs from 'dayjs'

import { DATE_TYPE } from '../../const'

export const DefaultSwiperItems = new Array(101).fill(0).map((_: any, index: number) => index)
// 最大文字限制
export const getLimitText = (text: string, limit = 4) => text?.slice(0, limit)
// day format
export const DayFormatStr = 'YYYY-MM-DD'
// 获取日历组（用于swiper）
export const getCalendarMap = ({
  currentView,
  swiperIndex = 0,
  mode = 'month',
  dataSource = []
}: {
  currentView: string
  swiperIndex: number
  mode: 'month' | 'week'
  dataSource: any
}): Map<string, any> => {
  // 匹配业务数据
  const matchDayData = (dates: string | Array<string>) => {
    if (Array.isArray(dates)) {
      return dates.map(
        (date: any) =>
          dataSource?.find((item: any) => item?.date === date) || {
            date,
            date_type: DATE_TYPE.working
          }
      )
    }
    return (
      dataSource?.find((item: any) => item?.date === dates) || {
        date: dates,
        date_type: DATE_TYPE.working
      }
    )
  }
  const calenderMap = new Map()
  const indexArray: number[] = DefaultSwiperItems
  new Array(DefaultSwiperItems.length).fill(0).map((item: any, index: number) => {
    const currentSwiperIndex = indexArray[index]
    let startDate = dayjs(currentView, DayFormatStr).startOf('month').startOf('week')
    let endDate = dayjs(currentView, DayFormatStr).endOf('month').endOf('week')
    const start = dayjs(currentView, DayFormatStr).startOf('month').format(DayFormatStr)
    const end = dayjs(currentView, DayFormatStr).endOf('month').format(DayFormatStr)
    // console.log('currentSwiperIndex:', index, currentSwiperIndex, swiperIndex, currentMonthDiff)
    // while (endDate.diff(startDate, 'days') < 41) {
    //   endDate = endDate.add('w', 1)
    // }
    if (mode === 'week') {
      startDate = dayjs(currentView, DayFormatStr).startOf('week')
      endDate = dayjs(currentView, DayFormatStr).endOf('week')
    }
    const min = startDate.format(DayFormatStr)
    const max = endDate.format(DayFormatStr)
    const days: any = []
    let startPoint = startDate
    while (startPoint.isBefore(endDate)) {
      const dayData = matchDayData(startPoint.format(DayFormatStr))
      days.push(dayData)
      startPoint = startPoint.add(1, 'days')
    }
    if (mode === 'week') {
      calenderMap.set(`${currentSwiperIndex}`, {
        start: min,
        end: max,
        days
      })
    } else {
      const monthDays: any = []
      while (days.length > 0) {
        monthDays.push(days.splice(0, 7))
      }
      calenderMap.set(`${currentSwiperIndex}`, {
        start,
        end,
        days: monthDays
      })
    }
  })
  console.log('calenderMap::', swiperIndex, calenderMap)
  return calenderMap
}
