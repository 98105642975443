import { useSetState } from 'ahooks'
import { Picker } from 'antd-mobile'
import classNames from 'classnames'
import { useLocalStore, observer } from 'mobx-react'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

import HCell from '../../HYBVant/HCell'

import { AllSetting } from './constant'
import useColumns from './hooks/useColumns'
import comstore from './store'

import './index.less'

interface ScribeLineItemProps {
  value?: any
  type?: string
  visible?: boolean
  source?: string
  currentDay?: string
  title?: string
  // 1固定班，2划线班
  shiftType?: number
  onChange: (value: any) => void
  onClose?: (value: boolean) => void
  renderChildren?: (value: any[]) => void
}

const TimePicker: React.FC<ScribeLineItemProps & PropsWithChildren> = ({
  value,
  onChange,
  visible: _visible,
  type,
  source,
  onClose,
  currentDay,
  shiftType,
  title = '请选择上下班时间',
  children,
  disabled,
  readed,
  renderChildren,
  ...rest
}: any) => {
  const endPickerRef = useRef<any>()
  const [{ visible, display, startIndex }, setState] = useSetState<any>({
    visible: false,
    startIndex: 0
  })
  const { runScheduleSetting, setting: storeSetting } = useLocalStore(() => comstore)
  const { startColumns, endColumns } = useColumns({
    dataSource: {
      start_datetime: '',
      end_datetime: ''
    },
    setting: shiftType === 1 ? AllSetting : storeSetting,
    endPickerRef,
    open,
    todayStr: currentDay,
    startIndex
  })

  const columns = [startColumns, endColumns]

  useEffect(() => {
    runScheduleSetting()
  }, [])

  useEffect(() => {
    if (display) onChange?.(display)
  }, [display])

  useEffect(() => {
    if (source && source === 'Modal') {
      setState({
        visible: _visible
      })
    }
  }, [_visible])

  useEffect(() => {
    // value && onChange?.(undefined)
  }, [currentDay])

  const bool: boolean = value && Array.isArray(value) && value?.length > 0

  const renderCell = (props: any) => {
    const { split, className } = props
    return (
      <div className={classNames(className)}>
        <span>
          {bool ? (value?.[0]?.split(' ')?.[0] !== currentDay ? ' 次日' : '') : ''}
          {bool ? value?.[0]?.split(' ')?.[1] || '' : ''}
        </span>

        {bool ? (
          split || '-'
        ) : (
          <span className={classNames({ 'items-timepicker-value-split': true })}>请选择</span>
        )}
        <span>
          {bool ? (value?.[1]?.split(' ')?.[0] !== currentDay ? ' 次日' : '') : ''}
          {bool ? value?.[1]?.split(' ')?.[1] || '' : ''}
        </span>
      </div>
    )
  }

  const _bool: boolean = !disabled && !readed
  return (
    <>
      {source !== 'Modal' && (
        <div
          className={classNames('items-timepicker-value', {
            // 'items-timepicker-value-disabled': disabled
          })}
          onClick={() => _bool && setState({ visible: true })}
        >
          {renderChildren?.(renderCell) || renderCell({})}
        </div>
      )}
      <Picker
        columns={columns}
        visible={visible}
        title={title}
        onClose={() => (onClose ? onClose?.(false) : setState({ visible: false }))}
        value={value || []}
        onConfirm={(v) => {
          setState({ display: v, visible: false })
        }}
        onSelect={(e: any) => {
          setState({ startIndex: startColumns?.findIndex((x: any) => x.value === e?.[0]) })
        }}
        className='time-picker'
      />
    </>
  )
}

export default observer(TimePicker)
