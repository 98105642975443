import { Button, Checkbox, Dialog, Modal } from 'antd-mobile'
import classNames from 'classnames'

import { isNotEmptyArray } from '@/utils'

import './index.less'

/**
 * 按钮组
 * @returns FC
 */
const BatchAction = ({
  permission = {},
  batchActions,
  selectedKeys,
  selectedItems,
  allKeyList,
  stateStore,
  dataList,
  abnormalAction
}: {
  dataList?: any[]
  allKeyList?: any[]
  selectedKeys: any[]
  selectedItems: any[]
  batchActions: any[]
  permission: any
  stateStore: any
  abnormalAction?: any
}) => {
  const actions = isNotEmptyArray(batchActions)
    ? batchActions.filter((x) => permission.getCodeFun?.(x.code))
    : []

  const checked = isNotEmptyArray(selectedKeys) && allKeyList?.length === selectedKeys.length

  return (
    <div className='hyb-batch-action-wrap'>
      <div className='hyb-batch-action-left'>
        <Checkbox
          className='hyb-batch-action-checkbox'
          checked={checked}
          onChange={(flag) => {
            stateStore.setTableState({
              selectedKeys: flag ? allKeyList : [],
              selectedItems: flag ? dataList : []
            })
          }}
        >
          全选
        </Checkbox>
        <div className='selected-tips'>{`已选${selectedKeys.length}个`}</div>
      </div>
      {isNotEmptyArray(actions) && (
        <div
          className={`hyb-batch-action-right ${actions.length > 2 ? 'hyb-batch-action-more' : ''}`}
        >
          {actions.map((item: any, index: number) => {
            const { type, ...rest } = item.elementProps || {}
            const onConfirm = () => {
              item.onClick?.({
                selectedKeys,
                btnInfo: {
                  ...item,
                  operaType: 'batch'
                }
              })
            }
            return (
              <Button
                {...item}
                color={type}
                {...rest}
                className={classNames(item?.className, {
                  'batch-action-btn': actions?.length > 1,
                  'batch-action-btn-single': actions?.length === 1
                })}
                key={`${item.dataIndex}_${index}`}
                onClick={() => {
                  if (abnormalAction && item?.abnormalTitle?.length > 0) {
                    const count = abnormalAction?.(selectedItems)
                    const title = count ? (
                      <div className='hyb-batch-action-title'>
                        {item?.abnormalTitle?.[0] || ''}
                        <span>{count}</span>
                        {item?.abnormalTitle?.[1] || ''}
                      </div>
                    ) : (
                      item?.confirmTitle
                    )
                    Dialog.confirm({
                      title,
                      content: item?.confirmContent,
                      className: 'hyb-com-dialog-wrap',
                      onConfirm
                    })
                  } else if (item.confirmTitle) {
                    Dialog.confirm({
                      title: item.confirmTitle,
                      content: item.confirmContent,
                      className: 'hyb-com-dialog-wrap',
                      onConfirm
                    })
                  } else {
                    onConfirm()
                  }
                }}
              >
                {item.text}
              </Button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default BatchAction
