import { DatePicker, Picker } from 'antd-mobile'
import { useMemo, useCallback } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import useTimeColumn from '@/hooks/useTimeColumn'
import { getTypeof } from '@/utils'
import dayjs from '@/utils/dayjs'

import HCell from '../../HYBVant/HCell'

import './index.less'
const weekdayToZh = (weekday: number) => {
  switch (weekday) {
    case 1:
      return '周一'
    case 2:
      return '周二'
    case 3:
      return '周三'
    case 4:
      return '周四'
    case 5:
      return '周五'
    case 6:
      return '周六'
    case 7:
      return '周日'
    default:
      return weekday
  }
}
const toDateObj = (date: any) => {
  return getTypeof(date) === 'Number' ? new Date(date) : date
}
function DatetimePickerBox(props: any) {
  const { value, type = 'day', onConfirm, ...rest } = props
  const { open, modalData, setModalData, onOpen, onOk, onCancel } = usePopup({
    afterOk: () => {
      // console.log(modalData.value, value, 'modalData.value')
      const __value = modalData.value || value
      onConfirm?.(type !== 'time' ? __value || dayjs().format('YYYY-MM-DD') : __value)
      return Promise.resolve()
    }
  })
  const { renderTimeColumn } = useTimeColumn()
  const precision = (type: any) => {
    if (type === 'datetime') {
      return 'minute'
    }
    if (type === 'date') {
      return 'day'
    }
    if (type === 'year-month') {
      return 'month'
    }
    return type
  }
  const labelRenderer = useCallback((str: string, data: number) => {
    switch (str) {
      case 'year':
        return data + '年'
      case 'month':
        return data + '月'
      case 'day':
        return data + '日'
      case 'hour':
        return data + '时'
      case 'minute':
        return data + '分'
      case 'second':
        return data + '秒'
      case 'week':
        return data + '周'
      case 'week-day':
        return weekdayToZh(data)
      default:
        return data
    }
  }, [])
  const { basicColumns } = renderTimeColumn({ ...props, format: 'HH:mm' })
  const onConfirmOk = (timeParts: any) => {
    onConfirm?.(timeParts?.join(':') || null)
  }
  const __value = useMemo(() => {
    return type === 'time' ? modalData?.value : value ? new Date(value) : null
  }, [modalData, value])

  const bool: boolean = !props?.disabled && !props?.readed

  return (
    <>
      <HCell
        value={props.formatter ? props.formatter(value) : value || ''}
        onClick={() =>
          bool &&
          onOpen(
            type === 'time' && getTypeof(value) === 'String'
              ? { value: value.split(':').slice(0, (rest.format || 'HH:mm:ss').split(':').length) }
              : undefined
          )
        }
        disabled={props?.disabled}
        readed={props?.readed}
      />
      {open && type !== 'time' && (
        <DatePicker
          visible={open}
          className='datetime-picker-context-box'
          precision={precision(type)}
          value={__value}
          onSelect={(e: any) => {
            console.log('setModalData', e, dayjs(e).format('YYYY-MM-DD'))
            setModalData({ value: dayjs(e).format('YYYY-MM-DD') })
          }}
          renderLabel={labelRenderer}
          onClose={onCancel}
          onConfirm={onOk}
          min={toDateObj(rest.min || rest.minDate)}
          max={toDateObj(rest.max || rest.maxDate)}
          {...rest}
        />
      )}
      {open && type === 'time' && (
        <Picker
          visible={open}
          className='datetime-picker-context-box'
          columns={basicColumns}
          value={__value}
          onSelect={(e: any) => {
            console.log('picker value:', e)
            setModalData({ value: e })
          }}
          onClose={onCancel}
          onConfirm={onConfirmOk}
        />
      )}
    </>
  )
}

export default DatetimePickerBox
