import { Checkbox } from 'antd-mobile'
import dayjs from 'dayjs'

import DatetimePickerBox from '../DatetimePickerBox'
import './index.less'

// 通用数字框
function DatePickerAll({ value, onConfirm, ...props }: any) {
  console.log('DatePickerAll', value)
  return (
    <div className='date-picker-all-wrap'>
      <DatetimePickerBox
        {...props}
        disabled={props.disabled || value?.is_all === 1}
        value={value?.date}
        onConfirm={(val) => onConfirm?.({ ...value, date: val })}
      />
      <div className='permanent-wrap'>
        {props.disabled && value?.is_all === 1 ? (
          props.tip
        ) : props.disabled && value?.is_all === 0 ? null : (
          <Checkbox
            value={value?.is_all}
            onChange={(val) => {
              onConfirm?.({ is_all: val ? 1 : 0 })
            }}
          >
            {props.tip}
          </Checkbox>
        )}
      </div>
    </div>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
DatePickerAll.convertValue = (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config.names[0]]: {
        date: values?.[config.names[0]],
        is_all: values?.[config.names[1]]
      }
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
DatePickerAll.transform = (value: any, config?: any, values?: any) => {
  if (value && Array.isArray(config?.names) && config?.names?.length === 2) {
    // console.log('[config?.names[0]]:', values, [config?.names[0]], values[config?.names[0]])
    // 单选
    return {
      [config?.names[0]]:
        typeof value?.date === 'string'
          ? value?.date
          : value?.date
            ? dayjs(value?.date).format('YYYY-MM-DD')
            : null,
      [config?.names[1]]: value?.is_all
    }
  }
  return value
}

export default DatePickerAll
