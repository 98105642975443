import { useSetState } from 'ahooks'
import { CheckList, Popup, SearchBar, SafeArea } from 'antd-mobile'
import classNames from 'classnames'
import { isEmpty, isEqual } from 'lodash-es'
import { observer } from 'mobx-react'
import { FC, useEffect, useMemo } from 'react'

// ====================== 内部公共
import BlankBox from '@/components/BlankBox'
import HCell from '@/components/NewEditForm/UILibrary/HYBVant/HCell'
import { getTypeof, isNullOrUndefined } from '@/utils'
import './index.less'

interface IProps {
  renderItem?: Function
  dataList?: any[]
  disabled?: boolean
  onChange?: Function
  fieldNames?: any
  isSearch?: boolean
  title?: string
  store?: any
  value?: any
  mode?: string
  size?: string
}

function ListSelect({
  value = [],
  dataList = [],
  isSearch = false,
  disabled = false,
  title = '',
  fieldNames = { value: 'value', label: 'name' },
  mode = 'single',
  onChange,
  renderItem,
  size
}: IProps) {
  const [{ show, selectedValue, searchText }, setState] = useSetState<any>({
    show: false,
    selectedValue: null,
    searchText: ''
  })
  useEffect(() => {
    if (!isNullOrUndefined(value)) {
      dataList?.forEach((item: any) => {
        const isSelected =
          getTypeof(value) === 'Array'
            ? value?.includes(item[fieldNames?.value])
            : value === item[fieldNames?.value]
        if (isSelected) {
          setState({ selectedValue: item?.[fieldNames?.label] })
        }
      })
    } else if (!isEqual(value, selectedValue)) {
      setState({ selectedValue: undefined })
    }
  }, [value, dataList])
  const onClick = () => {
    !disabled &&
      setState({
        show: true
      })
  }
  const onClose = () => {
    setState({
      show: false
    })
  }
  const filteredItems = useMemo(() => {
    if (searchText) {
      return dataList.filter((item: any) => item?.[fieldNames?.label]?.includes(searchText))
    } else {
      return dataList
    }
  }, [dataList, searchText])
  const checkedValue = useMemo(() => {
    return mode === 'single' && !isNullOrUndefined(value) && getTypeof(value) !== 'Array'
      ? [value]
      : value || []
  }, [value])
  return (
    <>
      {renderItem ? (
        renderItem(onClick)
      ) : (
        <HCell value={selectedValue} onClick={onClick} disabled={disabled} />
      )}

      <Popup
        visible={show}
        closeOnMaskClick={true}
        position='bottom'
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }}
        className={classNames('public-picker-popup', { sm: size === 'sm' })}
        onClose={() => onClose()}
      >
        <div className='public-picker-popup-content'>
          <div className='public-picker-popup-content-header'>
            <div className='public-picker-popup-content-header-close' onClick={() => onClose()}>
              取消
            </div>
            <div className='public-picker-popup-content-header-title'>请选择{title}</div>
          </div>
          {isSearch && (
            <div className='public-picker-popup-content-search'>
              <SearchBar
                placeholder='请输入'
                value={searchText}
                onChange={(v) => {
                  setState({
                    searchText: v
                  })
                }}
              />
            </div>
          )}
          {filteredItems?.length > 0 ? (
            <div className='public-picker-popup-content-list'>
              <CheckList
                value={checkedValue}
                onChange={(val) => {
                  onChange?.(mode === 'single' ? val?.[0] : val)
                  setState({
                    show: false
                  })
                }}
              >
                {filteredItems?.map((item: any) => (
                  <CheckList.Item
                    key={item?.[fieldNames?.value]}
                    value={item?.[fieldNames?.value]}
                    disabled={item?.disabled}
                  >
                    {item?.[fieldNames?.label]}
                  </CheckList.Item>
                ))}
              </CheckList>
            </div>
          ) : (
            <div className='public-picker-popup-content-not'>
              <BlankBox name='暂无数据' />
            </div>
          )}
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </>
  )
}

ListSelect.transform = (value: any, config: any) => {
  switch (config.mode) {
    case 'multiple':
      return value
    default:
      return Array.isArray(value) ? value?.[0] : value
  }
}
export default observer(ListSelect)
