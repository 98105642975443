import { Button, Dialog, Modal } from 'antd-mobile'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { authStore } from '@/store/global/authStore'

import { isNotEmptyArray } from '@/utils'

import './index.less'

/**
 * 按钮组
 * @returns FC
 */
const ActionsBottom = ({ actions, onClick }: { actions: any[]; onClick: Function }) => {
  const btnList = isNotEmptyArray(actions) ? actions : []
  const { getCodeFun, curFunction } = useLocalObservable(() => authStore)

  return (
    <div className='hyb-bottom-action-wrap'>
      {/* <div className='hyb-bottom-action-left'></div> */}
      {isNotEmptyArray(btnList) && (
        <div
          className={`hyb-bottom-action-right ${btnList.length > 2 ? 'hyb-bottom-action-more' : ''}`}
        >
          {btnList
            ?.filter((x) => getCodeFun(x.code || ''))
            .map((item: any, index: number) => {
              const { type, ...rest } = item.elementProps || {}
              const onConfirm = () => {
                typeof onClick === 'function' && onClick(item)
              }
              return (
                <Button
                  {...item}
                  color={type}
                  {...rest}
                  className={`batch-action-btn ${item.className || ''}`}
                  key={`${item.dataIndex}_${index}`}
                  onClick={() => {
                    if (item.confirmTitle) {
                      Dialog.confirm({
                        title: item.confirmTitle,
                        content: item.confirmContent,
                        className: 'hyb-com-dialog-wrap',
                        onConfirm
                      })
                    } else {
                      onConfirm()
                    }
                  }}
                >
                  {item.text}
                </Button>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default observer(ActionsBottom)
