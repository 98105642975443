import { Tabs, TabsProps } from 'antd-mobile'
import classNames from 'classnames'
import React from 'react'
import './index.less'

const HTabs: React.FC<any> = ({ children, className, ...props }: TabsProps) => {
  return (
    <Tabs
      activeLineMode='fixed'
      style={{
        '--fixed-active-line-width': '34px',
        '--title-font-size': '14px'
      }}
      {...props}
      className={classNames('h-tabs', className || '')}
    >
      {children}
    </Tabs>
  )
}

export default HTabs
