import { useQuery } from '@tanstack/react-query'
import { useSetState } from 'ahooks'
import { CascaderView, Toast } from 'antd-mobile'
import { useEffect, useMemo } from 'react'

import { QUERY_KEY_MAP } from '@/constants'
import { getTypeof, isNotEmptyArray, transformData, transformDataToMap } from '@/utils'

import HCell from '../../HYBVant/HCell'
import HPopup from '../../HYBVant/HPopup'

import { getCity } from './service'

import './index.less'

interface IProps {
  onConfirm?: any
  value?: any
  width?: string
  height?: number
  disabled?: boolean
  placeholder?: string
  [key: string]: any
}

function CityCascader({ value, onConfirm, ...props }: IProps) {
  const [{ visible, isLeaf, selected, options, optionsMap }, setState] = useSetState<any>({
    visible: false,
    isLeaf: false,
    selected: [],
    optionsMap: {},
    options: []
  })

  const { isPending, data } = useQuery({
    queryKey: [QUERY_KEY_MAP.REGION_KEY],
    queryFn: () => getCity(),
    staleTime: 1000 * 60 * 60
  })

  useEffect(() => {
    if (!isPending && data?.code === 2000) {
      const tempData = transformData(data?.data, { value: 'id' })
      const tempMap = transformDataToMap(tempData)
      setState({ options: tempData, optionsMap: tempMap })
    }
  }, [isPending])

  const changeCity = (city: any, { isLeaf, items }: any = {}) => {
    setState({
      isLeaf,
      selected: items.map((item: any) => item.value)
    })
  }

  const onCancel = () => {
    setState({ visible: false, selected: [], isLeaf: false })
  }

  const onOk = () => {
    if (!isLeaf) {
      return Toast.show('请选择完整的地址')
    }
    onConfirm?.(selected)
    onCancel()
  }

  const cellTitle = useMemo(() => {
    if (isNotEmptyArray(value)) {
      return value.map((id: string) => optionsMap?.[id]?.label).join('/')
    }
    return ''
  }, [value, optionsMap])

  useEffect(() => {
    visible && setState({ selected: value })
  }, [value, visible])

  console.log('选择的地址', selected, props)

  return (
    <>
      <HCell
        value={cellTitle}
        editform={props?.editform}
        onClick={() => setState({ visible: true })}
        disabled={props?.disabled}
      />

      <HPopup
        visible={visible}
        title='选择城市'
        onCancel={onCancel}
        onOk={onOk}
        bodyClassName='city-cascader-popup-content'
      >
        <CascaderView
          options={options}
          value={selected || []}
          {...props}
          onChange={changeCity}
          loading={isPending}
        />
      </HPopup>
    </>
  )
}

/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
CityCascader.transform = (value: any, config?: any) => {
  if (!Array.isArray(value)) return value
  const _value = value
    ?.filter((i) => !!i)
    ?.reverse()
    ?.find((item) => item)
  if (value && Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config?.names[0]]: _value,
      [config?.names[1]]: _value
    }
  }
  return _value
}
/*
 * 回显时转换
 * @param value
 * @param config
 * @returns newValue
 */
CityCascader.convertValue = (value: any, config?: any, values?: any) => {
  if (value && getTypeof(value) === 'string') {
    return values?.[config.valuePropName]
  }
  return value
}

export default CityCascader
