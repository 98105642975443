import { Input } from 'antd-mobile'
import { omit } from 'lodash-es'

import { publicProps } from '../../HYBVant/utils'
import PickerBox from '../PickerBox'
import './index.less'

// 通用数字框
function InputSelect({ value, onConfirm, ...props }: any) {
  console.log('inputSelect', value)
  return (
    <>
      <Input
        placeholder='请输入'
        type={props.type || 'number'}
        maxLength={props.maxlength}
        value={value?.input}
        onChange={(e: any) => onConfirm({ ...value, input: e })}
      />
      <div style={{ padding: '0 20px' }}></div>
      <PickerBox
        {...publicProps(omit(props, ['type', 'maxlength']), 'input-select')}
        value={value?.select}
        onConfirm={(e: any) => onConfirm({ ...value, select: e })}
      />
    </>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
InputSelect.convertValue = (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config.names[0]]: {
        input: values?.[config.names[0]],
        select: values?.[config.names[1]]
      }
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
InputSelect.transform = (value: any, config?: any, values?: any) => {
  if (value && Array.isArray(config?.names) && config?.names?.length === 2) {
    // console.log('[config?.names[0]]:', values, [config?.names[0]], values[config?.names[0]])
    // 单选
    return {
      [config?.names[0]]: value?.input,
      [config?.names[1]]: value?.select
    }
  }
  return value
}

export default InputSelect
