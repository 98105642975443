import { get } from 'lodash-es'

import { isNotEmptyArray } from '@/utils'

export type Data = {
  [x: string]: any
}

type TextName = string | string[]

export const formatStr = (str: TextName): string => {
  return isNotEmptyArray(str) ? (str as string[]).join(' ') : (str as string) || '-'
}

export const textRender = (key: TextName, itemData: Data): string => {
  if (typeof key === 'string') {
    return formatStr(get(itemData, key)) || ''
  }
  if (isNotEmptyArray(key)) {
    return key.map((keyItem) => textRender(keyItem, itemData)).join(' ')
  }
  return ''
}

// 执行函数
export const execJsStr = (func: any, values: any, current?: any) => {
  if (!func) return func
  if (typeof func === 'function') {
    return func(values, current)
  } else if (typeof func === 'string') {
    // eslint-disable-next-line no-new-func
    const _func = new Function('values', 'current', `return ${func}`)
    return _func(values, current)
  }
  return func
}
