import { observable, makeAutoObservable, action, runInAction } from 'mobx'

import { scheduleSettingMobile } from './service'

class SechduleStore {
  constructor() {
    makeAutoObservable(this)
  }

  @observable setting: any = {}

  @action
  runScheduleSetting = async () => {
    const { data } = await scheduleSettingMobile()
    this.setting = data
  }
}
export default new SechduleStore()
