import { Switch } from 'antd-mobile'
import React from 'react'

import { getTypeof } from '@/utils'

import { publicProps } from '../utils'
import './index.less'

function HSwitch(props: any) {
  return <Switch checked={props.value} {...publicProps(props, 'switch')} />
}

HSwitch.transform = (value?: any) => {
  if (getTypeof(value) === 'Boolean') {
    return value ? 1 : 0
  }
  if (getTypeof(value) === 'Number') {
    return value
  }
  if (getTypeof(value) === 'Null' || getTypeof(value) === 'Undefined') {
    return 0
  }
  return value
}

export default HSwitch
