import dayjs from 'dayjs'
import { useMemo } from 'react'

/**
 * 选时段数据源
 * @param param0
 * @returns
 */
const useColumns = ({ dataSource, todayStr, setting, startIndex }: any) => {
  // const optStore = useLocalObservable(() => scheduleOperaStoreNoContext)

  const { columns, startColumns, startValue, startText, endText } = useMemo(() => {
    const {
      tool_schedule_step = 30,
      tool_schedule_start_time = '00:00',
      tool_schedule_duration = 24 // 47.5
    } = setting

    const _columns: any[] = []
    const beforeTime = dayjs(todayStr)
      .hour(tool_schedule_start_time.split(':')[0])
      .minute(tool_schedule_start_time.split(':')[1])
      .subtract(tool_schedule_step, 'm')
      .format('HH:mm')
    const loopTime = () => {
      const prevTime = _columns.slice(-1)?.[0]?.text || beforeTime
      let day = prevTime.startsWith('次') ? dayjs(todayStr).add(1, 'd') : dayjs(todayStr)
      const prev = prevTime?.replace?.(/次/, '')
      // console.log('prev:', prev)
      const datetime = day
        .hour(prev.split(':')[0])
        .minute(prev.split(':')[1])
        .add(tool_schedule_step, 'm')
      const text = `${datetime.isSame(dayjs(todayStr), 'day') || _columns.length === 0 ? '' : '次'}${datetime.format(
        'HH:mm'
      )}`
      if (datetime.get('hour') < dayjs(`${todayStr} ${prevTime}`).get('hour')) {
        day = dayjs(todayStr).add(1, 'd')
      }
      const value = `${day.format('YYYY-MM-DD')} ${datetime.format('HH:mm')}`
      _columns.push({
        text,
        value,
        label: text
      })
    }
    const maxDateTime = dayjs(todayStr)
      .hour(tool_schedule_start_time.split(':')[0])
      .minute(tool_schedule_start_time.split(':')[1])
      .add(tool_schedule_duration, 'h')
    new Array(tool_schedule_duration).fill(0).forEach(() => {
      let step = 0
      while (
        step < 60 &&
        (_columns.length === 0 || dayjs(_columns.slice(-1)?.[0]?.value).isBefore(maxDateTime, 'm'))
      ) {
        loopTime()
        step += tool_schedule_step
      }
    })
    dayjs(_columns.slice(-1)?.[0]?.value).isBefore(maxDateTime, 'm') && loopTime()
    const _startValue: any = _columns.findIndex(
      (item: any) => item.value === dataSource.start_datetime
    )
    const _startText = dataSource?.start_datetime
      ? `${
          dayjs(dataSource?.start_datetime).isSame(dayjs(todayStr), 'day') ? '' : '次'
        }${dataSource?.start_datetime?.slice(-5)}`
      : ''
    const _endText = dataSource?.end_datetime
      ? `${dayjs(dataSource?.end_datetime).isSame(dayjs(todayStr), 'day') ? '' : '次'}${dataSource?.end_datetime?.slice(-5)}`
      : ''

    return {
      columns: _columns,
      startColumns: _columns,
      startValue: _startValue,
      startText: _startText,
      endText: _endText
    }
  }, [setting, dataSource])
  // const { endColumns, endValue } = useMemo(() => {
  //   const start = dayjs(modalData?.start_datetime || dataSource?.start_datetime)
  //   const _endColumns = columns.filter((item: any) => dayjs(item.value).isAfter(start))
  //   const _endValue: any = _endColumns.findIndex(
  //     (item: any) => item.value === dataSource?.end_datetime
  //   )
  //   if (_endValue < 0) {
  //     setModalData({ ...modalData, end_datetime: _endColumns?.[0]?.value })
  //   }
  //   setTimeout(() => {
  //     // endPickerRef.current?.setIndexes([_endValue])
  //   }, 50)
  //   return {
  //     endColumns: _endColumns,
  //     endValue: _endValue
  //   }
  // }, [modalData?.start_datetime, dataSource?.start_datetime, open])

  const endColumns = useMemo(() => {
    if (startIndex === startColumns?.length - 1) {
      return [startColumns?.[startColumns?.length - 1]]
    }
    return startColumns?.slice(startIndex + 1)
  }, [startColumns, startIndex])
  return {
    startColumns,
    startValue,
    startText,
    endColumns,
    // endValue,
    endText
  }
}

export default useColumns
