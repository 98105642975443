import { FlowOperaType } from '../../types'

export const json = (props: any) => {
  const {
    flowOperaType,
    text = '',
    node,
    applyItem = '',
    apply_category_code = '',
    date_mode = '',
    initiate_mode
  } = props

  return {
    formInfo: [
      {
        title: '',
        top: false,
        key: '1',
        form_list: [
          {
            type: 'Personnel',
            hide: !(['SYSTEM_LOAN'].includes(applyItem) && flowOperaType === FlowOperaType.Agree),
            formItemProps: {
              label: '借调的员工',
              name: 'employee_ids',
              required: false
            },
            elementProps: {
              type: 'employee_ids'
            }
          },
          {
            type: 'ListSelect',
            hide: flowOperaType !== FlowOperaType.Countersign,
            formItemProps: {
              label: '节点之后加签',
              name: 'add_after_inst_node_id'
            },
            elementProps: {
              placeholder: '请选择节点',
              dataList: node.map((x: any) => ({
                ...x,
                text: x?.node_name,
                name: x?.node_name,
                value: x?.id,
                id: x?.id
              }))
            }
          },
          {
            type: 'ListSelect',
            // disabled: true,
            hide: flowOperaType !== FlowOperaType.Back,
            formItemProps: {
              label: '回退节点',
              name: 'revert_to_inst_node_id',
              required: true
            },
            elementProps: {
              placeholder: '请选择节点',
              dataList: node.map((x: any) => ({
                ...x,
                text: x?.node_name,
                name: x?.node_name,
                value: x?.id,
                id: x?.id
              }))
            }
          },
          {
            type: 'Personnel',
            hide:
              flowOperaType !== FlowOperaType.Countersign &&
              flowOperaType !== FlowOperaType.Transfer,
            formItemProps: {
              label: `${text}审批人`,
              name: 'transfer_approver_ids',
              required: true
            },
            elementProps: {
              type: 'transfer_approver_ids',
              transfer_approver_ids: {
                personnelSearchRequestParams: {
                  // with_no_auth: true
                },
                selectorParams: {
                  with_no_auth: true
                }
              }
            }
          },
          {
            type: 'Textarea',
            hide: flowOperaType === FlowOperaType.File,
            formItemProps: {
              name: 'comment',
              label: '备注',
              className: ['class-a'],
              required: true
            },
            elementProps: {
              // style: 'background:#fff;width:100%;min-height:100px;padding: 0 30rpx;border: none',
              autoHeight: true,
              placeholder: flowOperaType === FlowOperaType.Modify ? '备注' : '请输入审批意见',
              ...(flowOperaType === FlowOperaType.Modify ? {} : { maxlength: 100 })
            }
          },
          {
            type: 'DatePicker',
            hide:
              !(flowOperaType === FlowOperaType.Modify && apply_category_code === 'ASSIST') ||
              (initiate_mode === 'SINGLE_CYCLE_MODE' && date_mode === 'HALF_DAY_MODE'),
            formItemProps: {
              label: '开始日期',
              required: true,
              name: 'application_date_start'
            },
            elementProps: {}
          },
          {
            type: 'DatePicker',
            hide:
              !(flowOperaType === FlowOperaType.Modify && apply_category_code === 'ASSIST') ||
              (initiate_mode === 'SINGLE_CYCLE_MODE' && date_mode === 'HALF_DAY_MODE'),
            formItemProps: {
              label: '结束日期',
              required: true,
              name: 'application_date_end'
            },
            elementProps: {}
          },
          {
            type: 'CalendarCascader',
            formItemProps: {
              label: '开始日期',
              required: true,
              name: 'application_date_start'
            },
            elementProps: {},
            hide: !(
              flowOperaType === FlowOperaType.Modify &&
              apply_category_code === 'ASSIST' &&
              date_mode === 'HALF_DAY_MODE' &&
              initiate_mode === 'SINGLE_CYCLE_MODE'
            )
          },
          {
            type: 'CalendarCascader',
            formItemProps: {
              label: '结束日期',
              required: true,
              name: 'application_date_end'
            },
            elementProps: {},
            hide: !(
              flowOperaType === FlowOperaType.Modify &&
              apply_category_code === 'ASSIST' &&
              date_mode === 'HALF_DAY_MODE' &&
              initiate_mode === 'SINGLE_CYCLE_MODE'
            )
          },
          {
            type: 'DatePicker',
            formItemProps: {
              name: 'daily_start_time',
              label: `${initiate_mode === 'LOOP_MODE' ? '每日' : ''}开始时间`,
              required: true
            },
            hide: !(
              flowOperaType === FlowOperaType.Modify &&
              apply_category_code === 'ASSIST' &&
              date_mode === 'TIME_PERIOD_MODE'
            ),
            elementProps: {
              type: 'time',
              format: 'HH:mm'
            }
          },
          {
            type: 'DatePicker',
            formItemProps: {
              name: 'daily_end_time',
              label: `${initiate_mode === 'LOOP_MODE' ? '每日' : ''}结束时间`,
              required: true
            },
            hide: !(
              flowOperaType === FlowOperaType.Modify &&
              apply_category_code === 'ASSIST' &&
              date_mode === 'TIME_PERIOD_MODE'
            ),
            elementProps: {
              type: 'time',
              format: 'HH:mm'
            }
          },
          {
            type: 'Select',
            formItemProps: {
              name: 'daily',
              label: '每日时间',
              required: true
            },
            dictionary: 'HALF_DAY',
            elementProps: {},
            hide: !(
              flowOperaType === FlowOperaType.Modify &&
              apply_category_code === 'ASSIST' &&
              date_mode === 'HALF_DAY_MODE' &&
              initiate_mode === 'LOOP_MODE'
            )
          },
          {
            type: 'FormImage',
            hide: flowOperaType === FlowOperaType.Modify,
            formItemProps: {
              label: '',
              name: 'attachments',
              className: ['class-f'],
              required: false
            },
            elementProps: {
              label: '上传附件',
              hasToken: true,
              required: false
            }
          }
        ]
      }
    ]
  }
}
