import { useRef, useState } from 'react'

/**
 * 初始化计数器
 * @param param0
 * @returns
 */
const useInited = (
  execCount = 1,
  timeout = 100,
  complete?: (isSuccess: boolean) => void
): [boolean, () => void, () => void, () => void, boolean, number] => {
  const [inited, setInited] = useState(false)
  const initedRef = useRef(inited)
  initedRef.current = inited
  const count = useRef(0)
  const completeCount = useRef(0)
  const success: () => void = () => {
    setTimeout(() => {
      completeCount.current++
      count.current++
      setInited(count.current >= execCount)
      if (complete && completeCount.current === execCount) {
        setTimeout(() => {
          complete(count.current === execCount)
        }, timeout)
      }
    }, timeout)
  }
  const fail: () => void = () => {
    setTimeout(() => {
      completeCount.current++
      count.current--
      setInited(count.current >= execCount)
      if (complete && completeCount.current === execCount) {
        setTimeout(() => {
          complete(count.current === execCount)
        }, timeout)
      }
    }, timeout)
  }
  const clear: () => void = () => {
    completeCount.current = 0
    count.current = 0
    setInited(false)
  }
  return [inited, success, fail, clear, initedRef.current, count.current]
}

export default useInited
