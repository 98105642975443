// import { useDidShow, useRouter } from '@tarojs/taro'
import { useSetState } from 'ahooks'
import { useLocalObservable } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useVariable from '@/hooks/useVariable'
import { unifiedOptionStoreNoContext } from '@/store/unified/unifiedOptionStore'
import { unifiedStoreNoContext } from '@/store/unified/unifiedStore'
import service from '@/utils/service'

import { StatusCode } from '../enum'

import usePcTransform from './usePcTransform'
// 获取通用表单
export const getCommonForm = (data: any, url?: any): Promise<any> =>
  service<any>({ method: 'get', url: `/common/form_json${url}`, data })

/**
 * 动态获取通用表单
 * @param code 表单code
 * @param qr_code_id 免登录扫码id
 */
export default ({
  code,
  vars,
  immediate,
  ignoreStore,
  isPreview,
  isLocal,
  localOption
}: {
  code: string
  vars?: any
  immediate?: boolean
  ignoreStore?: boolean
  isPreview?: boolean
  isLocal?: boolean
  localOption?: any
}) => {
  const _optionStore = useLocalObservable(() => unifiedOptionStoreNoContext)
  const _stateStore = useLocalObservable(() => unifiedStoreNoContext)
  const params = useParams()
  const [state, setState] = useSetState<{
    option: any
    originOption: any
    optionStatus: StatusCode
    variables?: any
  }>({
    option: undefined,
    originOption: undefined,
    optionStatus: StatusCode.pending
  })
  const { transform } = usePcTransform()
  const stateRef = useRef<any>(state)
  stateRef.current = state
  const setFormOptionItem = (formInfo: any, items: { [key: string]: any }, extraName?: string) => {
    if (extraName) {
      // TODO
    } else {
      const info = formInfo
      Object.entries(items).forEach(([key, value]) => {
        const columns = key.split('.')
        if (columns?.length === 2) {
          const blockIndex = info.findIndex(
            (item: any) => item.id === columns[0] || item.key === columns[0]
          )
          const itemIndex = info?.[blockIndex]?.form_list?.findIndex(
            (item: any) => item.name === columns[1] || item.formItemProps?.name === columns[1]
          )
          if (blockIndex > -1 && itemIndex > -1) {
            info[blockIndex].form_list[itemIndex] = {
              ...info[blockIndex].form_list[itemIndex],
              ...value
            }
          } else {
            throw new Error(`设置表单项找不到 ${key}`)
          }
        }
      })
      return info
    }
  }
  const getFormOptionItem = (formInfo: any, key: string, extraName?: string) => {
    if (extraName) {
      // TODO
    } else {
      const info = formInfo
      const columns = key.split('.')
      if (columns?.length === 2) {
        const blockIndex = info.findIndex(
          (item: any) => item.id === columns[0] || item.key === columns[0]
        )
        const itemIndex = info?.[blockIndex]?.form_list?.findIndex(
          (item: any) => item.name === columns[1] || item.formItemProps?.name === columns[1]
        )
        if (blockIndex > -1 && itemIndex > -1) {
          return info[blockIndex].form_list[itemIndex]
        } else {
          throw new Error(`设置表单项找不到 ${key}`)
        }
      }
      return {}
    }
  }
  const injectVariable = useVariable({
    vars,
    isPreview
  })
  const initOptionError = () => {
    setState({ optionStatus: StatusCode.error })
  }
  const initOptionLocal = ({ vars: _vars, code: _code }: { vars?: any; code?: string }) => {
    const _variables = { ...vars, ..._vars }
    !ignoreStore && _optionStore?.setStatus(StatusCode.pending)
    setState({ variables: _variables, optionStatus: StatusCode.pending })
    const __option = injectVariable(localOption, _variables)
    const _option = transform(__option)
    setState({ option: _option, originOption: localOption, optionStatus: StatusCode.done })
    if (!ignoreStore) {
      _optionStore?.setOption(_option)
      _optionStore?.setStatus(StatusCode.done)
    }
    return Promise.resolve(!0)
  }
  const initOption = (config: { vars?: any; code?: string }) => {
    if (isLocal) {
      return initOptionLocal(config)
    }
    const { vars: _vars, code: _code } = config
    const _variables = { ...vars, ..._vars }
    setState({ variables: _variables, optionStatus: StatusCode.pending })
    return getCommonForm(
      { form_type: _code || code, qr_code_id: params?.id || null },
      params?.id ? '/no_auth' : ''
    )
      .then(({ success, data }: any) => {
        const dataSource = data
        if (success && data && dataSource) {
          const __option = injectVariable(dataSource, _variables)
          const _option = transform(__option)
          setState({
            option: _option,
            originOption: transform(dataSource),
            optionStatus: StatusCode.done
          })
        } else if (success && data && !dataSource) {
          const __option = injectVariable(dataSource, _variables)
          const _option = transform(__option)
          setState({
            option: _option,
            originOption: transform(dataSource),
            optionStatus: StatusCode.error
          })
        } else {
          initOptionError()
        }
      })
      .catch((error: any) => {
        console.error(error)
        initOptionError()
      })
  }
  const refreshOption = (newVars: any) => {
    // console.log('newVars', newVars, stateRef.current.originOption)
    const _variables = {
      ...stateRef.current.variables,
      ...newVars
    }
    const _option = injectVariable(stateRef.current.originOption, _variables)
    setState({ option: _option, variables: _variables })
  }
  useEffect(() => {
    if (immediate && code) {
      _optionStore.clear()
      _stateStore.clear()
      setTimeout(() => {
        initOption({ code })
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    option: state.option,
    originOption: state.originOption,
    optionStatus: state.optionStatus,
    initOption,
    refreshOption,
    setFormOptionItem,
    getFormOptionItem
  }
}
