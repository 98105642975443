import dayjs from 'dayjs'

export const Weeki = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][
  dayjs().day()
]

const month = dayjs().month() + 1

export const Datei = `${dayjs().year()}.${month > 10 ? month : `0${month}`}.${dayjs().date()}`

export const IconMap: Record<string, string> = {
  Success: 'https://s.huiyunban.cn/1821520325834182656.png',
  Danger: 'https://s.huiyunban.cn/1821520362035220480.png'
}

export const IconClockDispalyMap: Record<string, string> = {
  2: 'https://s.huiyunban.cn/1783034401374867456.png',
  3: 'https://s.huiyunban.cn/1783038083164278784.png',
  4: 'https://s.huiyunban.cn/1783038741271547904.png'
}
/**
 * 上下班文案
 */
export const InOrOutMap: any = {
  1: '上班',
  2: '下班'
}
/**
 * 时段状态
 */
export const SlotStatus: any = {
  0: '', // 正常 不显示
  1: '迟到',
  2: '早退',
  3: '缺卡',
  4: '正常',
  5: '旷工'
}
/**
 * 时段状态样式
 */
export const SlotStatusClass: any = {
  0: 'success',
  1: 'warning',
  2: 'warning',
  3: 'danger',
  4: 'success',
  5: 'danger'
}
/**
 * 打卡类型样式
 */
export const ClockClass: any = {
  1: 'gps',
  2: 'gps',
  3: 'bluetooth',
  4: 'qrcode',
  5: 'gps'
}

/**
 * 其他业务错误状态
 */
export const ErrorStatus: any = {
  0: '', // 正常 不显示
  1: '暂无账期，请联系管理员;',
  2: '暂无计算/结算制度,请联系管理员;',
  3: '暂无计算/结算制度,请联系管理员;'
}

/**
 * 其他业务错误状态
 */
export const ApprovalStatus: any = {
  0: '当前打卡“审批拒绝”，此卡将不参与考勤计算',
  1: '当前打卡“审批通过”',
  2: '当前打卡“审批中”，审批通过后将消除异常',
  3: '打卡无效，此卡将不参与考勤计算'
}
