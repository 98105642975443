// import Cell from '@/components/Cell'
import { Image } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import classNames from 'classnames'
import { useLocalStore, observer } from 'mobx-react'
import React from 'react'

import { ClassMap, MapApplyStatus } from '@/pages/attendance/const'
import { ObjectToKeyValueString } from '@/pages/Flow/Contants'

import flowStore from '@/pages/Flow/Store'

import { commonNav } from '@/utils'

import './index.less'

interface LogProps {
  form_list: any[]
  isTitle?: boolean
}

const OAApproval: React.FC<LogProps> = ({ form_list = [], isTitle = true }: any) => {
  const { changeType } = useLocalStore(() => flowStore)
  const jumpDetail = (data: any) => {
    const { form_status, id } = data
    const item = JSON.stringify({
      apply_id: id || '',
      apply_status: form_status
    })
    changeType('')
    commonNav.navigateTo({
      url: `/flow/applyForm?${ObjectToKeyValueString({
        flowType: 0,
        flowListType: MapApplyStatus[form_status] || '',
        item
      })}`
    })
  }
  console.log(form_list, 'form_list')
  return (
    <div className='oa-approval'>
      {isTitle && form_list?.length > 0 && <div className='oa-approval-title'>OA审批</div>}
      {form_list?.length > 0 &&
        form_list?.map((item: any, index: number) => (
          <div key={`${index}`} onClick={() => jumpDetail(item)} className='oa-approval-item'>
            <div className='oa-approval-item-img'>
              <Image className='oa-approval-item-img-icon' src={item?.icon} />
              <div className='oa-approval-item-img-context'>
                {` ${item?.form_name || ''} ${item?.time_period || ''} ${item?.hour ? `${item?.hour}小时` : ''}`}
              </div>
            </div>
            <div className='oa-approval-item-btn'>
              <span className={classNames([ClassMap[item?.form_status] || 'Attendance-bd-ready'])}>
                {item?.status_name || '-'}
              </span>
              <RightOutline />
            </div>
          </div>
        ))}
    </div>
  )
}
export default observer(OAApproval)
