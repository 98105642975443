import { http } from '@/utils'

export const getUploadSign = (data: {
  file_suffix: string
  file_name: string
  content_type?: string
  u: number
  vs: string
  hasToken: boolean
}): any =>
  http.post(
    data?.hasToken === false ? '/hrm/other/pub/upload_sign' : '/hyb/oss/pri/upload_sign',
    data
  )

export const batchDownloadSigns = (data: any): any =>
  http.post(
    data?.hasToken ? '/hyb/oss/pri/batch_download_signs' : '/hrm/other/pri/batch_download_signs',
    data?.params
  )
