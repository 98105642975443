import SlsTracker from '@aliyun-sls/web-track-browser'

import { getApiEnv, getProjectEnv } from './env'

// 错误类型
export enum ErrorType {
  // 一般错误
  Error = 'Error',
  Warn = 'Warn',
  Info = 'Info',
  Debug = 'Debug',
  // 请求错误
  RequestError = 'RequestError',
  // 弱网错误
  NetSlowError = 'NetSlowError',
  // PromiseUnCatch错误
  UnHandleRejectError = 'UnHandleRejectError'
}
// 定义入参
interface ILogger {
  errorType?: ErrorType
}
// 函数定义
export type TLogger = (error: any, extra?: any) => void
type DevEnv = 'qa1' | 'qa2' | 'qa3' | 'qa4' | 'sandbox' | 'formal'
const TrackerData: any = {
  APIVersion: '0.6.0',
  app: 'pcweb'
}
// 阿里云SLS日志
const tracker = new SlsTracker({
  host: 'cn-shanghai.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
  project: 'hyb-app-log', // Project名称。
  logstore: 'web-tracking-log', // Logstore名称。
  time: 10, // 发送日志的时间间隔，默认是10秒。
  count: 10, // 发送日志的数量大小，默认是10条。
  topic: '慧云班人效云H5Web' // 自定义日志主题。
  // source: 'pcweb' // 预留字段:来源
})
/**
 * 日志util
 */
export const Logger: (props: ILogger) => TLogger = ({ errorType = ErrorType.Error }: ILogger) => {
  const logger: TLogger = (error: any, extra?: any) => {
    const isFormal = getProjectEnv()
    const apiEnv = getApiEnv()
    const env = (isFormal ? 'formal' : apiEnv) as DevEnv
    // 错误标签
    const errorData: any = {
      ...TrackerData,
      url: location.href,
      ns: env,
      name: 'error',
      ua: navigator.userAgent,
      msg: { ...extra }
    }
    switch (errorType) {
      case ErrorType.Error:
        errorData.name = 'error'
        break
      case ErrorType.Warn:
        errorData.name = 'warn'
        break
      case ErrorType.Info:
        errorData.name = 'info'
        break
      case ErrorType.Debug:
        errorData.name = 'debug'
        break
      case ErrorType.UnHandleRejectError:
        errorData.name = 'un_handle_reject_error'
        break
      case ErrorType.RequestError:
        errorData.name = 'api_error'
        break
      case ErrorType.NetSlowError:
        errorData.name = 'api_slow'
        break
    }
    // 错误信息
    if (error instanceof Error) {
      const { name, stack, message } = error
      name && (errorData.msg.error_type = name)
      stack && (errorData.msg.stack = stack)
      message && (errorData.msg.message = message)
    } else {
      errorData.msg.message =
        typeof error === 'object' ? JSON.stringify(error) : error?.toString() || '未知错误'
    }
    tracker.send(errorData)
    console.log('发送日志：', errorData)
  }
  return logger
}
// 默认一般错误
const logger: TLogger = Logger({ errorType: ErrorType.Error })
export default logger
