import { postSearchApi, positionSearchApi, rankSearchApi, scheduleSearchApi } from './services'
import { SelectSearchEnum } from './type'

export const NoInitType: string[] = ['changed_value']

export const SearchSelectApi: Record<string, Function> = {
  [SelectSearchEnum.Post]: postSearchApi,
  [SelectSearchEnum.Position]: positionSearchApi,
  [SelectSearchEnum.Rank]: rankSearchApi,
  [SelectSearchEnum.Schedule]: scheduleSearchApi
}
