import { Dialog } from 'antd-mobile'
import drawWatermark from './h5origin/drawWatermark'
import { JSSDK, LocationResponse } from './interface'
import { getWxworkTicket } from './service'
import { bridgeToast, callbackToPromise, service, storage } from './utils'
import { WxworkApiList, WxworkAuthUrl } from './wxwork.config'
import { isAndroid } from '../wxBridge'

declare const ww: any
/**
 * 企微api二次封装
 * 参考：https://developer.work.weixin.qq.com/document/path/94315
 */
class Wxwork implements JSSDK {
  apiAuthStatus: 'pending' | 'done' | 'error' | undefined = 'pending'
  /**
   * 静默登录
   * @param data  { corp_id: corpId, code }
   * @param config ?: { baseURL: string }
   * @returns Promise<IResponseType>
   */
  silenceLogin(data: any, config?: any) {
    return service({
      method: 'post',
      url: '/app/qw/inner/login',
      data,
      config
    })
  }
  /**
   * 手机系统权限
   * 文档：https://open.feishu.cn/document/client-docs/gadget/-web-app-api/open-ability/setting/getsetting
   * @param authType 授权类型 location
   * @returns Promise<boolean>
   */
  checkAuth(authType: string): Promise<boolean> {
    const checkFunc: any = {
      location: ww.getLocation
    }
    if (checkFunc[authType]) {
      return new Promise((resolve, reject) => {
        checkFunc[authType]({ success: resolve, fail: reject, complete: resolve })
      }).then((res: any) => {
        return res.errCode === 0
      })
    } else {
      return Promise.resolve(false)
    }
  }
  /**
   * 暂无
   * @param authType 同checkAuth
   * @returns Promise
   */
  authorize(authType: string): Promise<boolean> {
    return Promise.resolve(true)
  }
  /**
   * 引导打开系统设置
   * @param authType 同checkAuth
   * @returns Promise
   */
  openSystemSetting(param?: any) {
    Dialog.alert({
      title: '无法获取你的位置信息',
      content: isAndroid
        ? '请前往手机系统的设置打开定位服务，并允许企业微信使用定位服务。'
        : '请在iphone的“设置-隐私-定位服务”选项中打开定位服务，并允许企业微信使用定位服务。',
      closeOnMaskClick: true,
      confirmText: '关闭'
    })
    return Promise.resolve()
  }
  /**
   * 初始化鉴权接口
   * @param param0 { api: string; corpId?: string }
   * @returns Promise
   */
  config({ api, corpId }: { api?: string; corpId?: string } = {}) {
    // if (this.apiAuthStatus === 'done') {
    //   return Promise.reject(new Error('重复调用'))
    // }
    return new Promise((resolve, reject) => {
      const toResolve = (res: any) => {
        this.apiAuthStatus = 'done'
        resolve(res)
      }
      const toReject = (err: any) => {
        this.apiAuthStatus = 'error'
        reject(err)
      }
      const corp_id = corpId || storage.get('corp_id')
      console.log('config.corp_id', corp_id)
      callbackToPromise(
        ww.register,
        {
          corpId: corp_id,
          jsApiList: WxworkApiList,
          getConfigSignature: getWxworkTicket
        },
        ['onConfigSuccess', 'onConfigFail']
      ).then(toResolve, toReject)
    })
  }
  getAuthCode({
    corpId,
    agentId
  }: {
    corpId: string
    agentId?: string
  }): Promise<{ code: string }> {
    // console.log('wxwork.getAuthCode', corpId, ww.createWWLoginPanel)
    return new Promise((resolve, reject) => {
      const url = `${WxworkAuthUrl}?appid=${corpId?.trim?.()}&redirect_uri=${encodeURIComponent(location.href.trim())}&response_type=code&scope=snsapi_privateinfo&state=&agentid=${agentId?.trim?.()}#wechat_redirect`
      location.href = url
      reject(new Error('跳转到授权页'))
    })
  }
  getLocation(): Promise<LocationResponse> {
    console.log('我调用了getLocation:', ww.getLocation)
    return callbackToPromise(ww.getLocation, { type: 'gcj02' })
  }
  getHighLocation() {
    return callbackToPromise(ww.getLocation, { type: 'gcj02' })
  }
  startLocation(): Promise<LocationResponse> {
    return new Promise((resolve, reject) => {
      callbackToPromise(ww.startAutoLBS, { type: 'gcj02' })
        .then(() => {
          ww.onLocationChange(resolve)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }
  stopLocation() {
    return callbackToPromise(ww.stopAutoLBS, { type: 'gcj02' })
  }
  scanCode() {
    return callbackToPromise(ww.scanQRCode, { scanType: ['qrCode'] }).then(
      (res: any) => res?.resultStr
    )
  }
  takePhoto(param?: any): Promise<string> {
    return new Promise((resolve, reject) => {
      callbackToPromise(ww.chooseImage, {
        sourceType: ['camera'],
        sizeType: ['compressed'],
        isSaveToAlbum: false,
        ...param
      })
        .then((res: any) => {
          const path = res.localIds?.[0]
          callbackToPromise(ww.getLocalImgData, { localId: path })
            .then((res: any) => res.localData)
            .then((base64Data: any) => {
              console.error('文件转换', base64Data)
              if (base64Data) {
                drawWatermark(base64Data, param?.stickers).then(resolve).catch(reject)
              } else {
                reject(new Error('拍照失败'))
              }
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  setTitle(params?: any): Promise<void | {}> {
    // 无需实现，自动使用document.title
    return Promise.resolve()
  }
  getImageInfo(localId?: string): Promise<string> {
    // data:image/jpeg;base64,
    return new Promise((resolve, reject) => {
      callbackToPromise(ww.getLocalImgData, { localId })
        .then((res: any) => resolve(res.localData))
        .catch(reject)
    })
  }
}
export default new Wxwork()
