import { Popup, Checkbox, Image } from 'antd-mobile'
import { useEffect, useState } from 'react'

import useInited from '@/hooks/useInited'

import HCell from '../../HYBVant/HCell'
import { publicEvents } from '../../HYBVant/utils'

import {
  organizationsChild,
  organizationsChildOnlyShop,
  organizationsChildNoOnlyShop,
  getGroupNodeTree
} from './services'
import { IOrganize } from './types/index'
import { findCurrentList, findItem } from './utils'
import './index.less'
// 暂无数据
function OrganizeSelect(props: {
  thread?: IOrganize
  onConfirm?: Function
  isDayOffer?: boolean
  onClose?: Function
  chose?: boolean
  isOnlyShop?: boolean
  [key: string]: any
}) {
  const {
    thread = {},
    isDayOffer = false,
    onConfirm,
    chose = false,
    isOnlyShop = true,
    onClose,
    isGroup,
    ...elementProps
  } = props
  const [childrenList, setChildrenList] = useState<IOrganize[]>([])
  const [parentOrgList, setParentOrgList] = useState<IOrganize[]>([])
  const [currentOrgList, setCurrentOrgList] = useState<IOrganize[]>([])
  // const [initOrgName, setInitOrgName] = useState('')
  const [targetOrg, setTargetOrg] = useState<IOrganize | undefined>()
  const [show, setShow] = useState(false)
  const [inited, setInited] = useInited(1, 0)
  useEffect(() => {
    let requestService
    if (isGroup) {
      requestService = getGroupNodeTree
    } else if (isDayOffer || chose) {
      requestService = organizationsChildOnlyShop
    } else if (!isOnlyShop) {
      requestService = organizationsChildNoOnlyShop
    } else {
      requestService = organizationsChild
    }
    requestService(isGroup ? elementProps.code : {}).then((res: any) => {
      const { code, data } = res
      if (code === 2000) {
        const _data = isGroup ? data.nodes : data
        if (_data?.id) {
          setChildrenList([_data])
          setCurrentOrgList([_data])
          if (!isDayOffer || !isOnlyShop) {
            // onConfirm?.(data?.children[0])
          }
        } else {
          setChildrenList(_data?.children || [])
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (show && elementProps?.value?.id) {
      setInited()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])
  useEffect(() => {
    if (inited) {
      const isInit =
        (elementProps.value?.[isGroup ? 'node_code' : 'id'] && !elementProps.value?.name) || false
      setOrgById(elementProps.value?.[isGroup ? 'node_code' : 'id'], isInit)
    }
  }, [inited])
  console.log('childrenList, thread, show:', childrenList, thread, show)

  const setOrgById = async (id: string, bool: boolean) => {
    const children_list = childrenList
    const org = await findItem(id, children_list)
    setTargetOrg(org)
    // setCurrentOrgList(parentOrgList)
    // bool && setInitOrgName(org?.name || '')
  }

  const selectOrg = (checkedOrg: IOrganize) => {
    setTargetOrg(checkedOrg)
  }

  const handleConfim = () => {
    setShow(false)
    publicEvents.onChange.call(props, targetOrg, 'OrganizeSelect')
  }

  const switchPar = () => {
    if (!parentOrgList?.length) {
      return
    }
    console.log('switchPar')
    setCurrentOrgList(parentOrgList)
    if (parentOrgList?.length) {
      setParentOrgList(findCurrentList(parentOrgList[0]?.parent_id || '', childrenList))
    }
  }

  const switchChild = (cur: IOrganize) => {
    console.log('switchChild')
    setParentOrgList(currentOrgList)
    setCurrentOrgList(cur.children || [])
  }
  const handleCancel = () => {
    setShow(false)
  }
  return (
    <>
      <HCell
        value={elementProps?.value?.name}
        onClick={() => !elementProps?.disabled && setShow(true)}
        disabled={elementProps.disabled}
      />
      {
        <Popup
          visible={show}
          closeOnMaskClick
          onMaskClick={handleCancel}
          bodyStyle={{
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            minHeight: '40vh'
          }}
          onClose={() => handleCancel()}
        >
          <div className='OrganizeSelect_container'>
            <div className='OrganizeSelect_header'>
              <div className='OrganizeSelect_header-left'>{isGroup ? '分组' : '部门'}选择</div>
              <div className='OrganizeSelect_header-right'>
                <span className='org-btn-cancel' onClick={() => handleCancel()}>
                  关闭
                </span>
                <span className='org-btn-ok' onClick={() => handleConfim()}>
                  确定
                </span>
              </div>
            </div>
            <div className='OrganizeSelect_options'>
              <div className='OrganizeSelect_options-left'>
                <span>{targetOrg?.name}</span>
              </div>
              {parentOrgList.length ? (
                <div className='OrganizeSelect_options-right' onClick={() => switchPar()}>
                  <Image
                    src='https://s.huiyunban.cn/63c0e97f00f8fb2196d9acb4.png'
                    width={32}
                    height={32}
                  ></Image>
                  <span>返回上级</span>
                </div>
              ) : null}
            </div>
            <div className='OrganizeSelect_tree'>
              <div className='OrganizeSelect_tree-group'>
                <div className='OrganizeSelect_tree-group-list'>
                  {currentOrgList.map((cur: any) => (
                    <div
                      key={cur?.id}
                      onClick={() => cur?.is_click !== 0 && selectOrg(cur)}
                      className={`OrganizeSelect_tree-group-list-item ${cur.id === targetOrg?.id}`}
                    >
                      <Checkbox
                        disabled={cur?.is_click === 0}
                        // disabled
                        style={{
                          '--icon-size': '24px',
                          '--font-size': '14px',
                          '--gap': '6px'
                        }}
                        // checkedColor='#0256ff'
                        value={targetOrg?.id || ''}
                      >
                        {cur.name}
                      </Checkbox>

                      {cur.children?.length ? (
                        <div className='look-children' onClick={() => switchChild(cur)}>
                          <Image
                            src='https://s.huiyunban.cn/1702630947737833472.png'
                            width={27}
                            height={24}
                          ></Image>
                          下级
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      }
    </>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
OrganizeSelect.convertValue = (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
    if (values?.[config?.names[0]] && values?.[config?.names[1]]) {
      return {
        [config?.names[0]]: {
          id: values[config?.names[0]],
          name: values[config?.names[1]]
        }
      }
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
OrganizeSelect.transform = (value: any, config?: any) => {
  if (value && Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config?.names[0]]: value?.id,
      [config?.names[1]]: value?.name
    }
  }
  return value?.id
}

export default OrganizeSelect
