import { http } from '@/utils'

export const organizationsChild = (data: {}): any =>
  http.post(`/hrm/organizations/child`, { data: { ...data } })

export const organizationsChildOnlyShop = (): any =>
  http.post(`/hrm/organizations/child`, { data: { type_codes: ['STORE'] } })
export const organizationsChildNoOnlyShop = (data: {}): any =>
  http.post(`/hrm/organizations/child?only_shop=false`, { data: data })

// 职级类型 获取当前登录人的组织下的职级类型
export const getGroupNodeTree = (code?: string): any => {
  return http.get(`/system/group/nodes`, { data: { code, only_enable: true } })
}
