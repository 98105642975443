import { action, makeObservable, observable } from 'mobx'

class ScreenShotStore {
  constructor() {
    makeObservable(this)
  }

  @observable screenShotRef: any

  @action
    changeScreenShotRef = (screenShotRef: any) => {
      this.screenShotRef = screenShotRef
    }
}

export const screenShotStore = new ScreenShotStore()

export default screenShotStore
