import { observable, makeAutoObservable, action } from 'mobx'
import { createContext } from 'react'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  @observable education: any[] = []
  @observable educationStatus: string = 'add'

  @observable employeeIds: any = []
  @observable healthCertificate: any[] = []

  @observable editHealthItem: any = {}

  @observable healthCertificateStatus: string = 'add'

  @observable concat: any[] = []

  @observable concatStatus: string = 'add'

  @observable qrcodeId: string = ''

  @observable cacheEducationJson: any

  @observable experience: any[] = []
  @observable experienceStatus: string = 'add'
  @observable cacheExperienceJson: any

  @observable modelPage: Record<string, any> = {}

  @action
  changeEducation = (data: any) => {
    this.education = [...this.education, ...[data]]
  }

  @action
  changeAllEducation = (education: any[]) => {
    this.education = education
  }

  @action
  changeEducationStatus = (status: string) => {
    this.educationStatus = status
  }

  @action
  changeEmployeeIds = (data: any) => {
    this.employeeIds = data ? [data] : []
  }

  @action
  changeEditHealthItem = (data: any) => {
    console.log('data:---->>>>', data)
    this.editHealthItem = data || {}
  }
  @action
  changeHealthCertificate = (data: any) => {
    this.healthCertificate = [...this.healthCertificate, ...[data]]
  }

  @action
  changeAllHealthCertificate = (healthCertificate: any[]) => {
    this.healthCertificate = healthCertificate
  }

  @action
  changeHealthCertificateStatus = (status: string) => {
    this.healthCertificateStatus = status
  }
  @action
  changeConcatStatus = (status: string) => {
    this.concatStatus = status
  }

  @action
  changeExperience = (data: any) => {
    this.experience = [...this.experience, ...[data]]
  }

  @action
  changeExperienceStatus = (status: string) => {
    this.experienceStatus = status
  }

  @action
  changeAllExperience = (experience: any[]) => {
    this.experience = experience
  }

  @action
  changeModelPageConfig = (code: string, config: Record<string, any>) => {
    const _config = {
      [code]: { ...this.modelPage?.[code], ...config }
    }
    this.modelPage = { ...this.modelPage, ..._config }
  }

  @action
  changeCacheExperienceJson = (cacheExperienceJson: any[]) => {
    this.cacheExperienceJson = cacheExperienceJson
  }

  @action
  changeConcat = (concat: any[]) => {
    this.concat = concat
  }

  @action
  changeQrcodeId = (qrcodeId) => {
    this.qrcodeId = qrcodeId
  }

  @action
  changeCacheEducationJson = (cacheEducationJson: any[]) => {
    this.cacheEducationJson = cacheEducationJson
  }
}
const entryStore = new Store()
export const entryStoreWithContext = createContext(entryStore)
export default entryStore
