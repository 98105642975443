import { Button, Loading } from 'antd-mobile'
import { observer } from 'mobx-react-lite'
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react'

import { HErrorBlock } from '@/components'
import PageLayout from '@/components/Layout'
import unifiedOptionStore, { StatusCode } from '@/store/unified/unifiedOptionStore'
import unifiedStore from '@/store/unified/unifiedStore'

import Layout from './components/Layout'

import usePage, { PageOption } from './hooks/usePage'

/**
 * 通用页面（权限控制/option状态处理）
 * @param props
 * @returns
 */
function UnifiedPage({
  pageOption,
  extraName,
  className,
  children
}: {
  pageOption?: PageOption
  extraName?: string
  className?: string
  children?: ReactElement
}) {
  const optionStore = useContext(unifiedOptionStore)
  const stateStore = useContext(unifiedStore)
  const [inited, setInited] = useState(false)
  const { currentOption, currentStatus, currentAuthCode }: any = useMemo(
    () => ({
      currentOption: pageOption?.option || optionStore,
      currentStatus: pageOption?.optionStatus || optionStore?.status,
      currentAuthCode: extraName
        ? optionStore.extraOption[extraName]?.pageOption?.authCode
        : (pageOption?.option || optionStore)?.pageOption?.authCode
    }),
    [pageOption?.option, pageOption?.optionStatus, optionStore]
  )
  useEffect(() => {
    setInited(true)
    return () => {
      // 清空state
      stateStore.clear()
      setInited(false)
    }
  }, [])
  if (!inited) {
    return <Loading className='init-loading' />
  }
  return (
    <PageLayout code={currentAuthCode || ''}>
      {currentStatus === StatusCode.done ? (
        // 通用页面组件
        <Layout option={currentOption} className={className || ''} />
      ) : currentStatus === StatusCode.error ? (
        // 错误页面
        <HErrorBlock
          className='page-error-wrap'
          status='error'
          size='large'
          title='不好意思，出现了一些小问题'
        >
          <Button onClick={() => window.location.reload()}>刷新页面</Button>
        </HErrorBlock>
      ) : (
        // 加载中
        <Loading className='init-loading' />
      )}
      {children}
    </PageLayout>
  )
}

UnifiedPage.usePage = usePage
export default observer(UnifiedPage)
