import { relative } from 'path'

import { NoticeBar, Toast } from 'antd-mobile'
import React, { useEffect, useState } from 'react'

// 自定义 RTT 检测时长，ping文件 超过 600 则为网络慢
const RTT_CHECK = 1000
// 网络慢时延长检测时间, 快网络15秒检查一次，慢网络30秒检查一次
const FAST_DELAY_TIME = 15000
const SLOW_DELAY_TIME = 30000
// 弹窗消失时间
const TIME = 3000
// 网络提示词
const NETWORK_TIPS_MAP = {
  WEAK: '网络较慢，可能会影响您的体验',
  NONE: '目前网络不可用',
  THROTTLE: '网络连接不稳定'
}

const NetworkStatus = () => {
  let intervalId: any = null
  const [slowNetwork, setSlowNetwork] = useState(false) // 网络是否较慢
  const [alertVisible, setAlertVisible] = useState(false) // 弱网提示是否可见
  const [alertMessage, setAlertMessage] = useState('') // 弱网提示信息

  // 检测 Network Information API 支持
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection

  const showAlert = (msg: string) => {
    Toast.clear()
    Toast.show({
      content: msg,
      duration: TIME
    })
    // setAlertVisible(true)
    // setAlertMessage(msg)
    // setTimeout(() => {
    //   setAlertVisible(false)
    // }, TIME)
  }

  // 监听在线/离线状态
  const updateOnlineStatus = () => {
    if (!navigator.onLine) {
      showAlert(NETWORK_TIPS_MAP.NONE)
    }
  }

  // 检测 Network Information API 支持
  const updateNetworkInfo = () => {
    console.log('网络类型:', connection.effectiveType)

    // 当网络类型为 2g 或者 3g 时，提示网络慢
    if (['2g', '3g'].includes(connection.effectiveType)) {
      showAlert(NETWORK_TIPS_MAP.WEAK)
      setSlowNetwork(true)
    } else {
      setSlowNetwork(false)
    }
  }

  // 自定义 RTT 检测，动态调整检测间隔
  const checkRTT = () => {
    // 如果离线则不做检测
    if (!navigator.onLine) return

    const startTime = Date.now()
    // fetch(`${__ROUTE_PREFIX__}static/images/ping.svg`)
    fetch(`https://s.huiyunban.cn/ping.svg`)
      .then(() => {
        const currentRTT = Date.now() - startTime
        if (currentRTT > RTT_CHECK) {
          showAlert(NETWORK_TIPS_MAP.WEAK)
          setSlowNetwork(true)
        } else {
          setSlowNetwork(false)
        }
      })
      .catch(() => {
        showAlert(NETWORK_TIPS_MAP.THROTTLE)
        setSlowNetwork(true)
      })
  }

  useEffect(() => {
    // 在/离线检测
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    // 检测 Network Information API 支持
    if (connection) {
      connection.addEventListener('change', updateNetworkInfo)
      updateNetworkInfo()
    } else {
      // 网络慢时延长检测时间
      checkRTT()
      intervalId = setInterval(checkRTT, SLOW_DELAY_TIME)
    }

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)

      intervalId && clearInterval(intervalId)

      if (connection) {
        connection.removeEventListener('change', updateNetworkInfo)
      }
    }
  }, [slowNetwork])

  return (
    <>
      {alertVisible && (
        <NoticeBar
          style={{
            border: 0,
            zIndex: 1000,
            position: 'fixed',
            width: '100%'
          }}
          onClose={() => setAlertVisible(false)}
          content={alertMessage}
          color='alert'
          closeable={false}
        />
      )}
    </>
  )
}

export default NetworkStatus
