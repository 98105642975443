import { IPageProps } from '@/components/UnifiedPage/interface'

import { IStageState } from './interface'
/**
 * Json配置的默认值
 */
export const DefaultOption: IPageProps = {
  pageOption: {
    title: '通用页面',
    // 默认不调用接口
    immediate: false
  },
  filterOption: {
    is_show: true,
    form_list: []
  },
  tableOption: {
    row_selection: true,
    table_columns: [],
    btn_list: []
  },
  formOption: {
    formInfo: []
  }
}

export const DefaultState: IStageState = {
  pageState: {},
  filterState: {},
  tableState: {},
  formState: {}
}
