import { useSetState } from 'ahooks'
import { Cascader } from 'antd-mobile'
import { useCallback, useEffect } from 'react'

import HCell from '../../HYBVant/HCell'

import { workFlowTreeApi } from './service'

import './index.less'
function HCascader({ onConfirm, value, query, ...elementProps }: any) {
  const [{ visible, cascaderList, originalCascaderList }, setState] = useSetState<any>({
    visible: false,
    cascaderList: [],
    originalCascaderList: []
  })
  const transformedData = (data: any) => {
    return data?.map((topLevel: any) => ({
      value: topLevel.code,
      label: topLevel.name,
      children: topLevel?.children?.map((child: any) => ({
        ...child,
        value: child.id,
        label: child.name
      }))
    }))
  }

  const fetchCascader = useCallback(async () => {
    const { data } = await workFlowTreeApi({
      enable_status: 1,
      query_type: elementProps?.isDetails
        ? null
        : elementProps?.applyInitiate === 'INDIVIDUAL_INITIATED'
          ? 1
          : 2
    })
    setState({ cascaderList: transformedData(data), originalCascaderList: data })
  }, [])
  useEffect(() => {
    fetchCascader()
  }, [elementProps?.isDetails, elementProps?.applyInitiate])
  function selectObjectsByValueAndId<T>(data: T[]): T[] {
    // 查找具有特定值的顶层对象
    const topLevelItem: any = cascaderList?.find((item: any) => data.includes(item.value)) || {}

    // 查找具有特定ID的子对象
    const childItem = topLevelItem?.children?.find((item: any) => data.includes(item.id)) || {}

    // 返回包含顶层对象和子对象的数组
    return [topLevelItem, childItem]
  }
  const change = (data: any) => {
    const path = data?.length > 1 ? selectObjectsByValueAndId(data) : []
    onConfirm?.({ value: data, path })
  }
  useEffect(() => {
    if (
      elementProps?.applyInitiate === 'AGENT_INITIATED' &&
      cascaderList?.some((x: any) => x?.code === 'ASSIST')
    ) {
      setState({
        cascaderList: cascaderList
          ?.map((x: any) => {
            if (x?.code === 'ASSIST') return null
            if (x?.code === 'PERSONNEL') {
              x.children = x.children?.filter((y: any) => y?.code !== 'SYSTEM_EMPLOYMENT')
            }
            return x
          })
          .filter((z: any) => !!z)
      })
    }
    if (elementProps?.applyInitiate === 'INDIVIDUAL_INITIATED') {
      setState({ cascaderList: transformedData(originalCascaderList) })
    }
  }, [elementProps?.applyInitiate, originalCascaderList])
  console.log(cascaderList, '<<<<---cascaderList---items', value, elementProps)
  const bool: boolean = !elementProps?.disabled && !elementProps?.readed
  return (
    <>
      <HCell
        value={(value?.path?.map((x: any) => x?.label || x?.name) || []).join('-')}
        onClick={() => bool && setState({ visible: true })}
        disabled={elementProps?.disabled}
        readed={elementProps?.readed}
      />
      {visible && (
        <Cascader
          {...elementProps}
          visible={visible}
          value={value?.value || []}
          title={elementProps.title || '发起申请项目'}
          options={cascaderList || []}
          closeable
          onClose={() => {
            setState({ visible: false })
          }}
          onConfirm={change}
        />
      )}
    </>
  )
}

// /**
//  * 前置化转换 - 用于回显
//  * @param value
//  * @param config
//  * @returns newValue
//  */
// HCascader.convertValue = (value: any, config?: any, values?: any) => {
//   if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
//     return {
//       [config.names[0]]: {
//         value: [values?.[config.names?.[0]], values?.[config.names?.[1]]],
//         path: [{ name: values?.[config.name] }]
//       }
//     }
//   }
//   if (config?.name) {
//     return { value, path: [{ name: values?.[config.name] }] }
//   }
//   return value
// }
// /**
//  * 提交时转换
//  * @param value
//  * @param config
//  * @returns newValue
//  */
// HCascader.transform = (value: any, config?: any, values?: any) => {
//   if (
//     Array.isArray(value?.value) &&
//     value?.value?.length === 2 &&
//     Array.isArray(config?.names) &&
//     config?.names?.length === 2
//   ) {
//     // console.log('[config?.names[0]]:', values, [config?.names[0]], values[config?.names[0]])
//     // 单选
//     return {
//       [config?.names[0]]: value?.value?.[0],
//       [config?.names[1]]: value?.value?.[1]
//     }
//   }
//   return Array.isArray(value?.value) ? value?.value?.[0] : value?.value
// }

export default HCascader
