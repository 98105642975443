/**
 * 企微设置权限列表
 */
export const WxworkApiList: string[] = [
  'openSetting',
  'getSetting',
  'authorize',
  'scanCode',
  'makePhoneCall',
  'chooseImage',
  'getImageInfo',
  'openLocation',
  'chooseLocation',
  'getLocation',
  'startLocationUpdate',
  'stopLocationUpdate',
  'onLocationUpdate',
  'offLocationUpdate'
]
/**
 * 企微设置权限码
 */
export const WxworkAuthType: any = {
  location: 'getLocation'
}
/**
 * 企微oauth跳转URL
 */
export const WxworkAuthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize'
