import { Form } from 'antd-mobile'
import { pick } from 'lodash-es'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'

import NewEditForm from '@/components/NewEditForm'
import jobStore from '@/store/components/entryJob'

import HPopup from '../../HYBVant/HPopup'

import { json } from './enum'

import './index.less'

const Popup: React.FC<any> = (props) => {
  const entryStore = useLocalObservable(() => jobStore)
  const [form] = Form.useForm()
  const { concatStatus, concat } = useMemo(
    () => pick(entryStore, ['concatStatus', 'concat']),
    [entryStore.concatStatus, entryStore.concat]
  )

  const onFinish = (fieldValues: any) => {
    switch (concatStatus) {
      case 'Add':
        entryStore.changeConcat([...concat, ...[fieldValues]])
        break
      case 'Edit':
        entryStore.changeConcat(
          concat.map((x) => {
            if (x.active) return fieldValues
            return x
          })
        )
        break
    }
    props.onCancel()
  }

  const deleteInfo = () => {
    concatStatus === 'Edit' && entryStore.changeConcat(concat.filter((x) => !x.active))
    props.onCancel()
  }

  const getFormButtons = (hasDelete?: boolean) => {
    const btns = [
      {
        key: 'remove',
        title: '删除',
        className: 'delete-btn',
        onClick: deleteInfo
      },
      {
        key: 'submit',
        title: '保存'
      }
    ]
    return hasDelete ? btns : btns.slice(1)
  }

  useEffect(() => {
    const setFieldValues = () => {
      const fields = concat.find((x) => x.active)
      form.setFieldsValue({ ...fields })
    }
    concatStatus === 'Edit' && setFieldValues()
  }, [concatStatus])

  return (
    <HPopup title='紧急联系人' hasOk={false} className='lg' {...props}>
      <NewEditForm
        formInst={form}
        formInfo={json.formInfo}
        onFinish={onFinish}
        formButtons={getFormButtons(props.modalData?.status === 'Edit')}
      />
    </HPopup>
  )
}

export default observer(Popup)
