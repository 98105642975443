import { cloneDeep } from 'lodash-es'
import { action, makeObservable, observable, toJS } from 'mobx'
import { createContext } from 'react'

import {
  IFilterOption,
  IFormOption,
  IPageOption,
  IPageProps,
  ITableOption
} from '@/components/UnifiedPage/interface'

import { DefaultOption } from './const'
// import { getDictListGroup } from '../service'
export enum StatusCode {
  pending = 'PENDING',
  done = 'DONE',
  error = 'ERROR'
}

class UnifiedOptionStore {
  constructor() {
    makeObservable(this)
  }
  // page state
  @observable pageOption: IPageOption = DefaultOption.pageOption
  // filter state
  @observable filterOption: IFilterOption | false = DefaultOption.filterOption
  // table state
  @observable tableOption: ITableOption = DefaultOption.tableOption
  // form state
  @observable formOption: IFormOption = DefaultOption.formOption
  // 缓存当前所有state
  @observable stageState: IPageProps = {
    pageOption: DefaultOption.pageOption,
    filterOption: DefaultOption.filterOption,
    tableOption: DefaultOption.tableOption,
    formOption: DefaultOption.formOption
  }
  // 一个页面多个UnifieldPage 例: {modal: IStageState}
  @observable extraOption: { [key: string]: IPageProps } = {}
  // loading status
  @observable status: StatusCode = StatusCode.pending
  // loading status for map
  @observable statusMap: { [key: string]: StatusCode } = {}

  /**
   * 设置option状态
   * @param state
   */
  @action
  setStatus(status: StatusCode, extraName?: string) {
    if (extraName) {
      this.statusMap[extraName] = status
    } else {
      this.status = status
    }
  }

  @action
  setPageOption(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraOption[extraName].pageOption = {
        ...this.extraOption[extraName].pageOption,
        ...state
      }
    } else {
      this.pageOption = {
        ...this.pageOption,
        ...state
      }
    }
  }
  @action
  setFilterOption(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      const filterOption = (
        this.extraOption[extraName].filterOption
          ? this.extraOption[extraName].filterOption
          : DefaultOption.filterOption
      ) as IFilterOption
      this.extraOption[extraName].filterOption = {
        ...filterOption,
        ...state
      }
    } else {
      const filterOption = (
        this.filterOption ? this.filterOption : DefaultOption.filterOption
      ) as IFilterOption
      this.filterOption = {
        ...filterOption,
        ...state
      }
    }
  }
  @action
  setTableOption(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraOption[extraName].tableOption = {
        ...this.extraOption[extraName].tableOption,
        ...state
      }
    } else {
      this.tableOption = {
        ...this.tableOption,
        ...state
      }
    }
  }
  @action
  setFormOption(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraOption[extraName].formOption = {
        ...this.extraOption[extraName].formOption,
        ...state
      }
    } else {
      this.formOption = {
        ...this.formOption,
        ...state
      }
    }
  }
  /**
   * 修改表单项
   * @param items {'base.code': { name: 'code', type: 'Input', ... }}
   * base = formInfo[i].id
   * code = formInfo[i].form_list[j].name
   * 值 可以只传修改项，完整值会先取当前的值，例：{ hide: true }，自动带上name,type,single_line等
   */
  @action
  setFormOptionItem(items: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      // TODO
    } else {
      const info = cloneDeep(this.formOption.formInfo)
      Object.entries(items).forEach(([key, value]) => {
        const columns = key.split('.')
        if (columns?.length === 2) {
          const blockIndex = info.findIndex((item: any) => item.id === columns[0])
          const itemIndex = info?.[blockIndex]?.form_list?.findIndex(
            (item: any) => item.name === columns[1]
          )
          if (blockIndex > -1 && itemIndex > -1) {
            info[blockIndex].form_list[itemIndex] = {
              ...info[blockIndex].form_list[itemIndex],
              ...value
            }
          } else {
            throw new Error(
              `设置表单项找不到 ${key} 当前formOption如下\n${JSON.stringify(toJS(this.formOption))}`
            )
          }
        }
      })
      this.formOption = {
        ...this.formOption,
        formInfo: info
      }
    }
  }
  /**
   * 获取表单项
   * @param key 'base.code'
   */
  @action
  getFormOptionItem(key: string, extraName?: string) {
    if (extraName) {
      // TODO
    } else {
      const info = this.formOption.formInfo
      const columns = key.split('.')
      if (columns?.length === 2) {
        const blockIndex = info.findIndex((item: any) => item.id === columns[0])
        const itemIndex = info?.[blockIndex]?.form_list?.findIndex(
          (item: any) => item.name === columns[1]
        )
        if (blockIndex > -1 && itemIndex > -1) {
          return info[blockIndex].form_list[itemIndex]
        } else {
          throw new Error(
            `获取表单项找不到 ${key} 当前formOption如下\n${JSON.stringify(toJS(this.formOption))}`
          )
        }
      }
    }
    return {}
  }
  /**
   * 全量设置并缓存
   */
  @action
  setOption(option: IPageProps, extraName?: string) {
    if (extraName) {
      this.extraOption[extraName] = {
        ...this.extraOption[extraName],
        ...option
      }
    } else {
      const { pageOption, filterOption, tableOption, formOption } = option
      this.pageOption = pageOption
      this.filterOption = filterOption
      this.tableOption = tableOption
      this.formOption = formOption
      this.stageState = option
    }
  }
  /**
   * 重置option到上一次缓存
   */
  @action
  reset() {
    const { pageOption, filterOption, tableOption, formOption } = this.stageState
    this.pageOption = pageOption
    this.filterOption = filterOption
    this.tableOption = tableOption
    this.formOption = formOption
    this.extraOption = {}
  }
  /**
   * 清空option
   */
  @action
  clear() {
    this.status = StatusCode.pending
    this.pageOption = DefaultOption.pageOption
    this.filterOption = DefaultOption.filterOption
    this.tableOption = DefaultOption.tableOption
    this.formOption = DefaultOption.formOption
    this.stageState = DefaultOption
    this.extraOption = {}
  }
}
export const unifiedOptionStoreNoContext = new UnifiedOptionStore()

export default createContext(unifiedOptionStoreNoContext)
