import { storage, service } from './utils'

/**
 * 钉钉ticket
 * @param param0 code
 * @returns token
 */
export const getDingTicket = ({ corp_id }: { corp_id: string }) =>
  service<IResponseType<any>>({
    method: 'get',
    url: `/app/ding/inner/js_api_ticket?corp_id=${corp_id}&url=${encodeURIComponent(location.href)}`
  })

/**
 * 飞书ticket
 * @param param0 code
 * @returns token
 */
export const getFeishuTicket = ({ app_id }: { app_id: string }) =>
  service<IResponseType<any>>({
    method: 'get',
    url: `/app/fs/inner/js_api_ticket?app_id=${app_id}&url=${encodeURIComponent(location.href)}`
  })

/**
 * 企微ticket
 * @param param0 code
 * @returns token
 */
export const getWxworkTicket = (url: string) => {
  const corp_id = storage.get('corp_id')
  return service<IResponseType<any>>({
    method: 'get',
    url: `/app/qw/inner/js_api_ticket?corp_id=${corp_id}&url=${encodeURIComponent(url)}`
  }).then((res: any) => ({ ...res.data, nonceStr: res.data?.nonce_str }))
}
