import { useSetState } from 'ahooks'
import { Button } from 'antd-mobile'
import { useContext, useEffect, useRef } from 'react'
import './index.less'
/**
 * 按钮组
 */
export default ({
  value,
  onConfirm,
  dataList = [],
  popup = 'array'
}: {
  value?: any
  onConfirm: (value: any) => void
  dataList: any[]
  popup: string
}) => {
  const [{ buttonValue }, setState] = useSetState<any>({
    buttonValue: null
  })
  const onClickOk = (v: any) => {
    const _value = popup === 'string' ? v : [v]
    onConfirm(v ? _value : null)
  }
  useEffect(() => {
    // if (!value && value !== 0) {
    //   onConfirm(value)
    //   return
    // }
    const _value = popup === 'string' ? value : value?.[0]
    setState({ buttonValue: _value || null })
  }, [value, popup])
  console.log('buttonGroup,--->>>>', value, popup)
  return (
    <div className='hyb-button-group'>
      {dataList?.map((item: any) => (
        <Button
          fill='outline'
          color='primary'
          key={item.value}
          onClick={() => onClickOk(item.value)}
          className={item.value === buttonValue || null ? 'active' : ''}
        >
          {item.text}
        </Button>
      ))}
    </div>
  )
}
