/**
 * 飞书app id（测试环境）
 */
export const TTAppID = 'cli_a783ba6d9f9b900e'

export const TTSetting: any = {
  /**
   * 是否授予了地理位置权限
   */
  location: 'scope.userLocation',
  /**
   * 是否授予了用户权限
   */
  user: 'scope.userInfo',
  /**
   * 是否授予了麦克风权限。
   */
  record: 'scope.record',
  /**
   * 是否授予了剪贴板权限。
   */
  clipboard: 'scope.clipboard',
  /**
   * 是否授予了保存到相册权限。
   */
  writePhotosAlbum: 'scope.writePhotosAlbum',
  /**
   * 是否授予了蓝牙权限
   */
  bluetooth: 'scope.bluetooth'
}
