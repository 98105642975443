import service from '@/utils/service'

export const dynamicFields = (data: any, qrCodeId: string): any => {
  if (qrCodeId) {
    return service({ method: 'post', url: `/hrm/dynamic_fields/no_auth/${qrCodeId}`, data })
  }
  return service({ method: 'post', url: `/hrm/dynamic_fields`, data })
}

export const dynamicFieldsDetail = (data: any, qrcodeId: string): any =>
  service({ method: 'post', url: `/hrm/dynamic_field/details/no_auth/${qrcodeId}`, data })

export const dynamicFieldsEditDetail = (data: any): any =>
  service({ method: 'post', url: `/hrm/dynamic_field/detail`, data })

export const dynamicFieldsDelete = (data: any): any =>
  service({ method: 'delete', url: `/hrm/dynamic_field/detail`, data })

export const dynamicFieldsList = (employee_id: string, code: string): any =>
  service({ method: 'get', url: `/hrm/dynamic_field/detail/${employee_id}/${code}` })
