import { Button } from 'antd-mobile'
import classNames from 'classnames'
import { useLocalStore, observer } from 'mobx-react'
import React from 'react'

import authStore from '@/store/global/authStore'

import './index.less'

interface BtnGroupProps {
  code: string[]
  onOkFrist?: () => void
  onOkNext?: () => void
  text: string[]
  style?: any[]
  fristLoading?: boolean
  nextLoading?: boolean
}

const BtnGroup: React.FC<BtnGroupProps> = (props) => {
  const {
    code,
    text,
    style,
    onOkFrist,
    onOkNext,
    fristLoading = false,
    nextLoading = false
  } = props
  const { getCodeFun } = useLocalStore(() => authStore)

  const display = () => {
    if (text?.length === 1) return true
    if (code?.length === 0) return true
    return getCodeFun(code?.[0]) || getCodeFun(code?.[1])
  }
  const displayFrist = () => {
    if (text?.length === 1) return true
    if (code?.length === 0) return true
    return getCodeFun(code?.[0])
  }

  const displayFristSingle = () => {
    if (text?.length === 1) return true
    if (code?.length === 0) return false
    return !getCodeFun(code?.[0])
  }

  const displayNext = () => {
    if (text?.length === 1) return false
    if (code?.length === 0) return true
    return getCodeFun(code?.[1])
  }
  const displayNextSingle = () => {
    if (text?.length === 1) return true
    if (code?.length === 0) return false
    return !getCodeFun(code?.[1])
  }
  const fristStyle = style?.[0] || {}
  const nextStyle = style?.[text?.length === 1 ? 0 : 1] || {}

  return (
    <>
      {display() && (
        <div className='opera-btn-group-new'>
          {displayFrist() && (
            <Button
              disabled={fristLoading}
              className={classNames('delete-btn', { 'delete-btn-single': displayNextSingle() })}
              onClick={onOkFrist}
              style={fristStyle}
            >
              {text?.[0]}
            </Button>
          )}
          {displayNext() && (
            <Button
              disabled={nextLoading}
              className={classNames('add-btn', { 'add-btn-single': displayFristSingle() })}
              onClick={onOkNext}
              style={nextStyle}
            >
              {text?.[1] || text?.[0]}
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default observer(BtnGroup)
