import { Popup, Radio, List } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'

import './index.less'

const RidioSelect: React.FC<any> = ({ onConfirm, value, ...elementProps }) => {
  const [show, setShow] = useState(false)
  const [values, setValue] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    setValue('')
    onConfirm?.(value)
    setName(elementProps?.dataList.find((x: any) => x.value === value)?.name)
  }, [])

  useEffect(() => {
    if (show) setValue('')
  }, [show])

  return (
    <>
      <div className='hselect' onClick={() => !elementProps?.disabled && setShow(true)}>
        <div>{name || elementProps?.dataList?.find((x: any) => x.value === value)?.name}</div>
        <RightOutline
          className={classnames('pesonnel-icon', { disabled: elementProps?.disabled })}
        />
      </div>
      <Popup position='bottom' visible={show} onClose={() => setShow(false)}>
        <div className='hselect-popup-ridio'>
          <div className='hselect-popup-ridio-opera'>
            <div
              onClick={() => {
                setShow(false)
              }}
            >
              取消
            </div>
            <div
              onClick={() => {
                setShow(false)
                setName(elementProps?.dataList?.find((x: any) => x.value === values)?.name)
                onConfirm?.(values)
              }}
            >
              确定
            </div>
          </div>
          <div className='hselect-popup-ridio-context'>
            <Radio.Group value={values || value}>
              <List>
                {elementProps?.dataList?.map((x: any) => (
                  <List.Item
                    title={x.name}
                    clickable
                    onClick={() => !x?.disabled && setValue(x?.value)}
                    prefix={<Radio disabled={x?.disabled} value={x?.value}></Radio>}
                  />
                ))}
              </List>
            </Radio.Group>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default RidioSelect
