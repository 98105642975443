import { Image } from 'antd-mobile'
import { AddSquareOutline } from 'antd-mobile-icons'
import { isEmpty } from 'lodash-es'
import { toJS } from 'mobx'
import { useLocalStore, observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import jobStore from '@/store/components/entryJob'
import dictStore from '@/store/global/dictStore'
import { getUrlParams } from '@/utils'

import Popup from './Popup'
import PopupNoAuth from './PopupNoAuth'
import './index.less'

const Education: React.FC<any> = ({ onConfirm, value }) => {
  const {
    education = [],
    changeEducationStatus,
    changeAllEducation
  } = useLocalStore(() => jobStore)
  const location = useLocation()
  const store = useLocalStore(() => dictStore)
  const { open, modalData, onCancel, onOpen } = usePopup({})
  const { id } = getUrlParams()
  const isNoAuth = location.pathname.match('/entry/fast')
  const qrcodeId = isNoAuth ? id : ''

  const add = () => {
    changeEducationStatus('Add')
    onOpen({ status: 'Add' })
  }

  const edit = (index: number) => {
    education.map((x, i) => {
      if (index === i) {
        x.active = 1
      } else {
        x.active = 0
      }
      return x
    })
    changeEducationStatus('Edit')
    onOpen({ status: 'Edit' })
  }

  useEffect(() => {
    if (onConfirm) onConfirm(education)
  }, [education])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      !value.some((x) => education.includes(x)) && changeAllEducation(value)
    } else changeAllEducation([])
  }, [value])

  console.log(toJS(education), 'education', qrcodeId)

  return (
    <div className='education'>
      {!isEmpty(education) && (
        <div className='education-content-wrapper'>
          {education.map((x, index) => (
            <div key={x} className='education-content'>
              <div className='education-content-col-1'>
                <div className='education-content-1'>
                  {x.graduate_school}（
                  {
                    store
                      .getOptions('HRM_EDUCATION_LEVEL')
                      ?.find((y: any) => y.value === x.education_level)?.name
                  }
                  ）
                </div>
                <div className='education-content-3'>
                  {x.start_date || '-'}&nbsp;&nbsp;至&nbsp;&nbsp;{x.end_date || '-'}
                </div>
              </div>
              <div className='education-content-col-2'>
                <Image
                  onClick={() => edit(index)}
                  className='education-content-col-2-img'
                  src='https://s.huiyunban.cn/1747191709529280512.png'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='education-tip'>
        <AddSquareOutline />
        <div className='education-tip-title' onClick={add}>
          添加教育经历
        </div>
      </div>
      {open && qrcodeId ? (
        <PopupNoAuth visible={open} onCancel={onCancel} qrcodeId={qrcodeId} modalData={modalData} />
      ) : open && !qrcodeId ? (
        <Popup visible={open} onCancel={onCancel} modalData={modalData} />
      ) : null}
    </div>
  )
}

export default observer(Education)
