import React, { memo, useMemo, useState } from 'react'

import './index.less'

const Expand: React.FC<any> = ({ dataSource }) => {
  const [expand, setExpand] = useState(false)

  const expandItem = useMemo(() => {
    if (!dataSource.remark) return null
    const wordLen = dataSource.remark.length
    const chLen = dataSource.remark.match(/[^\x00-\xff]/g)?.length || 0
    const len = wordLen + chLen + 6
    const isWrap = 552 - 12 * len < 0
    return isWrap ? (
      <span className='time-line-item-content-remark-link' onClick={() => setExpand(!expand)}>
        {expand ? '收起' : '展开'}
      </span>
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, expand])

  return (
    dataSource.remark && (
      <div className={`time-line-item-content-remark${expand ? ' expand' : ''}`}>
        <span className='time-line-item-content-remark-label'>备注：</span>
        <span className='time-line-item-content-remark-value'>{dataSource.remark}</span>
        {expandItem}
      </div>
    )
  )
}

export default memo(Expand)
