import { http } from '@/utils'

export const postSearchApi = () => http.post('/hrm/post/search', { paged: false, enable_status: 1 })

export const positionSearchApi = () => http.post('/hrm/positions', { paged: false, enable_status: 1 })

export const rankSearchApi = () => http.post('/hrm/position/ranks', { paged: false, enable_status: 1 })

const scheduleSearchFilterApi = () =>
  http.get(`/schedule/shift`, {
    params: { paged: false, enable_status: 1, add_rest_shift: true, with_no_auth: true }
  })

export const scheduleSearchApi = async () => {
  const { data } = await scheduleSearchFilterApi()
  const list = data
    ?.filter((x: any) => x.code !== 'HXB-00001' && x.code !== 'HXB-10001')
    ?.map((x: any) => ({ ...x, label: x?.name, value: x?.id }))
  return Promise.resolve({
    data: list,
    code: 2000
  })
}
