import { useSetState } from 'ahooks'
import { Picker } from 'antd-mobile'
import dayjs from 'dayjs'

import { observer, useLocalStore } from 'mobx-react-lite'
import react from 'react'

import DatePicker from '@/components/NewEditForm/UILibrary/HYBFilter/DatePicker'

// import RootPortal from '@/components/RootPortal'
import dictStore from '@/store/global/dictStore'

import HCell from '../../HYBVant/HCell'
import './index.less'
// import { toJS } from 'mobx'

function TodayPicker(props) {
  const { value, onConfirm, ...elementProps } = props
  console.log('todayPicker:', value)
  const [{ show, step }, setState] = useSetState<any>({
    show: false,
    step: 1
  })

  const zidianStore = useLocalStore(() => dictStore)

  const toggleShow = react.useCallback((shows) => {
    // setState('show', show)
    setState({
      show: shows,
      step: 1
    })
  }, [])

  const handleChange = (key: string, v: any) => {
    onConfirm?.({ ...value, [key]: v })
    switch (key) {
      case 'type':
        setState({ step: 2 })
        break
      case 'time':
        toggleShow(false)
        break
    }
  }

  const handleCancel = react.useCallback(() => {
    if (props.onCancel) props.onCancel()
    toggleShow(false)
  }, [])

  // console.log('todaypicker props:', toJS(zidianStore.getOptions('SCHEDULE_SHIFT_DATE_TYPE')))

  return (
    <>
      <HCell
        value={
          value?.type && value?.time
            ? `${dictStore.getLabelByValue({ dictionary: 'SCHEDULE_SHIFT_DATE_TYPE', value: value?.type })} ${value.time}`
            : null
        }
        onClick={() => !elementProps?.disabled && toggleShow(true)}
        disabled={elementProps.disabled}
      />
      {step === 2 ? (
        <DatePicker
          type={elementProps?.type || 'time'}
          value={value?.time}
          onConfirm={(e: any) => handleChange('time', e.detail.value)}
          onCancel={handleCancel}
          filter={(type, options) => {
            if (type === 'minute' && elementProps.minuteStep) {
              return options.filter((option: any) => option % elementProps.minuteStep === 0)
            }

            return options
          }}
          {...elementProps}
        />
      ) : (
        <Picker
          columns={zidianStore.getOptions('SCHEDULE_SHIFT_DATE_TYPE')}
          value={value?.type || 'CURRENT_DAY'}
          onConfirm={(e: any) => handleChange('type', e.detail.value.value)}
          onCancel={handleCancel}
          {...elementProps}
        />
      )}
    </>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
TodayPicker.convertValue = (value: any, config?: any, values?: any) => {
  if (Array.isArray(config?.names) && config?.names?.length === 2) {
    if (!values[config?.names[0]] || !values[config?.names[1]]) {
      return undefined
    }
    return {
      type: values[config?.names[0]],
      time: values[config?.names[1]]
    }
  }
  if (!Array.isArray(value) || value.length !== 2 || !value[0] || !value[1]) {
    return undefined
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
TodayPicker.transform = (value: any, config?: any) => {
  if (!value?.type || !value?.time) {
    return undefined
  }
  if (Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config?.names[0]]: value.type,
      [config?.names[1]]: value.time
    }
  }
  if (config.currentDay) {
    return `${
      value.type === 'NEXT_DAY'
        ? dayjs(config.currentDay).add(1, 'd').format('YYYY-MM-DD')
        : config.currentDay
    } ${value.time}`
  }
  return value
}
/**
 * 必填校验
 * @param value
 * @param config
 * @returns newValue
 */
TodayPicker.rules = [
  {
    validator: (rule: any, value: any) =>
      value?.type && value?.time ? Promise.resolve() : Promise.reject(new Error('请选择'))
  }
]
export default observer(TodayPicker)
