/* eslint-disable no-undef */
import { useSetState } from 'ahooks'
import { ActionSheet, Dialog } from 'antd-mobile'
import type { Action } from 'antd-mobile/es/components/action-sheet'
import dayjs from 'dayjs'
import { useLocalStore, observer } from 'mobx-react'
import React, { Fragment, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { HActionSheet } from '@/components'
import Cell from '@/components/Cell'
import SheetForm from '@/components/SheetForm'
import flowStore from '@/pages/Flow/Store'
import authStore from '@/store/global/authStore'
import { commonNav } from '@/utils'

import { ScheduleModalTitleMap } from '../../teamDaily/detail/const'

import { fetchAttendancePeriodSummary } from '../../teamDaily/detail/service'
import {
  clockRecordManual,
  workHourAdjust,
  deleteSchedule,
  attendanceShiftSchedule
} from '../../teamDaily/service'

import json from './config.json'

import './index.less'

interface ScheduleProps {
  status: number
  run: (params: string) => void
  schedule_infos: any[]
  listItem: any
  currentDay: string
  currentDayDetail: any
  filter: string
}

interface ScheduleState {
  type: 'Schedule' | 'Hour' | 'Clock'
  [index: string]: any
}

const Schedule: React.FC<ScheduleProps> = (props) => {
  const navigate = useNavigate()
  const currentItemRef = useRef<any>(null)
  const permission = useLocalStore(() => authStore)
  const { changeType } = useLocalStore(() => flowStore)
  const {
    run,
    status,
    schedule_infos,
    currentDayDetail: { project_types = [], attendance_period_id, attendance_result_type = 1 },
    currentDay,
    filter,
    listItem: { employee_id, org_id, employee_name = '' }
  } = props
  const [{ visible, open, currentItem, type, initialValues, formInfo }, setState] =
    useSetState<ScheduleState>({
      visible: false,
      open: false,
      currentItem: {},
      type: 'Clock',
      initialValues: {},
      formInfo: {}
    })
  const nextday = dayjs(currentDay).add(1, 'day').format('YYYY-MM-DD')
  const actions: Action[] =
    project_types?.map((item: any) => {
      return {
        text: item?.code === 'REISSUE_CLOCK' ? item?.name : `${item?.name}申请`,
        key: item?.code,
        onClick: () => {
          const code = item?.children?.[0]?.code || ''
          code && getProcessData(item?.code, code)
        }
      }
    }) || []
  const getProcessData = (str: string, code: any) => {
    changeType(code)
    setState({ open: false })
    jumpbuka(str)
  }
  const jumpbuka = (code: any) => {
    const clock: any = project_types?.find((x: any) => x.code === code)
    const param = {
      apply_project: clock || {},
      apply_param: {
        application_date_end: currentItemRef.current?.reissue_clock_time?.[1] || currentDay,
        application_date_start: currentItemRef.current?.reissue_clock_time?.[0] || currentDay,
        applyInitiate:
          props?.currentDayDetail?.initiate_type === 2 ? 'AGENT_INITIATED' : 'INDIVIDUAL_INITIATED',
        employee: {
          name: employee_name,
          id: employee_id
        }
      },
      type: code,
      RefererPath: 'TeamDailyDetail'
    }
    commonNav.navigateTo({
      url: `/flow/applyForm?param=${JSON.stringify(param)}`
    })
  }

  const jumpAddSchedule = () => {
    navigate(`/attendance/teamDaily/schedule?currentDay=${currentDay}`, {
      state: { currentDay, attendance_period_id, filter }
    })
  }

  const hesuan = async () => {
    const { code } = await fetchAttendancePeriodSummary({
      employee_ids: [employee_id],
      period_id: attendance_period_id
    })
    if (code === 2000) {
      run(currentDay)
      setState({ visible: false })
    }
  }

  const fetchClockRecord = async (params: any) => {
    const { code } = await clockRecordManual({
      employee_id,
      org_id,
      clock_records: [
        {
          in_out_flag: 'AUTO',
          valid: 'VALID',
          date: currentItem?.reissue_clock_time?.[0]?.split(' ')?.[0] || currentDay,
          usage: 'WORKING',
          reissue_clock_reason: 'OTHER',
          ...(params || {})
        }
      ]
    })
    if (code === 2000) hesuan()
  }

  const fetchSechduleWork = async (params: any) => {
    const { code } = await workHourAdjust({
      ...params,
      new_work_hours: params?.new_work_hours * 60,
      id: currentItem?.attendance_detail_id
    })
    if (code === 2000) {
      setState({ visible: false })
      run(currentDay)
    }
  }

  const fetchScheduleRecord = async (params: any) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const [index, item] of currentItem.schedule_plan.time_interval_list?.entries()) {
      const _datetime = `datetime.${index}`
      item.start_datetime = params[_datetime]?.[0]
      item.end_datetime = params[_datetime]?.[1]
    }
    const _params = {
      data_source: 1,
      attendance_result_id: currentItem?.attendance_detail_id,
      ...currentItem.schedule_plan
    }
    const { code, data } = await attendanceShiftSchedule(_params)

    if (data && Array.isArray(data) && data.length) {
      const content = (
        <>
          {data.map((x) => (
            <div>{x?.error_msg}</div>
          ))}
        </>
      )
      Dialog.alert({
        content,
        confirmText: '确认'
      })
      if (data?.some((x) => x.action_code === 'FORBIDDEN')) return
    }
    if (code === 2000) hesuan()
  }

  const fetchDeleteSchedule = async (item: any) => {
    const { code, data } = await deleteSchedule({
      data_source: 1,
      employee_id,
      schedule_ids: [item?.schedule_plan_id]?.filter(Boolean)
    })
    if (code === 2000) {
      if (data && Array.isArray(data) && data.length) {
        const content = (
          <>
            {data.map((x) => (
              <div>{x?.error_msg}</div>
            ))}
          </>
        )
        Dialog.alert({
          content,
          confirmText: '确认'
        })
        return
      }
      hesuan()
    }
  }

  const renderActualHour = (actual_hour: string, adjust_flag: string) => {
    return (
      <>
        {actual_hour !== null && <span style={{ fontWeight: 500 }}>{actual_hour}小时</span>}
        {adjust_flag && (
          <span style={{ fontWeight: 500, color: '#52C41A', marginLeft: 5 }}>(已调整)</span>
        )}
      </>
    )
  }

  const showIcon: boolean = status === 0
  const schedulePremmsion: boolean = permission.getCodeFun('HYB_MOVE_TEAM_DAILY_SCHEDULE_EDIT')
  const clockPremmsion: boolean = permission.getCodeFun('HYB_MOVE_TEAM_DAILY_MANUAL_REFILL')
  const hourPremmsion: boolean = permission.getCodeFun('HYB_MOVE_TEAM_DAILY_WORKHOUR')

  console.log('schedule>>>', status, showIcon, currentDay, currentItem, formInfo, initialValues)

  return (
    <div className=''>
      <div className='attendance-schedule'>
        {schedule_infos?.map((x: any, index: number) => (
          <div key={index} className='attendance-schedule-item'>
            <div className='attendance-schedule-item-title'>
              <div className='attendance-schedule-item-title-t'>{`当前班次${index + 1}: ${x?.shift_name || '-'}`}</div>
              {x?.schedule_plan_id &&
                status === 0 &&
                permission.getCodeFun('HYB_MOVE_TEAM_DAILY_SCHEDULE_DELETE') && (
                  <div
                    className='attendance-schedule-item-title-d'
                    onClick={() => {
                      status === 0 &&
                        Dialog.confirm({
                          title: '您确定要删除该班次吗？',
                          className: 'hyb-com-dialog-wrap',
                          onConfirm() {
                            fetchDeleteSchedule(x)
                          }
                        })
                    }}
                  >
                    删除班次
                  </div>
                )}
            </div>
            <Cell
              title='班次时段'
              showIcon={showIcon && schedulePremmsion}
              content={x?.schedule_slot_infos?.map((x: any) => x?.display_time)?.join(';')}
              onClick={() => {
                const list: any[] = x?.schedule_slot_infos?.map(
                  (x: any, index: number, self: any[]) => ({
                    type: 'TimePicker',
                    elementProps: {
                      currentDay
                    },
                    formItemProps: {
                      name: `datetime.${index}`,
                      required: true,
                      label: `班次时段${self?.length === 1 ? '' : index + 1}`
                    }
                  })
                )
                const _formInfo: any = json['Schedule']
                const filedValues: any = {}
                // eslint-disable-next-line no-unsafe-optional-chaining
                for (const [index, item] of x?.schedule_slot_infos?.entries()) {
                  const start_time: string = `${item.start_date_type === 'NEXT_DAY' ? nextday : currentDay} ${item?.start_datetime}`
                  const end_time: string = `${item.end_date_type === 'NEXT_DAY' ? nextday : currentDay} ${item?.end_datetime}`
                  filedValues[`datetime.${index}`] = [start_time, end_time]
                }
                _formInfo.formInfo[0].form_list = list
                status === 0 &&
                  schedulePremmsion &&
                  x.edit_flag &&
                  setState({
                    formInfo: _formInfo,
                    type: 'Schedule',
                    visible: true,
                    currentItem: x,
                    initialValues: filedValues
                  })
              }}
            />
            <Cell
              title='班次打卡时间点'
              content={x?.clock_data?.join(';')}
              display={status === 0 && clockPremmsion ? '手动补卡' : ''}
              showIcon={showIcon && clockPremmsion}
              onClick={() => {
                status === 0 &&
                  clockPremmsion &&
                  setState({
                    visible: true,
                    type: 'Clock',
                    currentItem: x,
                    initialValues: {
                      time: x?.reissue_clock_time?.[0]?.split(' ')?.[1]
                    }
                  })
              }}
            />
            {x?.reissue_clock_list?.length > 0 &&
              x?.reissue_clock_list?.map((y: any, index: number) => (
                <Fragment key={index}>
                  <Cell
                    title='补卡时间'
                    content={y?.time}
                    className={{
                      classNameCell: 'cell-grey',
                      classNameCellContent: 'cell-content-grey',
                      classNameCellTitle: 'cell-title-grey'
                    }}
                    showIcon={false}
                  />
                  {y?.reason && (
                    <Cell
                      title='补卡原因'
                      content={y?.reason}
                      className={{
                        classNameCell: 'cell-grey',
                        classNameCellContent: 'cell-content-grey',
                        classNameCellTitle: 'cell-title-grey'
                      }}
                      showIcon={false}
                    />
                  )}
                </Fragment>
              ))}
            <Cell
              title='出勤工时'
              showIcon={showIcon && hourPremmsion}
              content={renderActualHour(x?.actual_hour, x?.adjust_flag)}
              onClick={() => {
                status === 0 &&
                  hourPremmsion &&
                  setState({ visible: true, type: 'Hour', currentItem: x })
              }}
            />
            {x?.adjust_reason && (
              <Cell
                title='调整工时原因'
                content={x?.adjust_reason}
                className={{
                  classNameCell: 'cell-grey',
                  classNameCellContent: 'cell-content-grey',
                  classNameCellTitle: 'cell-title-grey'
                }}
                showIcon={false}
              />
            )}
            <Cell
              title='其他统计'
              content={x?.other_statistics?.map((x: any) => x?.content)?.join(';')}
              display={
                attendance_result_type === 2 ? (
                  <span style={{ color: '#FAAD14' }}>处理异常</span>
                ) : (
                  <span style={{ color: '#0256FF' }}>发起申请</span>
                )
              }
              onClick={() => {
                currentItemRef.current = x
                setState({ open: true })
              }}
            />
          </div>
        ))}
        {status === 0 && permission.getCodeFun('HYB_MOVE_TEAM_DAILY_SCHEDULE_ADD') && (
          <div className='attendance-schedule-action'>
            <div className='attendance-schedule-action-button' onClick={jumpAddSchedule}>
              <img src='https://s.huiyunban.cn/1829122249538408448.png' />
              添加班次
            </div>
          </div>
        )}
      </div>
      <HActionSheet visible={open} actions={actions} onClose={() => setState({ open: false })} />
      {visible && (
        <SheetForm
          initialValues={initialValues}
          visible={visible}
          title={ScheduleModalTitleMap[type]}
          onCancel={() => setState({ visible: false })}
          onOk={(params) => {
            if (type === 'Clock') fetchClockRecord(params)
            if (type === 'Hour') fetchSechduleWork(params)
            if (type === 'Schedule') fetchScheduleRecord(params)
          }}
          json={type === 'Schedule' ? formInfo : json?.[type]}
        />
      )}
    </div>
  )
}

export default observer(Schedule)
