import { useSetState } from 'ahooks'
import { type FormProps, Button, Form, SafeArea, TextArea } from 'antd-mobile'
import classNames from 'classnames'
import { isEmpty, isEqual } from 'lodash-es'
import { useLocalStore } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import React, {
  Fragment,
  createContext,
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
  useEffect
} from 'react'

import useElement from '@/hooks/useElement'
import dictStore from '@/store/global/dictStore'
import screenShotStore from '@/store/global/screenShot'
import { isNullOrUndefined, commonNav, toPx } from '@/utils'

import { useEventManager } from '../EventManagerProvider'

import { ScreenShotEnum } from './constants'

import useFunctionCall from './hooks/useFunctionCall'
import RenderNode from './renderNode'
import { renderFormItem } from './utils'
import './index.less'

const dictStoreWithContext = createContext(dictStore)
interface EditFormprops extends Omit<FormProps, 'children'> {
  formInfo: any
  formInst?: any
  formButtons?: Array<Record<string, any>>
  formKey?: string
  initialValues?: any
  labelWidth?: number
  randomForm?: number
  formType?: string
  onFinish?: (values: any) => void
  onFinishFailed?: (values: any) => void
  disabled?: boolean
  readed?: boolean
}

const NewEditForm: React.FC<EditFormprops> = forwardRef((props, ref) => {
  const {
    formInfo,
    formInst,
    formButtons = [],
    formKey,
    initialValues,
    labelWidth,
    randomForm,
    onFinish,
    onFinishFailed,
    disabled,
    readed,
    formType = 'form',
    ...rest
  } = props
  const [random, setRandom] = useState(1)
  const [state, setState] = useSetState<any>(null)
  const screenShotRef = useRef<any>(null)
  const store = useContext(dictStoreWithContext)
  const { changeScreenShotRef } = useLocalStore(() => screenShotStore)
  const [form] = Form.useForm()
  const formInstance = formInst || form
  const { hideExec, onAfterChangeExec } = useFunctionCall({ form: formInstance })
  const setFieldsValue = async (values: any) => {
    const _values = await convertValue(values)
    console.log('_values:', _values)
    formInstance.setFieldsValue(_values)
  }
  useImperativeHandle(ref, () => ({ setFieldsValue }))
  const formSubmit = (values: any) => {
    console.log('transform:', convertValue, transform)
    const newValues = transform(values)
    onFinish?.(newValues)
  }
  const formSubmitFailed = (errors: any) => {
    if (onFinishFailed?.(errors)) {
      const newValues = transform(formInstance.getFieldsValue())
      onFinish?.(newValues)
    }
    const _errors = errors.errorFields
    // 不可见不校验
    formInfo?.map((info: any) => {
      info?.form_list?.map((item: any) => {
        const isHidden = item.hidden && hideExec(item?.hidden, state, {})
        const errorIndex = _errors.findIndex((e: any) =>
          isEqual(e.name, item.formItemProps?.name || item.name)
        )
        if (errorIndex > -1 && isHidden) {
          _errors.splice(errorIndex, 1)
        }
      })
    })
    const noError = _errors.length === 0
    const newValues = transform(formInstance.getFieldsValue())
    noError && onFinish?.(newValues)
  }
  const formButtonPreset: any = {
    goback: { className: 'default', title: '取消', onClick: () => commonNav.navigateBack() },
    goback2: {
      className: 'default',
      title: '取消',
      onClick: () => commonNav.navigateBack({ delta: 2 })
    },
    gohome: {
      className: 'default',
      title: '取消',
      onClick: () => window.close()
    },
    submit: {
      className: 'info',
      title: '提交',
      onClick: () => {
        formInstance.submit()
      }
    }
  }
  const getFormItemProps = (x: any) => {
    const formItemProps = {
      name: x.name,
      ...renderFormItem(x.type),
      ...x.formItemProps,
      className: classNames(x.formItemProps?.className?.join(','), {
        'class-e': x?.disabled || x.formItemProps?.disabled,
        hidden: hideExec(x?.hidden, state, {})
      }),
      ...(readed ? { required: false } : {})
    }
    const elementRequired = !isNullOrUndefined(x?.formItemProps?.required)
      ? x?.formItemProps?.required
      : x?.required
    const elementLabel = !isNullOrUndefined(x?.formItemProps?.label)
      ? x?.formItemProps?.label
      : x?.label
    if (
      !isEmpty(elementRequired) ||
      Object.prototype.toString.call(elementRequired).slice(8, -1) === 'Boolean'
    ) {
      const _required = !readed && hideExec(elementRequired, state, {})
      formItemProps.rules = [
        {
          required: _required,
          message: `${elementLabel}不能为空`
        }
      ]
      formItemProps.required = _required
    }
    return formItemProps
  }
  const getElementProps = (x: any) => {
    if (x.type === 'DatePicker') {
      console.log('x---DatePicker', x)
    }
    const elementProps = {
      readed,
      ...x?.elementProps,
      form: formInstance,
      globalProps: { state: {}, option: {}, onAfterChangeExec }
    }
    const elementDisabled = !isNullOrUndefined(x?.elementProps?.disabled)
      ? x?.elementProps?.disabled
      : x?.disabled
    if (
      disabled ||
      !isEmpty(elementDisabled) ||
      Object.prototype.toString.call(elementDisabled).slice(8, -1) === 'Boolean'
    ) {
      elementProps.disabled = disabled || hideExec(elementDisabled, state, {})
    }
    if (x?.elementProps?.dictionary || x?.dictionary) {
      const dictionary_list =
        store.getOptions(
          x?.elementProps?.dictionary || x?.dictionary,
          x?.elementProps?.disabledOptions || x?.disabledOptions
        ) || []
      elementProps.dataList = dictionary_list
      elementProps.options = dictionary_list
    }
    return elementProps
  }
  const { convertValue, transform } = useElement({
    formOption: { formInfo },
    getExtraDicts: store.requestExtraDicts.bind(store)
  })
  const _initialValues: any = useMemo(async () => {
    const newValues = await convertValue(initialValues)
    console.log('convertValue-newValues:', initialValues, newValues)
    return newValues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])
  // console.log('DictPositionModule:', toJS(store.dictMap.DictPositionModule))

  const styles: any = labelWidth
    ? {
        '--form-max-content-width': `${toPx(580 - labelWidth)}px`,
        '--form-label-width': `${toPx(labelWidth)}px`
      }
    : {}

  const renderFormInfoProps = (formInfoItemId: string) => {
    const _props: any = {}
    if (ScreenShotEnum.includes(formInfoItemId)) {
      _props['ref'] = screenShotRef
      return _props
    }
    return _props
  }
  useEffect(() => {
    screenShotRef.current && changeScreenShotRef(screenShotRef)
  }, [screenShotRef.current])
  // EditForm顶层注册setFormState用于向作用域useFunctionCall push数据源
  const eventManager = useEventManager()
  useEffect(() => {
    eventManager?.on('NewEditForm.setState', setState)
    eventManager?.on('NewEditForm.setFieldsValue', setFieldsValue)
    return () => {
      eventManager?.off('NewEditForm.setState', setState)
      eventManager?.off('NewEditForm.setFieldsValue', setFieldsValue)
    }
  }, [])
  // console.log('NewEditForm.setState:', toJS(state))
  const fragmentRander = () => {
    return (
      <div style={styles}>
        {formInfo.map((item: any) => {
          const {
            top = false,
            topProps = {},
            title = '',
            titleProps = {},
            form_list = [],
            is_show_title,
            is_show_delete,
            deleteButton,
            hide
          } = item
          return (
            !hideExec(hide, _initialValues, {}) && (
              <Fragment key={item.key || item.id}>
                {top && <div {...topProps} className='form-title-top' />}
                {!is_show_delete && !deleteButton && !title ? null : (
                  <div className='form-title-wrapper'>
                    {title && is_show_title !== false && (
                      <div {...titleProps} className='form-title'>
                        {title}
                      </div>
                    )}
                    {is_show_delete && deleteButton && (
                      <div
                        className='form-title-button'
                        onClick={() => deleteButton?.onClick?.(item.key || item.id)}
                      >
                        {deleteButton?.text}
                      </div>
                    )}
                  </div>
                )}
                <div className='new-edit-form-item' {...renderFormInfoProps(item?.id)}>
                  {form_list.map((x: any, index: any) => (
                    <Fragment key={index}>
                      {!hideExec(x?.hide, state, {}) ? (
                        <Form.Item key={index} {...getFormItemProps(x)}>
                          <RenderNode type={x.type} elementProps={getElementProps(x)} />
                        </Form.Item>
                      ) : null}
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            )
          )
        })}
        {randomForm === 1 || random === 1}
        {Object.keys(store.dictMap).find(() => false)}
      </div>
    )
  }
  return (
    <div className='new-edit'>
      {formType === 'item' ? (
        fragmentRander()
      ) : (
        <Form
          key={formKey}
          form={formInstance}
          onFinish={formSubmit}
          onFinishFailed={formSubmitFailed}
          initialValues={_initialValues}
          className={classNames('new-edit-form', { disabled })}
          onValuesChange={(e) => {
            console.log('onValuesChange', e)
            setRandom(Math.random())
          }}
          layout='horizontal'
          {...rest}
        >
          {fragmentRander()}
          {formButtons?.length > 0 && (
            <>
              <div
                className={classNames('new-edit-form-btns', { justone: formButtons?.length === 1 })}
              >
                {formButtons?.map((x) => (
                  <Button
                    {...x}
                    className={classNames(
                      'btn',
                      x.className || formButtonPreset[x.key]?.className || 'add-btn'
                    )}
                    key={x.key}
                    onClick={() => {
                      x?.onClick ? x.onClick() : formButtonPreset[x.key]?.onClick?.()
                    }}
                  >
                    {x.title || formButtonPreset[x.key]?.title}
                  </Button>
                ))}
              </div>
              <div className='new-edit-form-blank' />
              <SafeArea position='bottom' />
            </>
          )}
          {randomForm === 1 || random === 1}
          {Object.entries(store.dictMap) === undefined}
        </Form>
      )}
    </div>
  )
})

export default observer(NewEditForm)
