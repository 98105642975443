import React, { FC } from 'react'

import mapping from './mapping'

// 递归渲染节点
const RenderNode: FC<any> = ({ type, elementProps, children, ...rest }) => {
  const Component = mapping[type || 'Block']

  console.log('Component:', type, rest)
  if (Component) {
    if (children && children.length > 0) {
      return (
        <Component {...elementProps} {...rest}>
          {children?.map((child: any, index: number) => <RenderNode key={index} {...child} />)}
        </Component>
      )
    }
    return <Component {...elementProps} {...rest} />
  }
  return <></>
}

export default RenderNode
