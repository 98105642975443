import { useDebounceFn, useSetState } from 'ahooks'
import { TextArea } from 'antd-mobile'
import React, { useEffect } from 'react'

import HCell from '../HCell'

import { publicEvents, publicProps } from '../utils'
import './index.less'
const HTextArea: React.FC<any> = (elementProps: any) => {
  const [{ value }, setState] = useSetState<{ value?: string }>({ value: elementProps.value || '' })
  const valueRef = React.useRef<string | undefined>(value)
  valueRef.current = value

  const toInput = () => {
    publicEvents.onChange.call(elementProps, valueRef.current, 'Textarea')
  }
  const { run: runInput } = useDebounceFn(toInput, { wait: 50, trailing: true })
  const onInput = (val: any) => {
    setState({ value: val })
    valueRef.current = val
    elementProps?.onChange?.(val)
    runInput()
  }

  useEffect(() => {
    if (elementProps.value !== valueRef.current) {
      setState({ value: elementProps.value || '' })
    }
  }, [elementProps.value, setState])

  useEffect(() => {
    return () => {
      setState({ value: '' })
    }
  }, [setState])

  return (
    <div className='hyb-textarea'>
      {elementProps?.readed ? (
        <HCell value={elementProps?.value} readed={elementProps?.readed} />
      ) : (
        <TextArea
          id='ant-textarea'
          {...publicProps(elementProps, 'textarea')}
          value={value}
          onChange={onInput}
        />
      )}
    </div>
  )
}

export default HTextArea
