import { Toast } from 'antd-mobile'
import type { ToastHandler } from 'antd-mobile/es/components/toast'

type Timer = ReturnType<typeof setTimeout>
type CustomTimer = Timer | null

// loading 最小保持时间，避免一闪而过的 loading
const LOADING_MIN_KEEP_TIME = 200

// loading 最大保持时间 10s，避免接口报错太长 loading
const LOADING_MAX_KEEP_TIME = 10 * 1000

// loading 延迟执行时间，200毫秒内返回不展示 loading
const LOADING_DELAY_TIME = 200

type LoadingCtrl = () => {
  addLoading: (url: string) => void
  removeLoading: (url: string) => void
}

const loadingCtrl: LoadingCtrl = () => {
  const urlMap = new Map()
  let loadingTimer: CustomTimer = null
  // loading 开始计时器，若
  let loadingStartTimer: CustomTimer = null
  let loadingFlag = false
  let loadingStart: number | null = null
  let loadingEnd: number | null = null
  let toastHander: ToastHandler | null = null

  // 隐藏 loading
  const hideLoading = () => {
    toastHander && toastHander.close()
  }

  const resetLoading = () => {
    hideLoading()
    loadingFlag = false
    loadingTimer = null
    loadingStart = null
    loadingStartTimer = null
  }

  const addLoading = (url: string) => {
    if (!url) return

    // 是否有正在loading或者有200ms计时loading
    if (!loadingFlag && !loadingStartTimer) {
      loadingStartTimer = setTimeout(() => {
        clearTimeout(loadingStartTimer as Timer)

        // 若200ms后还有请求，展示loading
        if (urlMap.size > 0) {
          loadingFlag = true
          loadingStart = Date.now()
          toastHander = Toast.show({
            icon: 'loading',
            maskClickable: false
          })
        }
      }, LOADING_DELAY_TIME)
    }

    urlMap.set(url, true)
    // console.log(1111111, urlMap)

    if (!loadingTimer) {
      loadingTimer = setTimeout(() => {
        clearTimeout(loadingTimer as Timer)
        urlMap.clear()
        Toast.clear()
        resetLoading()
      }, LOADING_MAX_KEEP_TIME)
    }
  }

  const removeLoading = (url: string) => {
    if (!url) return

    urlMap.delete(url)
    // console.log(1111111222222, urlMap)

    // 还有未结束的请求
    if (urlMap.size) {
      return
    }

    // 若 loading 时间小于 200ms 则最少 200ms
    loadingEnd = Date.now()
    if (loadingStart && loadingEnd - loadingStart < LOADING_MIN_KEEP_TIME) {
      setTimeout(() => {
        resetLoading()
      }, LOADING_MIN_KEEP_TIME)
    } else {
      resetLoading()
    }
  }

  return {
    addLoading,
    removeLoading
  }
}

export default loadingCtrl
