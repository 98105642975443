import DatePicker from './DatePicker'
import Expand from './Expand'
import OrgFilter from './OrgFilter'
import Search from './Search'
/**
 * 组件映射 - Filter定制
 */
const mapping: { [key: string]: any } = {
  OrgFilter,
  DatePicker,
  Search,
  Expand
}
export default mapping
