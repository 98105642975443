import { useDebounceFn, useSetState } from 'ahooks'
import { SearchBar } from 'antd-mobile'
import React, { forwardRef, useEffect } from 'react'

import SvgIcon from '@/components/SvgIcon'

import { publicEvents, publicProps } from '../utils'
import './index.less'

const HSearchBar = forwardRef((elementProps: any, ref: any) => {
  const [{ value }, setState] = useSetState<{ value?: string }>({ value: elementProps.value || '' })
  const valueRef = React.useRef<string | undefined>(value)
  valueRef.current = value

  const toInput = () => {
    publicEvents.onChange.call(elementProps, valueRef.current, 'Textarea')
  }
  const { run: runInput } = useDebounceFn(toInput, { wait: 100, trailing: true })
  const onInput = (val: any) => {
    setState({ value: val })
    valueRef.current = val
    runInput()
  }

  // 在input等类型组件的onBlur回调函数内执行
  const onBlur = () => {
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0

      window.scrollTo({ top: scrollTop, left: 0, behavior: 'smooth' })
    }, 100)
  }

  useEffect(() => {
    if (elementProps.value !== valueRef.current) {
      setState({ value: elementProps.value || '' })
    }
  }, [elementProps.value])

  useEffect(() => {
    return () => {
      setState({ value: '' })
    }
  }, [setState])

  return (
    <SearchBar
      ref={ref}
      searchIcon={<SvgIcon className='search-icon' name='search' />}
      {...publicProps(elementProps, 'search-bar')}
      placeholder={`${elementProps.placeholder || '请输入内容'}`}
      value={value}
      onChange={onInput}
      onBlur={onBlur}
    />
  )
})

export default HSearchBar
