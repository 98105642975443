import { isNotEmptyArray } from '@/utils'
import './index.less'

const CardItemTitle = ({
  dataSource,
  columns = [{ label: '成本归属', value: 'cost_org_name' }]
}: { dataSource?: any; columns?: any } = {}) => {
  const {
    employee_name = '',
    job_type_name = '',
    post_name = '',
    position_name = ''
  } = dataSource || {}
  const hasColumns = isNotEmptyArray(columns)

  return (
    <div className='hyb-card-item-custom-title'>
      <div className='name-wrap'>
        <span className='name van-ellipsis-1'>{employee_name}</span>
        <span className='info van-ellipsis-1'>
          {[post_name, position_name, job_type_name].filter((key) => !!key).join(' / ')}
        </span>
      </div>
      {hasColumns && (
        <div className='second-line'>
          {columns.map(({ label, value }: any, index: number) => {
            return (
              <span key={index} className={`info info-${index}`}>
                <span>{label}</span>：{/* 仅有一个不限制内容 */}
                <span className={`${columns.length > 1 ? 'info-value van-ellipsis-1' : ''}`}>
                  {dataSource[value] || ''}
                </span>
              </span>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CardItemTitle
