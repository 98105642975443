import OrganizeSelect from '../OrganizeSelect'
// 分组选择器
function GroupSelect(props) {
  return <OrganizeSelect {...props} isGroup />
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
GroupSelect.convertValue = (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
    if (values?.[config?.names[0]] && values?.[config?.names[1]]) {
      return {
        [config?.names[0]]: {
          node_code: values[config?.names[0]],
          name: values[config?.names[1]]
        }
      }
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
GroupSelect.transform = (value: any, config?: any) => {
  if (value && Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config?.names[0]]: value?.node_code,
      [config?.names[1]]: value?.name
    }
  }
  return value?.node_code
}

export default GroupSelect
