import { useSetState } from 'ahooks'
import { Image } from 'antd-mobile'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import HInputDebounce from '../../HYBVant/HInputDebounce'

import { getUploadSign } from './service'

import './index.less'

function InputWithImage(props: any) {
  const { limit = 100, onChange, disabled, tips } = props
  const [{ imgUrl, objectKey }] = useSetState<any>({
    imgUrl: [],
    objectKey: []
  })

  const downloadSigns = async (params: any) => {
    console.log('onChange:', params)
    onChange?.([...objectKey, params[0].object_key])
  }

  const chooseImg = () => {
    if (imgUrl.length === limit) {
      console.log('数量受限')
      return
    }
    // 对于H5环境，使用<input type="file">
    const inputEl = document.createElement('input')
    inputEl.type = 'file'
    inputEl.accept = 'image/*'
    inputEl.multiple = false // 限制只能选择一张图片
    inputEl.click()

    inputEl.onchange = async (event: any) => {
      const files = event.target.files
      if (files.length > 0) {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = async (e: any) => {
          const tempFilePath = e.target.result
          const { data } = await getUploadSign({
            file_suffix: file.name.split('.').pop() || 'jpeg',
            file_name: file.name,
            content_type: 'image/*',
            u: dayjs().valueOf(),
            vs: CryptoJS.MD5(`HYB:${dayjs().valueOf()}`).toString()
          })

          // Taro.uploadFile({
          //   url: data.host,
          //   filePath: tempFilePath,
          //   name: 'file',
          //   formData: {
          //     object_key: data.object_key,
          //     policy: data.policy,
          //     OSSAccessKeyId: data.access_id,
          //     signature: data.signature,
          //     key: data.object_key,
          //     Signature: data.signature,
          //     success_action_status: '200',
          //     'x-oss-meta-file-name': file.name,
          //     'x-oss-content-type': 'image/*',
          //     ...data
          //   },
          //   success: function () {
          //     downloadSigns([{ object_key: data.object_key }])
          //   },
          //   fail: function (error) {
          //     console.log('上传失败', error)
          //   }
          // })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  useEffect(() => {
    // Taro.authorize({
    //   scope: 'scope.writePhotosAlbum',
    //   success() {
    //     console.log('授权成功')
    //   },
    //   fail(error) {
    //     console.error('授权失败', error)
    //   }
    // })
  }, [])

  return (
    <div className='input-with-image'>
      <div className='input-with-image-input'>
        <HInputDebounce placeholder='请输入' {...props} />
      </div>
      <div className='input-with-image-choose' onClick={() => !disabled && chooseImg()}>
        <Image
          className='input-with-image-display-img'
          src='https://s.huiyunban.cn/1792792144277147648.png'
        ></Image>
        <div className='input-with-image-choose-text'>{tips || 'ORC识别'}</div>
      </div>
    </div>
  )
}

export default InputWithImage
