export default class EventManager {
  private events: { [key: string]: Function[] } = {}

  // 注册事件处理器
  on(eventName: string, callback: Function): void {
    if (!this.events[eventName]) {
      this.events[eventName] = []
    }
    this.events[eventName].push(callback)
  }

  // 触发事件
  emit(eventName: string, ...args: any[]): void {
    const eventHandlers = this.events[eventName]
    if (eventHandlers) {
      eventHandlers.forEach((handler) => handler(...args))
    }
  }

  // 注销事件处理器
  off(eventName: string, callback: Function): void {
    const eventHandlers = this.events[eventName]
    if (eventHandlers) {
      this.events[eventName] = eventHandlers.filter((cb) => cb !== callback)
    }
  }

  // 清空所有事件处理器
  clearAll(): void {
    this.events = {}
  }
}
