import { Grid, Popup, SafeArea } from 'antd-mobile'
import classNames from 'classnames'
import React from 'react'

import './index.less'
import { isEmpty } from 'lodash-es'

const HPopup: React.FC<any> = ({
  children,
  className,
  title,
  hasCancel = true,
  hasOk = true,
  cancelText = '取消',
  okText = '确认',
  ...props
}: any) => {
  return (
    <Popup className={classNames('h-popup', className)} position='bottom' round {...props}>
      {props.position === 'top' && children}
      <div className='h-popup-header'>
        <div className='h-popup-header-lt'>
          {hasCancel && (
            <div className='h-popup-header-link' onClick={() => props.onCancel?.()}>
              {cancelText}
            </div>
          )}
        </div>
        <div className='h-popup-header-md'>
          <div className='h-popup-header-title'>{title || '请选择'}</div>
        </div>
        <div className='h-popup-header-gt'>
          {hasOk && (
            <div className='h-popup-header-link primary' onClick={() => props.onOk?.()}>
              {okText}
            </div>
          )}
        </div>
      </div>
      {isEmpty(props.columnsHeader) ? null : (
        <Grid columns={props.columnsHeader.length} style={{ padding: 10 }}>
          {props.columnsHeader?.map?.((item: any) => (
            <Grid.Item key={item} className=''>
              <center>{item}</center>
            </Grid.Item>
          ))}
        </Grid>
      )}
      {props.position !== 'top' && children}
      <SafeArea position='bottom' />
    </Popup>
  )
}

export default HPopup
