import { useSetState } from 'ahooks'
import { useLocalObservable } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import usePcTransform from '@/components/NewEditForm/hooks/usePcTransform'
import useVariable from '@/hooks/useVariable'
import { unifiedStoreNoContext } from '@/store/unified/unifiedStore'
import http from '@/utils/http'

import { IPageProps } from '../interface'
import { merge } from 'lodash-es'

export enum StatusCode {
  pending = 'PENDING',
  done = 'DONE',
  error = 'ERROR'
}
// 获取通用表单
export const getCommonForm = (data: any, url?: any): Promise<any> =>
  http.get(`/common/form_json${url}`, { data })
/**
 * PageProps
 */
export interface PageProps {
  code: string
  vars?: any
  immediate?: boolean
  isPreview?: boolean
  runLocalOption?: () => Promise<any>
}
export interface PageOption {
  option: IPageProps
  className?: string
  optionStatus?: StatusCode
  originOption?: IPageProps
  initOption?: (config: any) => void
  refreshOption?: (config: any) => void
  setFormOptionItem?: (config: any) => void
  getFormOptionItem?: (config: any) => any
  setOption: (config: any) => any
}
/**
 * 动态获取通用表单
 * @param code 表单code
 * @param qr_code_id 免登录扫码id
 */
export default ({ code, vars, immediate, runLocalOption, isPreview }: PageProps) => {
  const _stateStore = useLocalObservable(() => unifiedStoreNoContext)
  const [state, setState] = useSetState<{
    option: any
    originOption: any
    optionStatus: StatusCode
    variables?: any
  }>({
    option: undefined,
    originOption: undefined,
    optionStatus: StatusCode.pending
  })
  const { transform } = usePcTransform()
  const stateRef = useRef<any>(state)
  stateRef.current = state
  const setFormOptionItem = (formInfo: any, items: { [key: string]: any }, extraName?: string) => {
    if (extraName) {
      // TODO
    } else {
      const info = formInfo
      Object.entries(items).forEach(([key, value]) => {
        const columns = key.split('.')
        if (columns?.length === 2) {
          const blockIndex = info.findIndex(
            (item: any) => item.id === columns[0] || item.key === columns[0]
          )
          const itemIndex = info?.[blockIndex]?.form_list?.findIndex(
            (item: any) => item.name === columns[1] || item.formItemProps?.name === columns[1]
          )
          if (blockIndex > -1 && itemIndex > -1) {
            info[blockIndex].form_list[itemIndex] = {
              ...info[blockIndex].form_list[itemIndex],
              ...value
            }
          } else {
            throw new Error(`设置表单项找不到 ${key}`)
          }
        }
      })
      return info
    }
  }
  const getFormOptionItem = (formInfo: any, key: string, extraName?: string) => {
    if (extraName) {
      // TODO
    } else {
      const info = formInfo
      const columns = key.split('.')
      if (columns?.length === 2) {
        const blockIndex = info.findIndex(
          (item: any) => item.id === columns[0] || item.key === columns[0]
        )
        const itemIndex = info?.[blockIndex]?.form_list?.findIndex(
          (item: any) => item.name === columns[1] || item.formItemProps?.name === columns[1]
        )
        if (blockIndex > -1 && itemIndex > -1) {
          return info[blockIndex].form_list[itemIndex]
        } else {
          throw new Error(`设置表单项找不到 ${key}`)
        }
      }
      return {}
    }
  }
  const injectVariable = useVariable({
    vars,
    isPreview
  })
  const initOptionError = () => {
    setState({ optionStatus: StatusCode.error })
  }
  const initOption = (config: { vars?: any; code?: string }) => {
    const { vars: _vars, code: _code } = config
    const _variables = { ...vars, ..._vars }
    setState({ variables: _variables, optionStatus: StatusCode.pending })
    // eslint-disable-next-line no-unsafe-optional-chaining
    return (runLocalOption ? runLocalOption?.() : getCommonForm({ form_type: _code || code }))
      .then(({ success, data }: any) => {
        const dataSource = data
        if (success && data && dataSource) {
          const __option = injectVariable(dataSource, _variables)
          const _option = transform(__option)
          setState({
            option: _option,
            originOption: transform(dataSource),
            optionStatus: StatusCode.done
          })
        } else if (success && data && !dataSource) {
          const __option = injectVariable(dataSource, _variables)
          const _option = transform(__option)
          setState({
            option: _option,
            originOption: transform(dataSource),
            optionStatus: StatusCode.error
          })
        } else {
          initOptionError()
        }
      })
      .catch((error: any) => {
        console.error(error)
        initOptionError()
      })
  }
  const refreshOption = (newVars: any) => {
    // console.log('newVars', newVars, stateRef.current.originOption)
    const _variables = {
      ...stateRef.current.variables,
      ...newVars
    }
    const _option = injectVariable(stateRef.current.originOption, _variables)
    setState({ option: _option, variables: _variables })
  }
  const setOption = (_: any) => setState({ option: merge(stateRef.current.option, _) })
  useEffect(() => {
    if (immediate && code) {
      _stateStore.clear()
      initOption({ code })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const pageOption: PageOption = {
    option: state.option,
    setOption,
    originOption: state.originOption,
    optionStatus: state.optionStatus,
    initOption,
    refreshOption
  }
  return pageOption
}
