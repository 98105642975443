import { useRequest } from 'ahooks'
import { Toast } from 'antd-mobile'
import { toJS } from 'mobx'
import { useLocalStore, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'

import SheetForm from '@/components/SheetForm'
import useFetchCommonJson from '@/hooks/useFetchCommonJson'
import useVariable from '@/hooks/useVariable'
import flowStore from '@/pages/Flow/Store'

import { workflowInstApi, workflowApplyProject } from '../../services'
import { FlowOperaType } from '../../types'

import { json } from './enum'

import './index.less'

interface ApprovalFormProps {
  handleConfrim: (data: any, isRefreshCurrent?: boolean) => void
  agreeForm?: any
}

const ApprovalForm: React.FC<ApprovalFormProps> = ({ handleConfrim }) => {
  const {
    approvalForm,
    approvalForm: { text, flowOperaType = FlowOperaType.Countersign, item = {} },
    approvalFormShow,
    changeApprovalFormShow,
    flowDetail
  } = useLocalStore(() => flowStore)
  const { runCommonFormJson } = useFetchCommonJson()

  const {
    workflow_inst_id,
    apply_project_id,
    apply_category_code,
    date_mode,
    application_date_start,
    application_date_end,
    daily_start_time,
    daily_end_time,
    initiate_mode
  } = item || {}
  const [node, setNode] = useState([])
  const [isClick, setIsClick] = useState(true)
  const [applyItem, setApplyItem] = useState('')
  const [initialValues, setInitialValues] = useState<Record<string, any>>({})
  const [agreeForm, setAgreeForm] = useState<any>({})

  const fetchWorkflowInst = async () => {
    const { data } = await workflowInstApi(workflow_inst_id)
    if (flowOperaType === FlowOperaType.Countersign) {
      setNode(
        data?.map((x: any) => {
          if (!x.allow_counter_sign) return { ...x, disabled: true }
          return x
        })
      )
    }
    if (flowOperaType === FlowOperaType.Back) {
      const _data = data?.map((x: any) => {
        if (!x.allow_revert) return { ...x, disabled: true }
        return x
      })
      setNode(_data)
      const form_ = _data?.filter((x: any) => x.node_type === 'approver') || []
      const backNode: any =
        form_?.[form_?.findIndex((item: any) => item.approval_status === 'UNDER_APPROVAL') - 1] ||
        {}
      setIsClick(!!backNode?.id)
      setInitialValues({ revert_to_inst_node_id: backNode?.id })
    }
    if (flowOperaType === FlowOperaType.Transfer) {
      setNode(
        data?.map((x: any) => {
          if (!x.allow_transfer) return { ...x, disabled: true }
          return x
        })
      )
    }
  }

  useEffect(() => {
    if (flowOperaType !== FlowOperaType.Countersign && flowOperaType !== FlowOperaType.Back) return
    workflow_inst_id && workflow_inst_id !== '0' && fetchWorkflowInst()
  }, [workflow_inst_id, flowOperaType])

  useEffect(() => {
    if (flowOperaType === FlowOperaType.Agree && apply_project_id) {
      runWorkflowApplyProjectNew({ copyFlag: 0 }, apply_project_id)
    }
  }, [flowOperaType, apply_project_id])

  const _apply_project_code =
    Object.keys(flowDetail).length > 0 ? flowDetail?.apply_project_code : item?.apply_type_code

  const injectVariable = useVariable({
    vars: {
      projectCode: _apply_project_code !== 'SYSTEM_LOAN'
    }
  })

  useEffect(() => {
    const getAgreeForm = async () => {
      const { data, code } = await runCommonFormJson('h5_oa_agree_form')
      if (code === 2000) setAgreeForm(injectVariable(data))
    }
    getAgreeForm()
  }, [])

  useEffect(() => {
    const { supported_employee_info } = flowDetail
    setInitialValues({
      employee_ids: supported_employee_info?.map((x: any) => ({
        id: x?.key,
        name: x?.title
      }))
    })
  }, [flowDetail, agreeForm])

  useEffect(() => {
    const half: boolean =
      apply_category_code === 'ASSIST' &&
      initiate_mode === 'SINGLE_CYCLE_MODE' &&
      date_mode === 'HALF_DAY_MODE'
    if (apply_category_code === 'ASSIST') {
      setInitialValues({
        application_date_start: half
          ? [application_date_start, daily_start_time]
          : application_date_start,
        application_date_end: half ? [application_date_end, daily_end_time] : application_date_end,
        daily: daily_start_time || daily_end_time,
        daily_start_time:
          date_mode === 'TIME_PERIOD_MODE'
            ? daily_start_time?.split(' ')?.[1] || daily_start_time?.split(' ')?.[0]
            : daily_start_time,
        daily_end_time:
          date_mode === 'TIME_PERIOD_MODE'
            ? daily_end_time?.split(' ')?.[1] || daily_end_time?.split(' ')?.[0]
            : daily_start_time
      })
    }
  }, [item])

  const { run: runWorkflowApplyProjectNew } = useRequest(workflowApplyProject, {
    manual: true,
    onSuccess: (result: any) => {
      const { data } = result
      const personal_common_form_code = data?.personal_common_form_code || ''
      setApplyItem(personal_common_form_code)
    },
    onError: ({ message }) => {
      console.log('error', message)
      setApplyItem('')
    }
  })
  // const save = () => {
  //   formIt.validateFields(async (errorMessage, fieldValues) => {
  //     if (errorMessage && errorMessage.length) {
  //       return console.info('errorMessage', errorMessage)
  //     }
  //     // console.log(fieldValues, 'fieldValues', { fieldValues, approvalForm })
  //     handleConfrim({ fieldValues, approvalForm }, flowOperaType === 12)
  //     changeApprovalFormShow(false)
  //   })
  // }
  const onFinish = (fieldValues: any) => {
    const _fieldValues = {
      ...fieldValues,
      attachments: fieldValues?.attachments?.map((item: any) => item?.object_key || item)
    }
    handleConfrim({ fieldValues: _fieldValues, approvalForm }, flowOperaType === 12)
    changeApprovalFormShow(false)
  }

  // handover_person &&
  // changeEmployee({
  //   handover_person:
  //     handover_person?.map((x: number, index: number) => ({
  //       id: x,
  //       name: handover_person_name?.[index]
  //     })) || []
  // })

  console.log(
    toJS(approvalForm),
    'approvalForm',
    agreeForm,
    approvalFormShow,
    item,
    toJS(flowDetail)
  )

  const renderJson = useMemo(() => {
    if (flowOperaType === FlowOperaType.Agree && Array.isArray(agreeForm?.formInfo)) {
      return agreeForm
    }
    return json({
      text,
      node,
      flowOperaType,
      applyItem,
      date_mode,
      apply_category_code,
      initiate_mode
    })
  }, [
    text,
    node,
    flowOperaType,
    applyItem,
    date_mode,
    apply_category_code,
    agreeForm,
    initiate_mode
  ])

  return (
    <div className='approval-form'>
      <SheetForm
        initialValues={initialValues}
        visible={approvalFormShow}
        title={`确认${text}`}
        onCancel={() => changeApprovalFormShow(false)}
        onOk={(params) => {
          if (isClick) return onFinish(params)
          Toast.show({
            content: '无回退节点'
          })
        }}
        json={renderJson}
      />
    </div>
  )
}

export default observer(ApprovalForm)
