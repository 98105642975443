const useTimeColumn = () => {
  function generateTimeArray(
    start: number,
    end: number,
    step: number
  ): { label: string; value: string }[] {
    const compare =
      step > 0
        ? (current: number, limit: number) => current <= limit
        : (current: number, limit: number) => current >= limit

    const resultArray: { label: string; value: string }[] = []
    let i = start
    while (compare(i, end)) {
      const formattedValue = i < 10 ? `0${i}` : `${i}`
      resultArray.push({ label: formattedValue, value: formattedValue })
      i += step
    }
    return resultArray
  }

  const renderTimeColumn = (props?: any) => {
    const {
      minHour = 0,
      maxHour = 23,
      minMinute = 0,
      maxMinute = 59,
      minSecond = 0,
      maxSecond = 59,
      hourStep = 1,
      minuteStep = 1,
      secondStep = 1
    } = props

    const hours = generateTimeArray(minHour, maxHour, hourStep)
    const minutes = generateTimeArray(minMinute, maxMinute, minuteStep)
    const second = generateTimeArray(minSecond, maxSecond, secondStep)

    const basicColumns = props?.format === 'HH:mm' ? [hours, minutes] : [hours, minutes, second]
    return { basicColumns }
  }

  return { renderTimeColumn }
}

export default useTimeColumn
