import * as dd from 'dingtalk-jsapi'

import { AuthType, DDAuthCode, DDSetting } from './dingtalk.const'
import { JSSDK } from './interface'
import { getDingTicket } from './service'
import { storage, service } from './utils'
/**
 * 钉钉api二次封装
 * 来源：https://open.dingtalk.com/document/orgapp/jsapi-overview
 */
class Dingtalk implements JSSDK {
  /**
   * 静默登录
   * @param data  { corp_id: corpId, code }
   * @param config { baseURL: string } | undefined
   * @returns Promise<IResponseType>
   */
  silenceLogin(data: { corp_id: string; code: any }, config: { baseURL: string } | undefined) {
    return service({
      method: 'post',
      url: '/app/ding/inner/login',
      data,
      config
    })
  }
  /**
   * 手机系统权限
   * 文档：https://open.dingtalk.com/document/orgapp/jsapi-check-auth
   * @param authType 权限类型
   *  CAMERA	相机
      PHOTO	相册
      LBS	地理位置
      BLUETOOTH	蓝牙
      MICROPHONE	麦克风
      ADDRESSBOOK	通讯录
      NOTIFICATION	通知栏权限
      SHORTCUT	创建桌面快捷方式（仅Android）
   */
  checkAuth(authType: string) {
    return dd
      .checkAuth({
        authType: AuthType[authType]
      })
      .then((res: any) => res.granted)
  }
  /**
   * 引导打开系统设置钉钉权限
   * @param authType 同checkAuth
   * @returns Promise
   */
  authorize(authType: string) {
    return Promise.reject(new Error('Not Support'))
  }
  /**
   * 引导打开系统设置钉钉权限
   * @param authType 同checkAuth
   * @returns Promise
   */
  openSystemSetting(param: any) {
    return dd.device.base
      .openSystemSetting(DDSetting[param])
      .then((res: any) => {
        console.log('res:', res)
      })
      .catch((err: any) => console.log('err:', err))
  }
  /**
   * 初始化鉴权接口
   * @param param0 { api: string | string[]; corpId?: string }
   * @returns Promise
   */
  config({ api = '', corpId }: { api: string | string[]; corpId?: string }) {
    const corp_id = corpId || storage.get('corp_id')
    console.log('config>>>>>>>>>>', corp_id)
    return new Promise((resolve, reject) => {
      getDingTicket({ corp_id })
        .then((res: any) => {
          console.log('getDingTicket:', res)
          if (res.success) {
            const apiGroup =
              Array.isArray(api) &&
              api.reduce((prev: any, t: string) => {
                return prev.concat(DDAuthCode[t])
              }, [])
            const configParam = {
              agentId: res.data.agent_id, // 必填，微应用ID
              corpId: res.data.corp_id, // 必填，企业ID
              timeStamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonce_str, // 必填，自定义固定字符串。
              signature: res.data.signature, // 必填，签名
              type: 0, // 选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
              jsApiList: Array.isArray(api) ? apiGroup : DDAuthCode[api] || []
            }
            console.warn('getDingTicket.param:', configParam)
            dd.config(configParam)
            dd.error((err: any) => {
              // console.error('dd.config error:', JSON.stringify(err))
              reject(err)
            })
            dd.ready(() => {
              resolve(1)
            })
          }
        })
        .catch((e: any) => {
          console.log('请求出错:', e)
          reject(e)
        })
    })
  }
  /**
   * 在鉴权之前调用了接口
   * @param fn 需鉴权接口
   * @returns Promise
   */
  // beforeConfig<T>(fn: () => Promise<T>) {
  //   return new Promise((resolve, reject) => {
  //     fn()
  //       .then((res: T) => resolve(res))
  //       .catch((err: any) => {
  //         if (err?.errorMessage?.toLowerCase()?.startsWith('permission')) {
  //           this.config({ api: 'location' }).then((r: any) => resolve(fn()))
  //         }
  //         reject(err)
  //       })
  //   })
  // }
  getAuthCode({ corpId }: { corpId: string }) {
    return dd.runtime.permission.requestAuthCode({
      corpId
    })
  }
  getLocation() {
    return dd.device.geolocation.get({
      targetAccuracy: 50,
      coordinate: 0,
      withReGeocode: false,
      useCache: false
    })
  }
  getHighLocation() {
    return dd.device.geolocation.get({
      targetAccuracy: 10,
      coordinate: 0,
      withReGeocode: false,
      useCache: false
    })
  }
  startLocation() {
    return dd.device.geolocation.start({
      targetAccuracy: 50,
      iOSDistanceFilter: 10,
      callBackInterval: 1000,
      withReGeocode: false,
      useCache: false,
      sceneId: ''
    })
  }
  stopLocation() {
    return dd.device.geolocation.stop({ sceneId: '' })
  }
  scanCode() {
    return dd.biz.util.scan({}).then((res: any) => res?.text)
  }
  takePhoto(param?: any) {
    const stickers = param?.stickers
      ? {
          time: `${param?.stickers.time} ${param?.stickers.username}`,
          dateWeather: param?.stickers.dateWeather
        }
      : param?.stickers
    return dd.biz.util
      .uploadImageFromCamera({ compression: true, ...param, stickers })
      .then((res: any) => res?.[0])
  }
  setTitle(params: any) {
    return dd.biz.navigation.setTitle(params)
  }
  getImageInfo(src?: string) {
    return Promise.reject(new Error('dd.getImageInfo Not Support'))
  }
}
export default new Dingtalk()
