import { IOrganize } from '../types'

export function findP(id: string, list: IOrganize[] = []) {
  const result: any[] = []

  const loop = (targId: string, _list: IOrganize[] = []) => {
    _list.forEach((item) => {
      if (item.id === targId) {
        result.push(item?.id)
        return true
      } else if (item.children) {
        if (loop(targId, item.children)) {
          result.push(item?.id)
          return true
        }
      }
    })
    return false
  }
  loop(id, list)
  return result
}

export function findList(id: string, list: IOrganize[] = []) {
  const result: any[] = [list]

  const loop = (targId: string, _list: IOrganize[] = []) => {
    _list.forEach((item) => {
      if (item.id === targId) {
        return true
      } else if (item.children) {
        if (loop(targId, item.children)) {
          result.push(item.children)
          return true
        }
      }
    })
    return false
  }
  loop(id, list)
  return result.reverse()
}

export function findCurrentList(id: string, list: IOrganize[] = []) {
  let result: IOrganize[] = []

  const loop = (targId: string, _list: IOrganize[] = []) => {
    _list.forEach((item) => {
      if (item.id === targId) {
        result = _list
        return true
      } else if (item.children) {
        if (loop(targId, item.children)) {
          return true
        }
      }
    })
    return false
  }
  loop(id, list)
  return result
}

export function findItem(id: number | string, list: IOrganize[]) {
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  let result: IOrganize = {}
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      console.log('dang dang dang', list[i].id)
      result = list[i]
    } else if (list[i]?.children?.length) {
      const _result = findItem(id, list[i].children || [])
      if (_result?.id) {
        result = _result
      }
    }
    if (result.id) {
      break
    }
  }
  return result
}
