import { Toast } from 'antd-mobile'
import React from 'react'

import uploadImage from '@/utils/bridge/h5origin/uploadImage'

import logger from '@/utils/logger'

import { getUploadSign, batchDownloadSigns } from './service'

const UploadFile: React.FC = (props: any) => {
  const { disabled = false, element, hasToken = true, value, onConfirm } = props

  const openFile = () => {
    if (disabled) return
    const inputEl = document.createElement('input')
    inputEl.type = 'file'
    inputEl.accept = 'image/*'
    inputEl.multiple = false // 限制只能选择一张图片
    inputEl.style.display = 'none'
    inputEl.addEventListener('change', (event: any) => {
      const files = event.target.files
      if (files.length > 0) {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = async (e: any) => {
          uploadFile(file)
        }
        reader.readAsDataURL(file)
      }
      document.body.removeChild(inputEl)
    })
    document.body.appendChild(inputEl)
    inputEl.click()
  }
  const uploadFile = async (file: File | any) => {
    console.log(file, 'file', file.name.split('.').pop() || 'jpeg')
    Toast.show({
      icon: 'loading',
      content: '图片上传中...'
    })
    try {
      const object_key = await uploadImage(file, hasToken)
      const { data: fileData } = await batchDownloadSigns({ params: [{ object_key }], hasToken })
      const _value = value || []
      fileData?.forEach((item: any) => {
        if (_value?.findIndex((item0: any) => item0?.object_key === item?.object_key) === -1) {
          _value.push(item)
        }
      })

      onConfirm?.(_value)
    } catch (e) {
      console.error(e)
      logger(e)
      Toast.clear()
    }
  }

  return <span onClick={openFile}>{element}</span>
}

export default UploadFile
