import { FC, useEffect, useState } from 'react'
import './index.less'
// 暂无数据

const colorLis: string[] = [
  'linear-gradient(156deg, rgba(255,99,21,0.60) 11%, #FF6315 89%)',
  'linear-gradient(156deg, rgba(247,56,26,0.60) 11%, #E73115 89%)',
  'linear-gradient(156deg, rgba(41,200,33,0.60) 11%, #29C821 89%)',
  'linear-gradient(156deg, rgba(35,222,156,0.60) 11%, #23DE9C 89%)',
  'linear-gradient(156deg, rgba(38,165,255,0.60) 11%, #26A5FF 89%)',
  'linear-gradient(156deg, rgba(43,64,255,0.60) 11%, #2B40FF 89%)',
  'linear-gradient(156deg, rgba(170,43,255,0.60) 11%, #AA2BFF 89%)',
  'linear-gradient(156deg, rgba(237,41,110,0.60) 11%, #ED296E 89%)'
]

const AvatarColor: FC<{
  className?: string
  name: string
  width?: number
  height?: number
  size?: number
  count?: number
}> = ({ className = '', name = '', count = 1 }) => {
  const [index, setIndex] = useState(-1)

  useEffect(() => {
    if (name.length > 0) {
      const tempName = name
      setIndex(tempName.substring(0, 1).charCodeAt(0) % 8)
    }
  }, [name])

  return (
    <div
      className={`avatar-box ${className}`}
      style={{
        background: colorLis[index]
      }}
    >
      <div className='avatar-box-tl'>
        {name?.length > count ? name.substring(name.length - count, name.length) : name}
      </div>
    </div>
  )
}

export default AvatarColor
