import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { bridge } from '@/utils/bridge'

import EventManager from './EventManager'

// 创建上下文
const EventManagerContext = createContext<EventManager | null>(null)

// 创建提供者组件
export const EventManagerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [eventManager] = useState(new EventManager())

  // 在卸载时清空所有事件处理器
  useEffect(() => {
    return () => {
      eventManager?.clearAll()
    }
  }, [eventManager])

  return (
    <EventManagerContext.Provider value={eventManager}>{children}</EventManagerContext.Provider>
  )
}

// 自定义 Hook 来获取事件管理器实例
export const useEventManager = () => {
  const context = useContext(EventManagerContext)
  if (context === null) {
    // throw new Error('useEventManager must be used within an EventManagerProvider')
  }
  return context
}
