import { useSetState } from 'ahooks'

interface T {
  afterOpen?: (data?: any) => void
  afterOk?: (data?: any) => Promise<any>
  afterCancel?: () => void
}
export default ({ afterOpen, afterOk, afterCancel }: T) => {
  const [{ open, modalData }, setState] = useSetState<{
    open: boolean
    modalData?: any
  }>({
    open: false,
    modalData: {}
  })
  const onOpen = (data?: any) => {
    const _state: any = { open: true }
    data && (_state.modalData = data)
    setState(_state)
    afterOpen?.()
  }
  const onCancel = () => {
    setState({ open: false })
    afterCancel?.()
  }
  const onOk = (data?: any) => {
    afterOk?.(data).then(() => {
      onCancel()
    }) || onCancel()
  }
  const setModalData = (_: any) => {
    console.log('setModalData---@@@>>>>', _)
    setState({ modalData: _ })
  }
  return {
    open,
    modalData,
    setModalData,
    onOpen,
    onOk,
    onCancel
  }
}
