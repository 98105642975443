import http from './http'

export interface IRequest {
  /**
   * @description 接口Method
   */
  method: string
  /**
   * @description 接口地址
   */
  url: string
  /**
   * @description 接口数据
   */
  data?: any
  /**
   * @description 字段名映射（返回字段）
   */
  fieldNames?: { [key: string]: string }
  /**
   * 是否超长延时（用于接口速度缓慢）
   */
  isLong?: boolean
  config?: any
}
function service<T>(option: IRequest): Promise<IResponseType<T | any>> {
  const { method, url, data, fieldNames, config } = option
  const dataPropName = method?.toLocaleLowerCase() === 'get' ? 'params' : 'data'
  // `data` 是作为请求体被发送的数据
  // 仅适用 'PUT', 'POST', 'DELETE 和 'PATCH' 请求方法
  return http
    .request({
      url,
      method,
      [dataPropName]: data,
      ...config
    })
    .then((res: any) => {
      if (res.data) {
        const type = Object.prototype.toString.call(res.data).slice(8, -1).toLocaleLowerCase()
        const _obj: any = {}
        switch (type) {
          case 'array':
            return {
              ...res,
              data: res.data.map((item: any) => {
                const obj: any = {}
                for (const key in item) {
                  if (Object.prototype.hasOwnProperty.call(item, key)) {
                    obj[fieldNames?.[key] || key] = item[key]
                  }
                }
                return obj
              })
            }
          case 'object':
            for (const key in res.data) {
              if (Object.prototype.hasOwnProperty.call(res.data, key)) {
                _obj[fieldNames?.[key] || key] = res.data[key]
              }
            }
            return {
              ...res,
              data: _obj
            }
        }
      }
      return res
    })
}
export default service
