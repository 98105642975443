import { Form, Loading } from 'antd-mobile'
import dayjs from 'dayjs'
import { toJS } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import NewEditForm from '@/components/NewEditForm'
import { StatusCode } from '@/components/NewEditForm/enum'
import useCommonForm from '@/components/NewEditForm/hooks/useCommonForm'
import jobStore from '@/store/components/entryJob'
import { getUrlParams } from '@/utils'

import HPopup from '../../HYBVant/HPopup'

import { hrmSaveEducation, hrmEducationList, hrmDeleteEducation } from './service'

import './index.less'

const Education: React.FC<any> = ({ visible, onCancel, modalData }) => {
  const [formIt] = Form.useForm()
  const currentFiledsRef: any = useRef(null)
  const location = useLocation()

  const {
    changeEducation,
    education,
    employeeIds,
    educationStatus,
    changeAllEducation,
    cacheEducationJson,
    changeCacheEducationJson
  } = useLocalObservable(() => jobStore)
  const { id } = getUrlParams()
  const isNoAuth = location.pathname === '/entry/fast'
  const qrcodeId = isNoAuth ? id : ''
  const vars = {
    minDate: dayjs('1960-01-01').valueOf()
  }
  const { option, optionStatus } = useCommonForm({
    code: 'mobile_education',
    immediate: !qrcodeId,
    vars
  })

  const onFinish = async (fieldValues: any) => {
    const field = {
      ...(currentFiledsRef.current || {}),
      ...fieldValues,
      employee_id: employeeIds?.[0],
      start_date: fieldValues?.start_date
        ? dayjs(fieldValues?.start_date).format('YYYY-MM-DD')
        : '',
      end_date: fieldValues?.end_date ? dayjs(fieldValues?.end_date).format('YYYY-MM-DD') : ''
      // education_name: store.getOptions('HRM_EDUCATION_LEVEL')?.find(x => x.value === fieldValues?.education_level)?.name
    }
    await hrmSaveEducation(field)
    fetchHrmEducationList()
  }

  const fetchHrmEducationList = async () => {
    const { code, data } = await hrmEducationList({ employee_id: employeeIds?.[0] })
    if (code === 2000) {
      changeAllEducation(data)
      onCancel()
    }
  }

  useEffect(() => {
    const setFieldValues = () => {
      const fields = education.find((x) => x.active)
      currentFiledsRef.current = fields
      formIt.setFieldsValue({
        ...fields
      })
    }
    educationStatus === 'Edit' && option?.formInfo?.length && setFieldValues()
  }, [educationStatus, option])

  const deleteInfo = async () => {
    console.log('education:', toJS(education))
    await hrmDeleteEducation([education.find((x) => x.active)?.id])
    fetchHrmEducationList()
  }

  const getFormButtons = (hasDelete?: boolean) => {
    const btns = [
      {
        key: 'remove',
        title: '删除',
        className: 'delete-btn',
        onClick: deleteInfo
      },
      {
        key: 'submit',
        title: '保存'
      }
    ]
    return hasDelete ? btns : btns.slice(1)
  }

  useEffect(() => {
    if (Array.isArray(option?.formInfo) && optionStatus === StatusCode.done) {
      changeCacheEducationJson(option.formInfo)
    }
  }, [option, optionStatus])

  console.log('formInfo111', qrcodeId, toJS(education), toJS(cacheEducationJson), educationStatus)
  return (
    <HPopup title='教育经历' hasOk={false} className='lg' visible={visible} onCancel={onCancel}>
      {Array.isArray(cacheEducationJson) || optionStatus === StatusCode.done ? (
        <NewEditForm
          formInst={formIt}
          formInfo={cacheEducationJson ?? option.formInfo ?? []}
          onFinish={onFinish}
          formButtons={getFormButtons(modalData?.status === 'Edit')}
        />
      ) : (
        <Loading className='init-loading lg' />
      )}
    </HPopup>
  )
}

export default observer(Education)
