/* eslint-disable no-redeclare */
import { DatePicker, Picker } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import dayjs from 'dayjs'
import { useMemo, useCallback } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import { toPx } from '@/utils'
import './index.less'

const weekdayToZh = (weekday: number) => {
  switch (weekday) {
    case 1:
      return '周一'
    case 2:
      return '周二'
    case 3:
      return '周三'
    case 4:
      return '周四'
    case 5:
      return '周五'
    case 6:
      return '周六'
    case 7:
      return '周日'
    default:
      return weekday
  }
}
export default (props: any) => {
  const {
    value,
    onConfirm,
    type,
    minDate,
    maxDate,
    ...rest
  }: {
    value?: any
    minDate?: any
    maxDate?: any
    onConfirm?: (value: any) => void
    type?: string
  } = props
  const { open, modalData, setModalData, onOpen, onOk, onCancel } = usePopup({
    afterOk: () => {
      const _value = modalData.value ? dayjs(modalData.value).format('YYYY-MM-DD') : value
      onConfirm?.(_value)
      return Promise.resolve()
    }
  })
  const __value = useMemo(() => {
    return modalData?.value ? modalData?.value : value ? dayjs(value).valueOf() : value
  }, [modalData])
  // console.log('picker:::', value)
  const precision = (type: any) => {
    if (type === 'datetime') {
      return 'minute'
    }
    if (type === 'date') {
      return 'day'
    }
    if (type === 'year-month') {
      return 'month'
    }
    return type
  }
  const labelRenderer = useCallback((str: string, data: number) => {
    switch (str) {
      case 'year':
        return data + '年'
      case 'week':
        return data + '周'
      case 'week-day':
        return weekdayToZh(data)
      default:
        return data
    }
  }, [])
  function generateTimeArray(
    start: number,
    end: number,
    step: number
  ): { label: string; value: string }[] {
    const compare =
      step > 0
        ? (current: number, limit: number) => current <= limit
        : (current: number, limit: number) => current >= limit

    const resultArray: { label: string; value: string }[] = []
    let i = start
    while (compare(i, end)) {
      const formattedValue = i < 10 ? `0${i}` : `${i}`
      resultArray.push({ label: formattedValue, value: formattedValue })
      i += step
    }
    return resultArray
  }
  const {
    minHour = 0,
    maxHour = 23,
    minMinute = 0,
    maxMinute = 59,
    hourStep = 1,
    minuteStep = 1
  } = props
  const hours = generateTimeArray(minHour, maxHour, hourStep)
  const minutes = generateTimeArray(minMinute, maxMinute, minuteStep)
  const basicColumns = [hours, minutes]
  const onConfirmOk = (timeParts: any) => {
    onConfirm?.(timeParts?.join(':') || null)
  }
  const min = minDate ? { min: minDate } : {}
  const max = maxDate ? { max: maxDate } : { max: new Date() }
  return (
    <>
      <div className='unified-filter-date-picker' onClick={() => onOpen()}>
        <div style={{ marginRight: toPx(10) }}>{value?.substr(0, 7)}</div>
        <RightOutline fontSize={16} />
      </div>
      {open && type !== 'time' && (
        <DatePicker
          visible={open}
          precision={precision(type)}
          value={__value}
          onSelect={(e: any) => setModalData({ value: e })}
          renderLabel={labelRenderer}
          {...rest}
          {...min}
          {...max}
          onClose={onCancel}
          onConfirm={onOk}
        />
      )}
      {open && type === 'time' && (
        <Picker
          visible={open}
          columns={basicColumns}
          value={__value}
          onSelect={(e: any) => setModalData({ value: e })}
          onClose={onCancel}
          onConfirm={onConfirmOk}
        />
      )}
    </>
  )
}
