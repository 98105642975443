export const json: { [index: string]: any } = {
  formInfo: [
    {
      title: '紧急联系人',
      key: 'concat-1',
      form_list: [
        {
          type: 'Input',
          formItemProps: {
            label: '联系人姓名',
            name: 'full_name',
            required: true
          }
        },
        {
          type: 'Input',
          formItemProps: {
            label: '联系人电话',
            name: 'contact_number',
            required: true
          },
          elementProps: {
            maxLength: 11,
            type: 'tel'
          }
        },
        {
          type: 'Input',
          formItemProps: {
            label: '关系',
            name: 'relationship'
          },
          elementProps: {
            placeholder: '请输入'
          }
        },
        {
          type: 'Input',
          formItemProps: {
            label: '联系人地址',
            name: 'contact_address'
          },
          elementProps: {
            placeholder: '请输入'
          }
        },
        {
          type: 'Textarea',
          formItemProps: {
            label: '备注',
            name: 'remark'
          },
          elementProps: {
            placeholder: '请输入'
          }
        }
      ]
    }
  ]
}
