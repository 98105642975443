import { http } from '@/utils'

// 考勤汇总
export const getAttendanceCount = (period_id?: string): any =>
  http.get(`/attendance/employee/${period_id}/month_statistics`, {})

// 考勤周期
export const getPeriod = (): any => http.get('/attendance/periods/accounted', { data: { type: 0 } })

export const updateAttendanceDetail = (data: any): any => http.put('/attendance/detail', data)

export const clockRecordManual = (data: any): any =>
  http.post('/attendance/clock_record/manual', data)

export const workHourAdjust = (data: any): any =>
  http.post('/attendance/daily_work_hour/adjust', data)

export const deleteSchedule = (data: any): any =>
  http.post('/schedule/plan/delete_schedule_shift', data)

// 查询列表
export const attendanceSummary = (data: any): any => http.post('/attendance/details', data)

export const attendanceSummaryOpera = (data: any): any => http.put('/attendance/detail', data)

export const attendanceShiftSchedule = (data: any): any =>
  http.post('/schedule/plan/attendance_shift_schedule', data)
