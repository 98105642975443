/**
 * 原生实现跨端bridge不支持的函数
 * export default: 图片水印（飞书）
 */
import dayjs from 'dayjs'

import { bridge } from '..'
import { service } from '../utils'
import { resolve } from 'path'
import uploadImage from './uploadImage'

const getUploadSign = (data: {
  file_suffix: string
  file_name: string
  content_type?: string
  u: number
  vs?: string
}): any => service({ method: 'post', url: '/hyb/oss/pri/upload_sign', data })

const toUpload = async (_params: any) => {
  const suffix = _params.path.slice(_params.path.lastIndexOf('.') + 1)
  const { data } = await getUploadSign({
    file_suffix: suffix,
    file_name: _params.path,
    content_type: 'image/*',
    u: dayjs().valueOf()
  })
  const formData = new FormData()
  const dataMap = {
    ...data,
    // 分别添加每个键值对
    OSSAccessKeyId: data.access_id,
    signature: data.signature,
    key: data.object_key,
    Signature: data.signature,
    success_action_status: '200',
    'x-oss-meta-file-name': _params.file.name,
    'x-oss-content-type': 'image/*'
  }
  // 添加其他数据
  Object.keys(dataMap).forEach((key: any) => {
    console.log('formData.', key, dataMap[key])
    formData.append(key, dataMap[key])
  })
  // 添加文件
  formData.append('file', _params.file, _params.file.name)
  await fetch(data.host, {
    // 替换为实际的上传接口地址
    method: 'POST',
    body: formData
  }).catch((error) => {
    console.error('Upload error:', error)
  })
  return data.object_key
}
function compressImage(myCanvas: any, format: any) {
  const imgCanvas = document.createElement('canvas')
  const imgCtx = imgCanvas.getContext('2d')
  const width = myCanvas.width
  const height = myCanvas.height
  const maxSide = 800
  const scaleFactor = maxSide / Math.max(width, height)
  const scaledWidth = width * scaleFactor
  const scaledHeight = height * scaleFactor
  imgCanvas.width = scaledWidth
  imgCanvas.height = scaledHeight
  console.log('new scaleWIdth:', scaledWidth, scaledHeight)
  imgCtx?.drawImage(myCanvas, 0, 0, scaledWidth, scaledHeight)
  return imgCanvas
}

/**
 * canvas 转 Blob 并上传
 * @param myCanvas HTMLCanvasElement
 * @returns
 */
export const canvasToUpload = async (myCanvas: HTMLCanvasElement, with_no_auth = false) => {
  const newCanvas: any = await compressImage(myCanvas, 'image/jpeg')
  return new Promise((resolve) => {
    newCanvas.toBlob(async (blob: Blob) => {
      if (!blob) return new Error('Failed to convert canvas to blob')

      // 创建一个临时文件路径（这里使用 URL.createObjectURL）
      const path = URL.createObjectURL(blob)
      const file = new File([blob], 'watermark.jpeg', { type: 'image/jpeg' })

      const object_key = await toUpload({ path, file })
      // 可以在这里使用 tempFilePath 进行其他操作，例如上传到服务器
      console.log('Temporary file path:', path)
      // 释放 URL 对象
      URL.revokeObjectURL(path)
      resolve(object_key)
    }, 'image/jpeg')
  })
}

export default async (origin: string, { time, dateWeather, username }: any): Promise<string> => {
  console.log('drawWatermark-file: ', origin)
  const drawWatermarkIcons: string[] = [
    'https://s.huiyunban.cn/1849282519027945472.png',
    'https://s.huiyunban.cn/1849281655366225920.png'
  ]
  return new Promise((resolve, reject) => {
    const img = new Image()
    const myCanvas = document.createElement('canvas')
    // myCanvas.style.display = 'none'
    // document.body.appendChild(myCanvas)
    const ctx = myCanvas.getContext('2d')
    if (!ctx) {
      reject(new Error('当前环境不支持水印'))
      return
    }
    img.onload = () => {
      // 与设计稿的像素比
      const scale = img.width / 750
      // 设置 canvas 的尺寸与图片相同
      myCanvas.width = img.width
      myCanvas.height = img.height
      console.log('myCanvas.size:', img.width, img.height)
      // 绘制图片
      ctx.drawImage(img, 0, 0, img.width, img.height)
      ctx.font = `${
        48 * scale
      }px 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', Arial, 'SourceHanSansSC-Regular', sans-serif`
      ctx.fillStyle = '#fff'
      ctx.fillText(time, 50, img.height - 144 * scale)
      ctx.font = `${
        28 * scale
      }px 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', Arial, 'SourceHanSansSC-Regular', sans-serif`
      ctx.fillStyle = '#fff'
      ctx.fillText(dayjs().format('YYYY.MM.DD'), 200 + 80 * scale, img.height - 152 * scale)
      ctx.font = `${
        28 * scale
      }px 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', Arial, 'SourceHanSansSC-Regular', sans-serif`
      ctx.fillStyle = '#fff'
      ctx.fillText(dateWeather, 80 + 36 * scale, img.height - 100 * scale)
      ctx.font = `${
        28 * scale
      }px 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', Arial, 'SourceHanSansSC-Regular', sans-serif`
      ctx.fillStyle = '#fff'
      ctx.fillText(username, 80 + 36 * scale, img.height - 44 * scale)
      ctx.strokeStyle = '#fff'
      ctx.lineWidth = 2 * scale
      ctx.moveTo(28, img.height - 192 * scale)
      ctx.lineTo(28, img.height - 28 * scale)
      ctx.stroke()
      let finishCount = 0
      const icons = drawWatermarkIcons.map(() => new Image())
      const finish = () => {
        uploadImage(myCanvas).then(resolve)
      }
      icons[0].crossOrigin = 'Anonymous'
      icons[1].crossOrigin = 'Anonymous'
      icons[0].onload = () => {
        ctx.drawImage(icons?.[0], 50, img.height - 128 * scale, 36 * scale, 36 * scale)
        finishCount++
        if (finishCount === 2) finish()
      }
      icons[1].onload = () => {
        ctx.drawImage(icons?.[1], 50, img.height - 72 * scale, 36 * scale, 36 * scale)
        finishCount++
        if (finishCount === 2) finish()
      }
      drawWatermarkIcons.forEach(async (url: string, index: number) => {
        if (url.startsWith('https://') || url.startsWith('http://')) {
          icons[index].src = url
        } else {
          const base64Str = await bridge.getImageInfo(url)
          icons[index].src = base64Str
        }
      })
    }
    img.src = origin
  })
}

// const createFileFromBase64 = (base64Data: string) => {
//   const byteCharacters = atob(base64Data)
//   const byteNumbers = new Array(byteCharacters.length)
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i)
//   }
//   const byteArray = new Uint8Array(byteNumbers)
//   const blob = new Blob([byteArray], { type: 'image/jpeg' })
//   const file = new File([blob], 'file.jpg', { type: 'image/jpeg' })
//   return file
// }
