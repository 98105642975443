import { filter } from 'lodash-es'
import { action, makeObservable, observable } from 'mobx'
import { createContext } from 'react'

class TeamDailyStore {
  constructor() {
    makeObservable(this)
  }
  @observable listItem: any = {}

  @observable teamDailyDetailFilter: string = ''

  @action
  setListItem(_: any) {
    this.listItem = {
      ...this.listItem,
      ..._
    }
  }

  @action
  setTeamDailyDetail = (filter: string) => {
    this.teamDailyDetailFilter = filter
  }

  @action
  clear() {
    this.listItem = {}
  }
}

export const teamDailyStoreNoContext = new TeamDailyStore()
export default createContext(teamDailyStoreNoContext)
