import { Calendar } from 'antd-mobile'
import type { CalendarProps } from 'antd-mobile/es/components/calendar/calendar'
import dayjs from 'dayjs'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'

import HCell from '../HCell'
import { publicProps } from '../utils'
import './index.less'

const formatDate = (v: any) => v?.map?.((item: any) => dayjs(item).format('YYYY-MM-DD'))
const formatDateStr = (v: any) =>
  v?.map?.((item: any) => dayjs(item).format('YYYY-MM-DD'))?.join('；')
function HCalendar({
  value,
  onConfirm,
  type,
  ...rest
}: { value?: any; onConfirm?: (value: any) => void; type?: any } & CalendarProps) {
  const { open, onOpen, onCancel } = usePopup({})
  console.log('HCalendar', rest)
  return (
    <>
      <HCell value={formatDateStr(value)} onClick={() => onOpen()} />
      {open && (
        <Calendar
          show={open}
          type={type || 'multiple'}
          value={value}
          onConfirm={(e: any) => {
            onConfirm?.(e?.detail?.value?.map((date: any) => date?.valueOf()))
            onCancel()
          }}
          onClose={onCancel}
          {...publicProps(rest, 'calendar')}
        />
      )}
    </>
  )
}

HCalendar.transform = (value: any) => {
  return formatDate(value)
}
export default HCalendar
