import logger from '@/utils/logger'

import HCell from '../../HYBVant/HCell'

// 地图跳转选择
function LocationSelect(props: any) {
  const handleOpen = () => {
    // Taro.chooseLocation({
    //   ...props.value,
    //   success: (res) => {
    //     console.log('res:', res)
    //     props.onConfirm?.(res)
    //     props.afterConfirm?.(res)
    //   },
    //   fail: (err) => {
    //     logger(err)
    //   }
    // })
  }
  return (
    <HCell
      value={props.value?.name || props.value?.address}
      // renderRightIcon={
      //   props.value?.name ? (
      //     <div className='primary' onClick={() => handleOpen()}>
      //       查看定位
      //     </div>
      //   ) : null
      // }
      onClick={() => !props?.disabled && handleOpen()}
      disabled={props.disabled}
    />
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
LocationSelect.convertValue = (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 3) {
    return {
      [config?.names[0]]: {
        name: values[config?.names[0]],
        latitude: values[config?.names[1]],
        longitude: values[config?.names[2]]
      }
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
LocationSelect.transform = (value: any, config?: any) => {
  if (value && Array.isArray(config?.names) && config?.names?.length === 3) {
    return {
      [config?.names[0]]: value?.name || value?.address,
      [config?.names[1]]: value?.latitude,
      [config?.names[2]]: value?.longitude
    }
  }
  return value?.id
}

export default LocationSelect
