import { useSetState } from 'ahooks'
import { Image } from 'antd-mobile'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'

import { getUploadSign, batchDownloadSigns } from './service'

import './index.less'

const FormImage: React.FC<any> = ({ label, limit = 100, value, onConfirm, disabled }) => {
  const [{ imgUrl, objectKey, show, showImg }, setState] = useSetState<any>({
    imgUrl: [],
    objectKey: [],
    show: false,
    showImg: ''
  })

  const downloadSigns = async (params: any) => {
    // const { data } = await batchDownloadSigns(params)
    // setState({
    //   imgUrl: [...imgUrl, data[0].url],
    //   objectKey: [...objectKey, params[0].object_key]
    // })
    if (onConfirm) onConfirm([...objectKey, params[0].object_key])
  }

  const deleteSign = (index: number) => {
    if (onConfirm) onConfirm(objectKey.filter((x, i) => i !== index))
  }

  useEffect(() => {
    const downloadImg = async (params: any = []) => {
      const { data = [] } = await batchDownloadSigns(params)
      // !objectKey.includes(params[0].object_key) &&
      setState({
        imgUrl: [...data.map((x) => x?.url)],
        objectKey: [...params.map((x) => x?.object_key)]
      })
    }

    if (value && Array.isArray(value)) {
      // !value.some((x: any) => objectKey.includes(x)) &&
      downloadImg(value.map((x) => ({ object_key: x })))
    } else if (value && (value?.object_key || value?.oss_key)) {
      // !value.some((x: any) => objectKey.includes(x)) &&
      downloadImg([{ object_key: value?.object_key || value?.oss_key }])
    }
  }, [value])

  const chooseImg = () => {
    if (imgUrl.length === limit) {
      console.log('数量受限')
      return
    }
    // 对于H5环境，使用<input type="file">
    const inputEl = document.createElement('input')
    inputEl.type = 'file'
    inputEl.accept = 'image/*'
    inputEl.multiple = false // 限制只能选择一张图片
    inputEl.click()

    inputEl.onchange = async (event: any) => {
      const files = event.target.files
      if (files.length > 0) {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = async (e: any) => {
          const tempFilePath = e.target.result
          const { data } = await getUploadSign({
            file_suffix: file.name.split('.').pop() || 'jpeg',
            file_name: file.name,
            content_type: 'image/*',
            u: dayjs().valueOf(),
            vs: CryptoJS.MD5(`HYB:${dayjs().valueOf()}`).toString()
          })

          // Taro.uploadFile({
          //   url: data.host,
          //   filePath: tempFilePath,
          //   name: 'file',
          //   formData: {
          //     object_key: data.object_key,
          //     policy: data.policy,
          //     OSSAccessKeyId: data.access_id,
          //     signature: data.signature,
          //     key: data.object_key,
          //     Signature: data.signature,
          //     success_action_status: '200',
          //     'x-oss-meta-file-name': file.name,
          //     'x-oss-content-type': 'image/*',
          //     ...data
          //   },
          //   success: function () {
          //     downloadSigns([{ object_key: data.object_key }])
          //   },
          //   fail: function (error) {
          //     console.log('上传失败', error)
          //   }
          // })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  useEffect(() => {
    // Taro.authorize({
    //   scope: 'scope.writePhotosAlbum',
    //   success() {
    //     console.log('授权成功')
    //   },
    //   fail(error) {
    //     console.error('授权失败', error)
    //   }
    // })
  }, [])

  return (
    <div className='formf-image'>
      <div className='formf-image-row'>
        <div className='formf-image-row-title'>{label}</div>
        <div className='formf-image-row-img'>
          {/* <Image
            onClick={chooseImg}
            className='form-image-img'
            src='https://s.huiyunban.cn/1746806065627926528.png'
          /> */}
        </div>
      </div>
      <div className='formf-image-content'>
        {imgUrl.map((x, index) => (
          <div key={x} className='formf-image-display'>
            <div onClick={() => deleteSign(index)} className='formf-image-display-close'>
              <Image
                onClick={() => deleteSign(index)}
                className='formf-image-display-close-img'
                src='https://s.huiyunban.cn/1752253018285281280.png'
              />
            </div>
            <Image
              className='formf-image-display-img'
              src={x}
              onClick={() => setState({ showImg: x, show: true })}
            ></Image>
          </div>
        ))}

        <div className='formf-image-choose' onClick={() => !disabled && chooseImg()}>
          <Image
            className='formf-image-display-img'
            src='https://s.huiyunban.cn/1764210567377063936.png'
          ></Image>
        </div>
      </div>
      {/* <Overlay show={show} onClick={() => setState({ show: false })}>
        <Image mode='widthFix' className='form-image-show-img' src={showImg} />
      </Overlay> */}
    </div>
  )
}

export default FormImage
