import { http } from '@/utils'

// 当日考勤
export const getCurrentAttendance = (data: any): any => {
  return http.get('/attendance/employee/team/calendar', { params: data })
}

export const attendancePeriodSummary = (data: any): any => {
  return http.put('/attendance/period/summary', data)
}

export const fetchAttendancePeriodSummary = async (data: any) => {
  const res = await attendancePeriodSummary(data)
  return res
}
