import { action, makeObservable, observable } from 'mobx'

import { getToken } from '@/utils'
import service from '@/utils/service'

import { getDictListGroup, getNoLoginDictListGroup, systemDictListGroup } from '../services'

// 数据字典接口默认参数
const DictRequestParam = { paged: false, cur_page: 0, page_size: 999, enable_status: 1 }
class DictStore {
  constructor() {
    makeObservable(this)
    this.fetchDictMap()
  }
  // 指定对应的接口字段 select or tree
  fieldNames = { label: 'name', value: 'value' }
  @observable dictMap: any = {}
  @observable state = 'pending' // "pending" / "done" / "error"
  @observable extraState = 'pending' // "pending" / "done" / "error"
  @action
  handleSuccess(data: any) {
    const map: any = {}
    data?.forEach((item: any) => {
      const { type_code, setting_list } = item
      map[type_code] = setting_list
    })
    this.dictMap = map
  }

  @action
  fetchDictMap() {
    this.dictMap = {}
    this.state = 'pending'
    const token = getToken()
    if (!token) return
    systemDictListGroup({ paged: false, cur_page: 0, page_size: 999, enable_status: '1' }).then(
      ({ success, data }) => {
        if (success) {
          const codesArray = data?.map((item_: any) => item_?.code) || []
          getDictListGroup({ types: codesArray, enable_status: '1' }).then(
            ({ success: success_, data: data_ }) => {
              if (success_) {
                this.handleSuccess(data_)
              }
              this.state = 'done'
            },
            (error: any) => {
              this.state = 'error'
              console.log('error: ', error)
            }
          )
        }
        this.state = 'done'
      },
      (error: any) => {
        this.state = 'error'
        console.log('error: ', error)
      }
    )
  }

  @action
  fetchNoLoginDictMap(params: any) {
    this.dictMap = {}
    this.state = 'pending'
    getNoLoginDictListGroup({ enable_status: '1', ...params }).then(
      ({ success: success_, data: data_ }) => {
        if (success_) {
          this.handleSuccess(data_)
        }
        this.state = 'done'
      },
      (error: any) => {
        this.state = 'error'
        console.log('error: ', error)
      }
    )
  }

  @action
  addDict(key: string, dict: any) {
    this.dictMap[key] = dict
  }

  /**
   * 获取options
   * @param key 字典类型
   * @returns 字典列表 []
   */
  @action
  getOptions(key: string, disabledOptions?: string[]) {
    const options = this.dictMap[key]
      ?.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.value,
        text: item.name
      }))
      ?.filter((item: any) => {
        return !disabledOptions?.includes(item.value)
      })
    return options
  }
  /**
   * 用value获取label
   * @param param0 必填 value & dictionary
   * @returns
   */
  getLabelByValue({
    value,
    dictionary,
    isCheckbox,
    disabled
  }: {
    value: string
    dictionary: string
    isCheckbox?: boolean
    disabled?: string[]
  }) {
    /* eslint-disable indent */
    const options = isCheckbox
      ? this.dictMap[dictionary]?.map((item: any) => ({
          ...item,
          label: item.name,
          value: item.value,
          disabled: !!disabled?.includes(item.value)
        }))
      : this.dictMap[dictionary] || []
    const matched = options.find((item: any) => item.value === value)
    console.log('matched', matched, options, value)
    return matched?.label || matched?.name || ''
  }

  @action
  async requestExtraDicts(
    dicts: Array<{
      name: string
      fieldNames: { label: string; value: string }
      url: string
      method: string
      param?: any
      isForce?: boolean
    }>
  ) {
    this.extraState = 'pending'
    let requestCount = 0
    return Promise.all(
      dicts?.map(async (dict: any) => {
        const { name, fieldNames, url, method, param, isForce } = dict
        if (!this.dictMap[name] || isForce) {
          const { success, data } = await service<any>({
            method,
            url,
            data: { ...DictRequestParam, ...param }
          })
          if (success) {
            const dictData = fieldNames?.data ? data[fieldNames.data] : data
            this.dictMap[name] = dictData?.map((item: any) => {
              return {
                ...item,
                name: item[fieldNames.label],
                label: item[fieldNames.label],
                value: item[fieldNames.value],
                type: item?.type
              }
            })
            requestCount++
            console.log('requestCount:', requestCount)
            if (requestCount === dicts?.length) {
              setTimeout(() => {
                console.log('extraState:', this)
                this.extraState = 'done'
              })
            }
          }
          return data
        }
      })
    )
  }
}
export const dictStore = new DictStore()

export default dictStore
