import { isEqual, isNil, uniq } from 'lodash-es'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import useInited from '@/hooks/useInited'
import authStore from '@/store/global/authStore'
import unifiedStore from '@/store/unified/unifiedStore'
import { getTypeof, isNotEmptyArray, toPx } from '@/utils'

import { PageOption } from '../../hooks/usePage'
import useScrollList from '../../hooks/useScrollList'
import BatchAction from '../BatchAction'
import Buttons from '../Buttons'
import Filter from '../Filter'
import ScrollList from '../ScrollList'
import './index.less'

/**
 * 通用列表布局组件
 * @returns FC
 */
const Layout = ({ className, option }: PageOption) => {
  const optionStore = option
  const stateStore = useContext(unifiedStore)
  const permission = useLocalObservable(() => authStore)
  const [inited, initSuccess] = useInited(1, 0)
  const [requestInited, requestSuccess] = useInited(1, 0)
  const { immediate, services, beforeFilter, afterFilter, beforeList, afterList, action } =
    optionStore.pageOption
  const {
    form_list = [],
    confirm_form_list = [],
    ...restFilterOption
  } = optionStore?.filterOption || {}

  const {
    cardItem,
    renderItem,
    onPullUp,
    abnormalAction = null,
    ...tableOptionRest
  } = optionStore.tableOption
  const filterFormat = optionStore?.filterOption ? optionStore?.filterOption?.dataFormat : undefined
  const tableFormat = optionStore?.tableOption ? optionStore?.tableOption?.dataFormat : undefined
  const { loading, noMore, total, dataList, refresh, loadMore, runQuery } = useScrollList(
    services?.query,
    {
      immediate,
      format: filterFormat,
      stateStore: stateStore,
      tableFormat
    }
  )
  const filterData = {
    runQuery,
    form_list,
    confirm_form_list,
    ...restFilterOption
  }
  const refreshFn = useCallback(() => {
    const data = filterFormat
      ? filterFormat(stateStore.filterState?.filterValues)
      : stateStore.filterState?.filterValues
    runQuery({ ...data, current: 1 })
  }, [filterFormat, runQuery, stateStore.filterState?.filterValues])

  const listData: any = {
    noMore,
    total,
    loading,
    dataList,
    refresh: refreshFn,
    loadMore,
    runQuery,
    onPullUp,
    cardItem,
    renderItem,
    bottom: action?.length > 0 ? toPx(120) : 0,
    ...tableOptionRest
  }

  const { selectedKeys = [], selectedItems = [] } = stateStore.tableState
  // 是否展示批量处理模块
  const batchActionVisible = isNotEmptyArray(selectedKeys)
  const { row_selection_code = 'id' } = tableOptionRest || {}
  // 所有数据id
  const allKeyList = isNotEmptyArray(dataList)
    ? uniq(dataList.map((item) => item[row_selection_code]))
    : []

  useEffect(() => {
    const fieldName: any = optionStore?.filterOption
      ? optionStore?.filterOption?.form_list?.find((i: any) => i.required)?.name
      : null
    if (!fieldName || (fieldName && !isNil(stateStore.filterState?.filterValues?.[fieldName]))) {
      !inited && initSuccess()
      requestInited && refreshFn()
    }
  }, [stateStore.filterState?.filterValues])

  useEffect(() => {
    if (inited) {
      refreshFn()
      requestSuccess()
    }
  }, [inited])

  useEffect(() => {
    const tabsIndex = form_list?.findIndex(
      (i: any) => i.type === 'Tabs' && i.elementProps?.showTotal
    )
    if (tabsIndex > -1) {
      const fieldName: any = form_list[tabsIndex].name
      const filterValue = stateStore.filterState?.filterValues?.[fieldName]
      const _tabsTotal = stateStore.filterState?.tabsTotal
      const newtabsTotal = form_list[tabsIndex].elementProps?.dataList?.map((_: any, i: number) =>
        isEqual(_.value, filterValue) && _.showTotal ? total : _tabsTotal?.[i] || 0
      )
      stateStore.setFilterState({ total, refresh, refreshFn, runQuery, tabsTotal: newtabsTotal })
    } else {
      stateStore.setFilterState({ total, refresh, refreshFn, runQuery })
    }
  }, [total, services?.query])

  // useEffect(() => {
  //   setTimeout(() => {
  //     runQuery(
  //       filterFormat
  //         ? filterFormat(stateStore.filterState?.filterValues)
  //         : stateStore.filterState?.filterValues
  //     )
  //     onPullUp?.()
  //   }, 100)
  // }, [])

  // 数据更新后重新计算选中项
  useEffect(() => {
    const tempValues = selectedKeys.filter((item) => allKeyList.includes(item))
    stateStore?.setTableState?.({
      selectedKeys: tempValues,
      selectedItems: dataList?.filter((item) => tempValues.includes(item[row_selection_code]))
    })
  }, [dataList])

  const BatchActionProps = {
    stateStore,
    permission,
    selectedKeys,
    selectedItems,
    allKeyList,
    dataList,
    batchActions: tableOptionRest.action as any[]
  }

  return (
    <div
      id='jsUnifiedLayout'
      className={`unified-layout ${className || ''} ${batchActionVisible ? 'unified-layout-with-batch-action' : ''}`}
    >
      <div className='unified-filter-wrap' id='unifiedFilterWrap'>
        {beforeFilter}
        <Filter dataSource={filterData} optionStore={optionStore} />
        {getTypeof(afterFilter) === 'Function' ? afterFilter?.() : afterFilter}
      </div>
      {beforeList}
      <ScrollList
        dataSource={listData}
        stateStore={stateStore}
        before={listData.before}
        after={listData.after}
      />
      <Buttons dataSource={action} permission={permission} />
      {afterList}
      {batchActionVisible && <BatchAction abnormalAction={abnormalAction} {...BatchActionProps} />}
    </div>
  )
}

export default observer(Layout)
