/**
 * 钉钉api权限声明（需要鉴权的接口）
 * 参考：https://open.dingtalk.com/document/orgapp/jsapi-overview 是否需要鉴权
 */
export const DDAuthCode: any = {
  // 从摄像头拍照
  camera: ['biz.util.uploadImageFromCamera'],
  // 位置相关
  location: ['device.geolocation.get', 'device.geolocation.start', 'device.geolocation.stop']
}

/**
 * 钉钉认证结果
 */
export const DDAuthStatus: any = {
  ok: '授权成功',
  toAdmin: '向管理员发送授权申请，等待授权',
  failed: '授权异常',
  cancel: '主动取消授权'
}

export const AuthType: any = {
  location: 'LBS',
  camera: 'CAMERA'
}

export const DDSetting: any = {
  location: { action: 'android.settings.LOCATION_SOURCE_SETTINGS' }
  // TODO
}
