// import moment from 'moment'
import dayjs from 'dayjs'
// 状态和对应颜色
export const ClassMap = {
  // 审批中
  UNDER_APPROVAL: 'Attendance-bd-pending',
  // 通过
  APPROVED: 'Attendance-bd-passed',
  // 未通过
  NOT_APPROVED: 'Attendance-bd-reject',
  // 退回
  RETURNED: 'Attendance-bd-ended',
  // 撤销
  REVOKED: 'Attendance-bd-ended'
}
// 日历头
export const WeekDayStr: string[] = ['一', '二', '三', '四', '五', '六', '日']
// 今日
export const TodayStr = dayjs().format('YYYY-MM-DD')
// 本月第一个
export const MonthStartStr = dayjs().startOf('month').format('YYYY-MM-DD')

export enum DATE_TYPE {
  working = 'WORKING_DAY',
  weekend = 'WEEKEND',
  holiday = 'HOLIDAY'
}

export const ScheduleModalTitleMap: Record<string, string> = {
  Clock: '手动补卡',
  Hour: '调整工时',
  Schedule: '修改班次时段'
}
