// 微信/小程序兼容路由/事件
import { history } from './history'

const { userAgent } = window.navigator
const lowerUA = userAgent.toLowerCase()

// 是否微信小程序
export const isMiniProgram = lowerUA.includes('miniprogram')

// 是否微信环境
export const isWxEnv = lowerUA.includes('micromessenger') && !isMiniProgram

// 是否iOS
export const isIOS = lowerUA.includes('ios')

// 是否安卓
export const isAndroid = lowerUA.includes('android')

// ready
export const wxReady = (fn: unknown) => {
  if (typeof fn === 'function') {
    window.wx && window.wx?.ready(fn)
  }
}

export function jsonToUrlParams(json) {
  if (!json) {
    return ''
  }
  return Object.keys(json)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
    .join('&')
}

/** 生成webviewurl */
export const createWebviewUrl = ({
  pageType = 'h5',
  path,
  param
}: {
  pageType?: string
  path: string
  param?: {
    [x: string]: string | number
  }
}) => {
  const tempParamStr = encodeURIComponent(path.split('?')?.[1] || '')
  const paramStr = tempParamStr || (param ? encodeURIComponent(jsonToUrlParams(param)) : '')

  return `/pages/WebViewUrl/index?pageType=${pageType}&path=${path}${
    paramStr ? `&param=${paramStr}` : ''
  }`
}

// export const miniProgram = window.wx?.miniProgram

interface NavProps {
  url?: string
  type?: string
  [key: string]: any
}

/** 通用导航 */
export const commonNav = (() => {
  const { navigateTo, redirectTo, navigateBack, reLaunch, switchTab } = window.wx?.miniProgram || {}
  // 保留全局跳转
  // if (isMiniProgram) {
  //   return {
  //     navigateTo: ({ url = '', ...rest } = {}) => {
  //       navigateTo({
  //         url: createWebviewUrl({
  //           path: url
  //         }),
  //         ...rest
  //       })
  //     }
  //   }
  // }

  const pathPrefix = __ROUTE_PREFIX__ !== '/' ? __ROUTE_PREFIX__.slice(0, -1) : ''
  return {
    navigateTo: ({ type = 'h5', url }: NavProps = {} as any) => {
      if (isMiniProgram && type === 'miniProgram') {
        return navigateTo({ url })
      }
      history.push(`${pathPrefix}${url}`)
    },
    redirectTo: ({ type = 'h5', url }: NavProps = {} as any) => {
      if (isMiniProgram && type === 'miniProgram') {
        return redirectTo({ url })
      }
      history.replace(`${pathPrefix}${url}`)
    },
    navigateBack: ({ type = 'h5' }: NavProps = {} as any) => {
      if (isMiniProgram && type === 'miniProgram') {
        return navigateBack()
      }
      history.back()
    },
    switchTab: ({ type = 'h5', url }: NavProps = {} as any) => {
      if (isMiniProgram && type === 'miniProgram') {
        return switchTab({ url })
      }
      history.replace('/')
    },
    reLaunch: ({ type = 'h5', url }: NavProps = {} as any) => {
      if (isMiniProgram && type === 'miniProgram') {
        // eslint-disable-next-line no-unsafe-optional-chaining
        return wx?.miniProgram?.reLaunch({ url })
      }
      history.replace('/')
    }
  }
})()
