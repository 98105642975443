/**
 * 小程序不支持动态代码 - h5专用
 * https://developers.weixin.qq.com/miniprogram/dev/framework/runtime/js-support.html
 */

export default (context: any) => {
  const hideExec = (func: any, state: any, option: any) => {
    if (!func) return func
    if (typeof func === 'function') {
      return func()
    } else if (typeof func === 'string') {
      return returnFunctionCall(func, state, option)
    }
    return func
  }
  const onAfterChangeExec = (func: any, state: any, option: any, ...rest: any[]) => {
    if (!func) return func
    if (typeof func === 'function') {
      func(...rest)
    } else if (typeof func === 'string') {
      functionCall(func, state, option, ...rest)
    }
  }
  const returnFunctionCall = (func: any, state: any, option: any, ...rest: any[]) => {
    try {
      const values = context.form?.getFieldsValue?.() || {}
      const current = context?.current || {}
      // eslint-disable-next-line no-new-func
      const _func = new Function('values', 'current', 'form', 'option', 'state', `return ${func}`)
      console.log(
        'hide:',
        func,
        state,
        _func(values, current, context.form, option, state, ...rest)
      )
      return _func(values, current, context.form, option, state, ...rest)
    } catch (error) {
      console.error(error)
    }
    return func
  }
  const functionCall = (func: any, state: any, option: any, ...rest: any[]) => {
    try {
      const values = context.form?.getFieldsValue?.() || {}
      const current = state || {}
      // eslint-disable-next-line no-new-func
      const _func = new Function('values', 'current', 'form', 'option', func)
      return _func(values, current, context.form, option, ...rest)
    } catch (error) {
      console.error(error)
    }
  }
  return {
    hideExec,
    onAfterChangeExec
  }
}
