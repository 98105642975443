/* eslint-disable react-hooks/exhaustive-deps */
import { useSetState } from 'ahooks'
import { isEqual, isNil } from 'lodash-es'
import { useEffect } from 'react'

import { isNotEmptyArray, isNullOrUndefined } from '@/utils'

import HCell from '../../HYBVant/HCell'
import { publicEvents } from '../../HYBVant/utils'

import MyPickerBox from './pickerBox'
import './index.less'

const convertData = (arr: any[], fieldNames: any) => {
  if (!(Array.isArray(arr) && arr.length)) {
    return arr
  }
  return arr.map((item) => ({
    ...item,
    label: item?.[fieldNames?.label || 'name'],
    value: item?.[fieldNames?.label || 'value']
  }))
}

function PickerBox(props: any) {
  const {
    value,
    fieldNames = { value: 'value', label: 'name' },
    dataList = [],
    disabled = false,
    renderItem,
    defaultSelected = false
  } = props

  const [
    {
      show,
      innerValue,
      // selectInfo,
      defaultIndex
    },
    setState
  ] = useSetState<any>({
    // selectInfo: {},
    show: false,
    innerValue: null,
    defaultIndex: 0
  })
  // console.log('dataList--->>>', dataList)
  const onConfirm = (val: any) => {
    if (props.handleChange) props?.handleChange(val)
    publicEvents.onChange.call(props, val, 'Select')
    setState({
      show: false
    })
  }

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
    setState({
      show: false
    })
  }

  const onClick = () =>
    setState({
      show: true
    })

  useEffect(() => {
    if (!isNullOrUndefined(value)) {
      dataList?.forEach((item: any, index: number) => {
        if (item[fieldNames?.value] === value) {
          setState({ defaultIndex: index, innerValue: item[fieldNames?.label] })
        }
      })
    } else if (!isEqual(value, innerValue)) {
      setState({ defaultIndex: 0, innerValue: undefined })
    }
  }, [value, dataList, innerValue])

  const bool: boolean = !props?.disabled && !props?.readed

  useEffect(() => {
    if (defaultSelected && !value) {
      onConfirm(dataList?.[0]?.[fieldNames?.value])
    }
  }, [value])

  return (
    <>
      {renderItem ? (
        renderItem(onClick)
      ) : (
        <HCell
          value={innerValue}
          onClick={bool && onClick}
          disabled={props.disabled}
          readed={props?.readed}
        />
      )}
      <MyPickerBox
        show={show}
        value={isNotEmptyArray(value) ? value : [value]}
        setState={setState}
        onConfirm={onConfirm}
        onCancel={onCancel}
        dataList={dataList}
        fieldNames={fieldNames}
        defaultIndex={defaultIndex}
      />
    </>
  )
}
export default PickerBox
