import { Image } from 'antd-mobile'
import { DownFill } from 'antd-mobile-icons'
import { forwardRef, useImperativeHandle, useState } from 'react'

import { SvgIcon } from '@/components'
import Organize from '@/components/NewEditForm/UILibrary/HYBElement/Organize'
import './index.less'

export default forwardRef(
  (
    {
      type,
      defaultSelect = false,
      width,
      onConfirm
    }: {
      type: string
      defaultSelect?: boolean
      width?: number
      onConfirm?: (value: any) => void
    },
    ref
  ) => {
    // location.hash?.split('#/')[1]?.split('/')[2]
    const [value, setValue] = useState<any>()
    useImperativeHandle(ref, () => ({
      getValue: () => value
    }))
    const handleConfirm = (v: any) => {
      setValue(v)
      onConfirm?.(v)
    }
    console.log('value:::', value, type)
    return (
      <div className='hyb-filter-organize-wrap'>
        <SvgIcon className='org-icon' name='org' />
        <Organize
          value={value}
          onConfirm={handleConfirm}
          placeholder='组织筛选'
          elementProps={{
            type,
            config: {
              defaultSelect
            },
            arrowIcon: <DownFill className='arrow-icon' color='#595960' />
          }}
        />
      </div>
    )
  }
)
