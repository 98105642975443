import mapping from '../mapping.json'

import pc from './pc.type.json'

const mobile = mapping.components.reduce((prev: Record<string, string>, next: string) => {
  prev[next] = next
  return prev
}, {})
/**
 * 移动端支持的所有组件类型
 */
const config: Record<string, string> = {
  // 移动端自有
  ...mobile,
  // 来自于PC端映射
  ...pc
}

export default config
