import { useRequest } from 'ahooks'
import { Image } from 'antd-mobile'
import { AddSquareOutline } from 'antd-mobile-icons'
import { isEmpty } from 'lodash-es'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import jobStore from '@/store/components/entryJob'

import Popup from './Popup'
import { healthsEmployee } from './service'

import './index.less'

const HealthCertificate: React.FC<any> = ({ onConfirm }) => {
  const {
    employeeIds,
    healthCertificate = [],
    changeHealthCertificateStatus,
    changeAllHealthCertificate,
    changeEditHealthItem
  } = useLocalObservable(() => jobStore)
  const { open, modalData, onCancel, onOpen } = usePopup({})
  useEffect(() => {
    if (employeeIds?.length) {
      detailsOk()
    }
  }, [employeeIds])
  const detailsOk = () => {
    runHhealthsEmployee({
      paged: false,
      cur_page: 0,
      page_size: 999,
      employee_id_list: employeeIds
    })
  }
  // 员工健康证
  const { run: runHhealthsEmployee } = useRequest(healthsEmployee, {
    manual: true,
    onSuccess: (result: any) => {
      const { data } = result
      changeAllHealthCertificate(data)
    },
    onError: ({ message }) => {
      console.log('error', message)
      changeAllHealthCertificate([])
    }
  })
  const add = () => {
    changeHealthCertificateStatus('Add')
    onOpen({ status: 'Add' })
  }

  const edit = (index: number) => {
    changeEditHealthItem(healthCertificate?.[index])
    changeHealthCertificateStatus('Edit')
    onOpen({ status: 'Edit' })
  }

  useEffect(() => {
    if (onConfirm) onConfirm(healthCertificate)
  }, [healthCertificate])

  return (
    <div className='health_certificate'>
      {!isEmpty(healthCertificate) && (
        <div className='health_certificate-content-wrapper'>
          {healthCertificate?.map((x: any, index: number) => (
            <div key={x} className='health_certificate-content' onClick={() => edit(index)}>
              <div className='health_certificate-content-col-1'>
                <div className='health_certificate-content-1'>
                  {x?.city_names?.join('/') || '-'}
                </div>
                <div className='health_certificate-content-3'>
                  {x?.health_start_at || '-'}&nbsp;&nbsp;至&nbsp;&nbsp;{x?.health_end_at || '-'}
                </div>
              </div>
              <div className='health_certificate-content-col-2'>
                <Image
                  className='hyb-com-concat-content-col-2-img'
                  src='https://s.huiyunban.cn/1747191709529280512.png'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='health_certificate-tip' onClick={add}>
        <AddSquareOutline />
        <div className='health_certificate-tip-title'>添加健康证</div>
      </div>
      {open && <Popup visible={open} onCancel={onCancel} modalData={modalData} />}
    </div>
  )
}

export default observer(HealthCertificate)
