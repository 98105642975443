import { http } from '@/utils'

export const postMessagesEmployeeApi = (data: any): any =>
  http.post(`/system/messages/employee`, data)

export const postMessageReadApi = (id: string) => http.post(`/system/messages/read/${id}`, {})
// 获取常用菜单
export const menuCommonlyUsedList = () => http.get(`/console/menu/commonly_used/list`, {})
// 保存常用菜单
export const menuCommonlyUsed = (data: any) => http.post(`/console/menu/commonly_used`, data)
