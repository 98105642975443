import classNames from 'classnames'
import React from 'react'
import './index.less'

interface CellProps {
  title: React.ReactNode
  content: React.ReactNode
  display?: React.ReactNode
  onClick?: () => void
  className?: any
  showIcon?: boolean
}

const Cell: React.FC<CellProps> = (props) => {
  const { title, content, display, onClick, className, showIcon = true } = props
  return (
    <div
      className={classNames('cell', className?.classNameCell || 'cell-default')}
      onClick={onClick}
    >
      <div className='cell-item'>
        <div className={classNames('cell-item-title', className?.classNameCellTitle || '')}>
          {title}
        </div>
        <div className={classNames('cell-item-content', className?.classNameCellContent || '')}>
          {content}
        </div>
        {display && <div className='cell-item-display'>{display}</div>}
      </div>
      {showIcon && (
        <img className='cell-icon' src='https://s.huiyunban.cn/1829066686079176704.png' alt='' />
      )}
    </div>
  )
}

export default Cell
