import { cloneDeep, merge } from 'lodash-es'
import { useMemo } from 'react'

import mapping from '@/components/NewEditForm/mapping'
import { isNullOrUndefined } from '@/utils'

const isType = (val: any) => {
  if (val === null) return 'null'
  if (typeof val !== 'object') return typeof val
  else {
    return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase()
  }
}
const getValueByPath = (data: any, namePath: any): any => {
  const type = isType(namePath)
  if (type === 'string') {
    return data?.[namePath]
  }
  let value = data
  for (const key of namePath) {
    value = value?.[key]
  }
  return value
}
function convertToJSON(data: any): any {
  const result: any = data
  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    if (Array.isArray(key)) {
      let temp = result
      for (let i = 0; i < key.length - 1; i++) {
        const k = key[i]
        temp = temp[k] = temp[k] || {}
      }
      temp[key[key.length - 1]] = data[key]
    }
  }
  return result
}
function setNestedObject(namePath: any, value: any): any {
  if (namePath.length === 0) {
    return value
  }
  const [first, ...rest] = namePath
  return {
    [first]: setNestedObject(rest, value)
  }
}
/**
 * 转换 a,b,c: value 为json格式
 * @param obj namepath: value
 * @returns
 */
function transformFlatToNested(obj: { [key: string]: any }): { [key: string]: any } {
  const result: { [key: string]: any } = {}

  for (const key of Object.keys(obj)) {
    const keys = key.split(',')
    let currentLevel = result

    for (let i = 0; i < keys.length - 1; i++) {
      const subKey = keys[i]
      if (!(subKey in currentLevel)) {
        currentLevel[subKey] = {}
      }
      currentLevel = currentLevel[subKey]
    }

    // 设置最终的值
    currentLevel[keys[keys.length - 1]] = obj[key]
  }

  return result
}
/**
 * 字段转换
 * @param rule 正则 @xxx
 * @returns { injectVariable }
 */
export default function ({ formOption, getExtraDicts }: { formOption: any; getExtraDicts?: any }) {
  /**
   * 前置化转换 - 用于回显
   * @param values
   * @param config
   * @returns newValues
   */
  const toConvertValue = async (values: any, config?: any) => {
    let newValues: any = cloneDeep(values) || {}
    const elementArray: any[] = Array.from(elementMap)
    for (let i = 0; i < elementArray.length; i++) {
      const [key, { convertValue, elementProps }] = elementArray[i]
      const value = key ? getValueByPath(values, key) : undefined
      const _value = (await convertValue?.(value, { ...config, ...elementProps }, values)) || value
      const mergeValue = elementProps?.names ? convertToJSON(_value) : setNestedObject(key, _value)
      console.log('convertValueTo:', mergeValue, merge(newValues, mergeValue))
      newValues = merge(newValues, mergeValue)
    }
    // const formated = await Array.from(elementMap).reduce(async (prev: any, next: any) => {
    //   const [key, { convertValue, elementProps }] = next
    //   const value = key ? getValueByPath(values, key) : undefined
    //   const _value = (await convertValue?.(value, { ...config, ...elementProps }, values)) || value
    //   const mergeValue = elementProps?.names ? convertToJSON(_value) : setNestedObject(key, _value)
    //   console.log('convertValueTo:', mergeValue, merge(prev, mergeValue))
    //   newValues = merge(prev, mergeValue)
    // }, newValues)
    return newValues
  }
  /**
   * 提交时转化 - 用于提交
   * @param values
   * @param config
   * @returns newValues
   */
  const toTransform = (values: any, config?: any) => {
    const newValues = cloneDeep(values) || {}
    const formated = Array.from(elementMap).reduce((prev: any, next: any) => {
      const [key, { transform, elementProps }] = next
      const value = key ? getValueByPath(values, key) : undefined
      const transformValue = transform?.(value, { ...config, ...elementProps }, values)
      const _value = isNullOrUndefined(transformValue) ? value : transformValue
      const mergeValue = elementProps?.names
        ? convertToJSON(_value)
        : transformFlatToNested({ [key]: _value })
      // console.log('transformTo:', key, value)
      return merge(prev, mergeValue)
    }, newValues)
    return formated
  }
  const { elementMap, rulesMap, extraDicts } = useMemo(() => {
    const _elementMap: any = new Map<any, any>()
    const _rulesMap: any = new Map<any, any>()
    let _extraDicts: any[] = []
    const { formInfo = [] } = formOption || {}
    formInfo?.forEach((x: any) => {
      x?.form_list?.forEach((y: any) => {
        const elementType = y?.specific_type || (y?.type as unknown as string)
        const element = mapping[elementType]
        const namePath = y.name || y.formItemProps?.name
        if (element?.convertValue || element?.transform) {
          _elementMap.set(namePath, {
            elementProps: y?.elementProps,
            convertValue: element.convertValue,
            transform: element.transform
          })
        }
        if (element?.rules) {
          _rulesMap.set(namePath, element.rules)
        }
        if (Array.isArray(y?.elementProps?.extraDicts)) {
          _extraDicts = _extraDicts.concat(y?.elementProps?.extraDicts)
        }
      })
    })
    // 额外字典
    if (Array.isArray(_extraDicts) && _extraDicts?.length > 0) {
      getExtraDicts?.(_extraDicts)
    }
    // console.log('_elementMap:', _elementMap)
    return {
      elementMap: _elementMap,
      rulesMap: _rulesMap,
      extraDicts: _extraDicts
    }
  }, [formOption])
  return {
    convertValue: toConvertValue,
    transform: toTransform,
    elementMap,
    rulesMap,
    extraDicts
  }
}
