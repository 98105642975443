// import moment from 'moment'
import dayjs from 'dayjs'
// 状态和对应颜色
export const ClassMap: Record<string, string> = {
  // 审批中
  UNDER_APPROVAL: 'Attendance-bd-pending',
  // 通过
  APPROVED: 'Attendance-bd-passed',
  // 未通过
  NOT_APPROVED: 'Attendance-bd-reject',
  // 退回
  RETURNED: 'Attendance-bd-ended',
  // 撤销
  REVOKED: 'Attendance-bd-ended'
}
export const MapApplyStatus: any = {
  UNDER_APPROVAL: 1,
  APPROVED: 2,
  NOT_APPROVED: 3,
  REVOKED: 4
}
// 日历头
export const WeekDayStr: string[] = ['一', '二', '三', '四', '五', '六', '日']
// 今日
export const TodayStr = dayjs().format('YYYY-MM-DD')
// 本月第一个
export const MonthStartStr = dayjs().startOf('month').format('YYYY-MM-DD')

export enum DATE_TYPE {
  working = 'WORKING_DAY',
  weekend = 'WEEKEND',
  holiday = 'HOLIDAY'
}

/** 底部操作按钮 */
export const enum OPERA_TYPE_MAP {
  /** 上一条 */
  PREV = 'PREV',
  /** 下一条 */
  NEXT = 'NEXT',
  /** 确认考勤 */
  CONFIRM = 'CONFIRM',
  /** 解锁 */
  UNLOCK = 'UNLOCK',
  /** 结账 */
  CHECKOUT = 'CHECKOUT',
  /** 处理异常 */
  HANDLE_ERROR = 'HANDLE_ERROR',
  /** 核 算 */
  ACCOUNT = 'ACCOUNT'
}

// 操作类型 -1解除锁定 0核算 1锁定 2结账
export const NEED_OPERA_TYPE_MAP: Record<string, number> = {
  /** 确认考勤 */
  CONFIRM: 1,
  /** 解锁 */
  UNLOCK: -1,
  /** 结账 */
  CHECKOUT: 2,
  /** 核算 */
  ACCOUNT: 0
}

export const enum STATUS_MAP {
  /** 待锁定 */
  WAITING = 0,
  /** 已锁定 */
  CONFIRM = 1,
  /** 已结账 */
  CHECKOUT = 2
}

/** 通用考勤结果 */
// 类型 1迟到 2早退 3旷工 4请假 5差勤 6缺卡 7外勤缺卡
export const COMMON_ATTENDANCE_RESULT_MAP: {
  [x: string]: {
    className: string
    text?: string
    unit?: string
  }
} = {
  1: {
    className: 'danger'
    // text: '迟到'
    // unit: '分钟'
  },
  2: {
    className: 'danger'
    // text: '早退'
    // unit: '分钟'
  },
  3: {
    className: 'danger'
    // text: '旷工'
  },
  4: {
    className: 'info'
    // text: '请假'
    // unit: '小时'
  },
  5: {
    className: 'info'
    // text: '差勤'
    // unit: '小时'
  },
  6: {
    className: 'danger'
    // text: '缺卡'
    // unit: '小时'
  },
  7: {
    className: 'danger'
    // text: '外勤缺卡'
    // unit: '小时'
  },
  8: {
    className: 'info'
    //  text: '补卡',
    // unit: '次'
  },
  9: {
    className: 'info'
    // text: '加班',
    // unit: '小时'
  },
  10: {
    className: 'gray'
    // text: '出勤',
    // unit: '小时'
  }
}

export const ATTENDANCE_RESULT_TYPE_MAP = {
  /** 团队月考勤 */
  TEAM_MONTHLY: 'TEAM_MONTHLY',
  /** 团队日考勤 */
  TEAM_DAILY: 'TEAM_DAILY',
  /** 考勤日历 */
  STAFF_DAILY: 'STAFF_DAILY',
  /** 团队日考勤详情 */
  TEAM_DAILY_DETAIL: 'TEAM_DAILY_DETAIL'
}

export const TIPS_MAP: { [x: string]: string } = {
  /** 无异常锁定 */
  [OPERA_TYPE_MAP.CONFIRM]: '锁定后考勤将无法更改',
  /** 无异常锁定 */
  [`${OPERA_TYPE_MAP.CONFIRM}_20`]: '锁定后考勤将无法更改',
  /** 异常锁定 */
  [`${OPERA_TYPE_MAP.CONFIRM}_30`]: '当前考勤异常，锁定后考勤将无法更改',
  /** 确认结账 */
  [OPERA_TYPE_MAP.CHECKOUT]: '结账后考勤将无法更改，且无法再次解锁',
  /** 确认解锁 */
  [OPERA_TYPE_MAP.UNLOCK]: '解锁后，考勤将可修改',
  /** 核 算 */
  [OPERA_TYPE_MAP.ACCOUNT]: '您确定要考勤核算吗？'
}
