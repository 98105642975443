import { isEmpty, omit, pick } from 'lodash-es'
import { useState } from 'react'

import http from '@/utils/http'

// 团队当前考勤状态
export const getCurrentTeamAttendance = (data: any): any =>
  http.post('/attendance/employee/team/calendar_status', data)

export default () => {
  const [dataMap, setDataMap] = useState<any>({})
  // 获取日历状态
  const fetchDataMap = async (params: any) => {
    if (!params.start_at || !params.end_at) {
      return Promise.reject()
    }
    const { success, data } = await getCurrentTeamAttendance(
      omit(params, ['cur_page', 'page_size', 'paged', 'date_range', 'omit', 'sort', 'year_month'])
    )
    if (success) {
      const _dataMap = data?.reduce((prev: any, next: any) => {
        prev[next.date] = next
        return prev
      }, {})
      setDataMap(_dataMap)
    }
  }
  return { dataMap, fetchDataMap }
}
