import { cloneDeep } from 'lodash-es'

import config from '../config'
import PcElementProps from '../config/pc.element.json'
import PcFormItemProps from '../config/pc.form.item.json'
import PcProps from '../config/pc.props.json'

/**
 * PC端json转换
 */
export default () => {
  /**
   * 错误类型报错
   * @param errorList
   */
  const showError = (errorList?: any[]) => {
    if (errorList?.length) {
      console.error(
        `跨端翻译组件失败如下：（组件不会渲染）\n`,
        errorList?.map((item: any) => item.specific_type || item.type).join('\n')
      )
    }
  }
  /**
   * 判断当前组件类型是否可用
   * @param item 组件配置
   * @returns
   */
  const isRightType = (item: any) => {
    return Object.keys(config).includes(item.specific_type || item.type)
  }
  /**
   * 字段映射 - 支持对象嵌套
   * @param data 源数据
   * @param map 嵌套对象
   * @returns 映射后的值
   */
  const getMapFromObject = (data: any, map: object) => {
    const obj: Record<string, any> = {}
    Object.entries(map).forEach(([k, v]: [string, any]) => {
      switch (Object.prototype.toString.call(data).slice(8, -1).toLocaleLowerCase()) {
        case 'array':
          obj[k] = data.map((dataItem: any) => {
            for (const key in v) {
              const keys = key.split('|')
              const value = v[key]
              keys?.some((_key: any) => {
                if (dataItem[_key]) {
                  dataItem[value] = dataItem[_key]
                }
                return dataItem[_key]
              })
            }
            return dataItem
          })
          break
        case 'object':
          Object.entries(data).forEach(([dataKey, dataValue]: [string, any]) => {
            obj[dataValue] = data[dataKey]
          })
          break
        default:
          obj[k] = data
      }
    })
    console.log('映射后：', obj)
    return obj
  }
  function mapToMap(array: any[]): any[] {
    let _array = cloneDeep(array)
    const errorList: any[] = []
    _array = _array.map((item: any) => {
      const form_list = item.form_list
        .filter((i: any) => isRightType(i))
        .map((i: any) => propsTransform(i))
      if (form_list.length !== item.form_list.length) {
        const _errorList = item.form_list.filter((i: any) => !isRightType(i))
        errorList.push(..._errorList)
      }
      return {
        ...item,
        form_list
      }
    })
    showError(errorList)
    return _array
  }
  const convertTo = (data: any) => {
    return data
  }
  const transform = (data: any) => {
    let json = cloneDeep(data)
    const formInfo = json?.formOption?.formInfo || json?.formInfo || json
    const _formInfo = mapToMap(formInfo)
    if (json?.formOption?.formInfo) {
      json.formOption.formInfo = _formInfo
    } else if (json?.formInfo) {
      json.formInfo = _formInfo
    } else {
      json = _formInfo
    }
    console.log('翻译后json:', json)
    return json
  }
  const propsTransform = (item: any) => {
    const formType = config[item.specific_type || item.type]
    const propsMapping = PcProps[formType]
    const formItemPropsArray = PcFormItemProps.formItemProps.filter(
      (propName: string) => !!item[propName]
    )
    const elementPropsArray = PcElementProps.elementProps.filter(
      (propName: string) => !!item[propName]
    )
    const props = cloneDeep(item)
    props.type = formType
    formItemPropsArray.forEach((key: string) => {
      if (!props.formItemProps) {
        props.formItemProps = {}
      }
      props.formItemProps[key] = props?.[key]
      delete props?.[key]
    })
    elementPropsArray.forEach((key: string) => {
      if (!props.elementProps) {
        props.elementProps = {}
      }
      props.elementProps[key] = props?.[key]
      delete props?.[key]
    })
    if (propsMapping) {
      Object.entries(propsMapping).forEach(([key, value]: [string, any]) => {
        if (props.elementProps?.[key]) {
          const dataType = Object.prototype.toString.call(value).slice(8, -1).toLocaleLowerCase()
          switch (dataType) {
            case 'string':
              props.elementProps[value] = props.elementProps?.[key]
              delete props.elementProps?.[key]
              break
            case 'object':
              Object.entries(propsMapping).forEach(getMapFromObject)
              props.elementProps = {
                ...props.elementProps,
                ...getMapFromObject(props.elementProps?.[key], value)
              }
              delete props.elementProps?.[key]
              break
          }
        }
      })
    }
    // console.log('翻译前:', item, '翻译后:', props)
    return props
  }
  return { convertTo, transform }
}
