import { Popup } from 'antd-mobile'
import { AddOutline, SearchOutline } from 'antd-mobile-icons'

import { HSearchBar } from '@/components/NewEditForm/UILibrary/HYBVant'
import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import { toPx } from '@/utils'
import './index.less'

export default ({ value, onConfirm }: { value?: any; onConfirm: (value: any) => void }) => {
  const { open, modalData, setModalData, onOpen, onOk, onCancel } = usePopup({
    afterOk: () => {
      onConfirm?.(modalData.keyword)
      return Promise.resolve()
    }
  })
  const onClear = () => {
    setModalData({ keyword: undefined })
    onConfirm?.(undefined)
  }
  return (
    <>
      <div className='unified-filter-icon' onClick={() => (value ? onClear() : onOpen())}>
        {value ? (
          <AddOutline fontSize={38} className='icon' color='#ff4d4f' />
        ) : (
          <SearchOutline fontSize={38} className='icon' />
        )}
      </div>

      <Popup
        visible={open}
        closeOnMaskClick
        position='top'
        onClose={() => onCancel?.()}
        className='filter-search-popup'
        bodyStyle={{ top: `${toPx(88)}px` }}
      >
        <div className='filter-search'>
          <HSearchBar
            defaultValue={value}
            onChange={(val: string) => setModalData({ keyword: val })}
            placeholder='搜索关键词'
          />
          <div className='filter-search-btn' onClick={() => onOk()}>
            搜索
          </div>
        </div>
      </Popup>
    </>
  )
}
