import { Dialog } from 'antd-mobile'
import { PropsWithChildren, ReactElement } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import logger from '@/utils/logger'

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  logger(error)
  return (
    <Dialog
      title=''
      content='抱歉，发生了一些小问题'
      closeOnAction
      actions={[{ key: 'retry', text: '重试' }]}
      onAction={resetErrorBoundary}
    />
  )
}
/**
 * 错误边界处理
 * @param param0
 * @returns
 */
export default function App({ children }: PropsWithChildren): ReactElement {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}
