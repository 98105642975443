import { Radio } from 'antd-mobile'
import React from 'react'

import { isNotEmptyArray } from '@/utils'

import HCell from '../HCell'

import { publicProps } from '../utils'

import './index.less'

const HRadioGroup: React.FC<any> = (elementProps: any) => {
  const { dataList = [], options, value } = elementProps
  const list = options || dataList
  const _value: string = list?.find((x: any) => x.value === value)?.label
  return (
    <div className='h-radio-group'>
      {elementProps?.readed ? (
        <HCell value={_value} readed={elementProps?.readed} />
      ) : (
        <Radio.Group direction='horizontal' {...publicProps(elementProps, 'radio-group')}>
          {isNotEmptyArray(dataList) &&
            list?.map((x: any) => (
              <Radio key={x.value} value={x.value}>
                {x.label}
              </Radio>
            ))}
        </Radio.Group>
      )}
    </div>
  )
}

export default HRadioGroup
