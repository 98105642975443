import { useSetState } from 'ahooks'
import { Input } from 'antd-mobile'
import { isEmpty, isEqual } from 'lodash-es'
import { useEffect, useRef } from 'react'

import { publicProps } from '../../HYBVant/utils'
import './index.less'

// 通用数字框
function InputNumberRange(props: any) {
  const [state, setState] = useSetState<{
    value1: any
    value2: any
    // @ts-ignore
  }>({ value1: props?.value?.[0], value2: props?.value?.[1] })
  const stateRef = useRef<any>()
  stateRef.current = state
  const chooseChange = (_value: any) => {
    props.onInput?.(_value)
  }
  const handleChange = (_value: any, index: number) => {
    console.log('修改：', _value, index)
    // @ts-ignore
    setState({ [`value${index + 1}`]: _value })
    if (
      index === 0 &&
      !Number.isNaN(_value) &&
      !Number.isNaN(stateRef.current.value2) &&
      !isEmpty(stateRef.current.value2)
    ) {
      const newValue = [_value, stateRef.current.value2]
      chooseChange(newValue)
    } else if (
      index === 1 &&
      !Number.isNaN(_value) &&
      !Number.isNaN(stateRef.current.value1) &&
      !isEmpty(stateRef.current.value1)
    ) {
      const newValue = [stateRef.current.value1, _value]
      chooseChange(newValue)
    } else {
      chooseChange(undefined)
    }
  }
  useEffect(() => {
    if (
      Array.isArray(props.value) &&
      props.value.length === 2 &&
      !isEqual(props.value, [stateRef.current.value1, stateRef.current.value2])
    ) {
      setState({ value1: props.value[0], value2: props.value[1] })
    }
  }, [props.value])
  useEffect(() => {
    if (Array.isArray(props.value) && props.value.length === 2) {
      setState({ value1: props.value[0], value2: props.value[1] })
    } else if (Array.isArray(props.elementProps?.value) && props.elementProps.value.length === 2) {
      setState({ value1: props.elementProps.value[0], value2: props.elementProps.value[1] })
    }
  }, [])

  console.log('input-number-range:::', props.value)
  // console.log(_props, { ...publicProps(_props, 'input-number') }, 'yyyy')
  return (
    <>
      <Input
        {...publicProps(props, 'input')}
        placeholder='请输入'
        type='number'
        value={state.value1}
        onInput={(e: any) => handleChange(e.detail.value, 0)}
      />
      <div style={{ padding: '0 20px' }}>{props?.elementProps?.middle || ' - '}</div>
      <Input
        {...publicProps(props, 'input')}
        placeholder='请输入'
        type='number'
        value={state.value2}
        onInput={(e: any) => handleChange(e.detail.value, 1)}
      />
    </>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
InputNumberRange.convertValue = (value: any, config?: any, values?: any) => {
  if (values && Array.isArray(config?.names) && config?.names?.length === 2) {
    return {
      [config.names[0]]: [values?.[config.names?.[0]], values?.[config.names?.[1]]]
    }
  }
  return value
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
InputNumberRange.transform = (value: any, config?: any, values?: any) => {
  if (
    Array.isArray(value) &&
    value?.length === 2 &&
    Array.isArray(config?.names) &&
    config?.names?.length === 2
  ) {
    // console.log('[config?.names[0]]:', values, [config?.names[0]], values[config?.names[0]])
    // 单选
    return {
      [config?.names[0]]: Number(value?.[0]),
      [config?.names[1]]: Number(value?.[1])
    }
  }
  return value
}

export default InputNumberRange
