import { useSetState } from 'ahooks'
import { Popup, SafeArea, CalendarPickerView, PickerView } from 'antd-mobile'

import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'

import HCell from '../../HYBVant/HCell'

import { SixMonth } from '../CalendarCascader/constant'

import './index.less'

interface CalendarDatePickerState {
  visible: boolean
}

interface CalendarDatePickerProps {
  [index: string]: any
}

const CalendarDatePicker: React.FC<CalendarDatePickerProps> = (props) => {
  const { sixMonthsAgo, sixMonthsfFuture } = SixMonth()
  const { title = '开始日期', value, onConfirm, disabled = false, readed } = props
  const [{ visible }, setState] = useSetState<CalendarDatePickerState>({
    visible: false
  })
  const calendarRef = useRef<any>(null)

  const confirm = (date: string) => {
    onConfirm?.(date)
    setState({ visible: false })
  }

  useEffect(() => {
    setTimeout(() => {
      const _value: string = value || dayjs().format('YYYY-MM-DD')
      const _list = _value?.split('-')
      calendarRef.current?.jumpTo({ year: Number(_list?.[0]), month: Number(_list?.[1]) })
    }, 10)
  }, [calendarRef, visible, value])

  const bool: boolean = !props?.disabled && !props?.readed

  return (
    <div className='calender-cascader'>
      <HCell
        value={value || <span style={{ color: '#BFBFC2', fontWeight: 'normal' }}>请选择</span>}
        showPlaceholder={!value}
        onClick={() => bool && setState({ visible: true })}
        disabled={disabled}
        readed={readed}
      />
      <Popup
        visible={visible}
        onClose={() => setState({ visible: false })}
        onMaskClick={() => setState({ visible: false })}
        className='sheet-popup'
      >
        <div className='calender-cascader-form'>
          <div className='calender-cascader-form-action'>
            <div
              className='calender-cascader-form-action-i'
              onClick={() => setState({ visible: false })}
            >
              取消
            </div>
            <div className='calender-cascader-form-action-ii'>{title}</div>
            <div className='calender-cascader-form-action-iii'>{''}</div>
          </div>
          <div className='calender-cascader-form-edit'>
            <CalendarPickerView
              ref={calendarRef}
              defaultValue={value ? dayjs(value) : dayjs()}
              allowClear={false}
              min={sixMonthsAgo}
              max={sixMonthsfFuture}
              onChange={(date: any) => confirm(dayjs(date).format('YYYY-MM-DD'))}
            />
          </div>
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </div>
  )
}

export default CalendarDatePicker
