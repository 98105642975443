import { useSetState } from 'ahooks'
import { Button } from 'antd-mobile'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useEffect } from 'react'

import { SvgIcon } from '@/components'
import authStore from '@/store/global/authStore'
import { isNotEmptyArray } from '@/utils'

import { OPERA_TYPE_MAP, STATUS_MAP } from '../../const'

import './index.less'

const PAGE_SIZE = 10
const NO_TIPS = '没有了'

/** 底部通用按钮 */
const OperaBtnBottom = (
  {
    onClick,
    status,
    index,
    filter,
    initItem,
    queryList,
    codes
  }: {
    onClick?: any
    status?: number
    index?: number
    filter?: any
    initItem?: any
    queryList: any
    codes?: string[]
  } = {} as any
) => {
  const permission = useLocalStore(() => authStore)
  const [{ dataSource, total, currentPage, currentIndex }, setState] = useSetState({
    dataSource: [],
    total: 0,
    currentPage: 0,
    currentIndex: index ? Number(index) : 0
  })

  const isPrevDisable = currentIndex === 0
  const isNextDisable = currentIndex === total - 1

  const queryData = (cur_page: number = 0, callback?: any) => {
    if (typeof queryList !== 'function') {
      return
    }
    let filterData = {}
    try {
      filterData = filter ? JSON.parse(decodeURIComponent(filter)) : {}
    } catch {
      console.warn('filter waring')
    }
    return queryList({
      ...filterData,
      page_size: PAGE_SIZE,
      cur_page
    }).then(({ success, data, total: dataTotal }: any = {}) => {
      if (success) {
        setState({
          total: dataTotal,
          dataSource: data
        })
        typeof callback === 'function' && callback(data)
      }
    })
  }

  const handlePage = (nextIndex: number, callback: any) => {
    const page = Math.floor(nextIndex / PAGE_SIZE)
    console.log(`第${page + 1}页/第${(nextIndex + 1) % PAGE_SIZE}条数据,总第${nextIndex + 1}条数据`)
    setState({
      currentPage: page,
      currentIndex: nextIndex
    })
    if (!isNotEmptyArray(dataSource) || page !== currentPage) {
      return queryData(page, callback)
    }
    typeof callback === 'function' && callback(dataSource)
  }

  const handleClick = (type: OPERA_TYPE_MAP) => {
    console.log(`click--${type}-button`)
    // 上一步下一步
    if ([OPERA_TYPE_MAP.PREV, OPERA_TYPE_MAP.NEXT].includes(type)) {
      const nextIndex = currentIndex + (type === OPERA_TYPE_MAP.PREV ? -1 : 1)
      handlePage(nextIndex, (data: any) => {
        onClick(type, {
          dataSource: data?.[nextIndex % PAGE_SIZE] || {},
          index: nextIndex
        })
      })
      return
    }
    typeof onClick === 'function' &&
      onClick(type, {
        dataSource: dataSource?.[currentIndex % PAGE_SIZE] || {}
      })
  }

  useEffect(() => {
    // 按PAGE_SIZE条请求
    queryData(index ? Math.floor(Number(index) / PAGE_SIZE) : 0, (data: any[]) => {
      typeof initItem === 'function' && initItem(data?.[Number(index) % PAGE_SIZE])
    })
  }, [])

  return (
    <div className='opera-btn-list-wrap'>
      <div
        className={`opera-btn nav-btn ${isPrevDisable ? 'disable' : ''}`}
        onClick={() => !isPrevDisable && handleClick(OPERA_TYPE_MAP.PREV)}
      >
        <span className='icon-wrap'>
          <SvgIcon className='icon' name='up-arrow' />
        </span>
        <span className='tips'>{isPrevDisable ? NO_TIPS : '上一条'}</span>
      </div>
      <div
        className={`opera-btn nav-btn ${isNextDisable ? 'disable' : ''}`}
        onClick={() => !isNextDisable && handleClick(OPERA_TYPE_MAP.NEXT)}
      >
        <span className='icon-wrap'>
          <SvgIcon className='icon' name='down-arrow' />
        </span>
        <span className='tips'>{isNextDisable ? NO_TIPS : '下一条'}</span>
      </div>
      {status === STATUS_MAP.WAITING && (
        <>
          {permission.getCodeFun?.(codes?.[3] || '') && (
            <Button
              className='opera-btn default-btn'
              fill='outline'
              onClick={() => handleClick(OPERA_TYPE_MAP.ACCOUNT)}
            >
              核 算
            </Button>
          )}
          {permission.getCodeFun?.(codes?.[0] || '') && (
            <Button
              className='opera-btn default-btn'
              color='primary'
              onClick={() => handleClick(OPERA_TYPE_MAP.CONFIRM)}
            >
              锁 定
            </Button>
          )}
        </>
      )}
      {status === STATUS_MAP.CONFIRM && (
        <>
          {permission.getCodeFun?.(codes?.[1] || '') && (
            <Button
              className='opera-btn default-btn'
              // fill='none'
              onClick={() => handleClick(OPERA_TYPE_MAP.UNLOCK)}
            >
              解 锁
            </Button>
          )}
          {permission.getCodeFun?.(codes?.[2] || '') && (
            <Button
              className='opera-btn default-btn'
              color='danger'
              fill='outline'
              onClick={() => handleClick(OPERA_TYPE_MAP.CHECKOUT)}
            >
              结 账
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default observer(OperaBtnBottom)
