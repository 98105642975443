import { useSetState } from 'ahooks'
import { Image, ImageViewer } from 'antd-mobile'
import { toJS } from 'mobx'
import { useLocalStore, observer } from 'mobx-react-lite'
import React, { useEffect, useState, Fragment } from 'react'

import dictStore from '@/store/global/dictStore'
import { getUserData } from '@/utils'

import { flowPreviewApi, flowInstNodeApi } from './service'

import './index.less'

const FlowPath: React.FC<any> = ({ flowPreView, flowDetail, isPreview }) => {
  const useRData = getUserData()
  const renderAvatar = (x: any) => {
    if (x?.is_discard && x?.approval_status !== 'REVERTED') return ''
    if (x?.node_type === 'start') return 'https://s.huiyunban.cn/1764485845911343104.png'
    if (x?.approval_status === 'UNDER_APPROVAL' && x?.add_node_infos?.length) {
      return 'https://s.huiyunban.cn/1769555320272588800.png'
    }
    if (x?.approval_status === 'UNDER_APPROVAL') {
      return 'https://s.huiyunban.cn/1764541484658331648.png'
    }
    if (x?.approval_status === 'APPROVED' && x?.is_add_sign) {
      return 'https://s.huiyunban.cn/1764541329418752000.png'
    }
    if (x?.approval_status === 'APPROVED' && x?.is_transfer) {
      return 'https://s.huiyunban.cn/1764541329418752000.png'
    }
    if (x?.approval_status === 'APPROVED') return 'https://s.huiyunban.cn/1764485845911343104.png'
    if (x?.approval_status === 'NOT_ARPROVED') {
      return 'https://s.huiyunban.cn/1768555548472512512.png'
    }
    if (x?.approval_status === 'TRANSFERED') return 'https://s.huiyunban.cn/1769555320272588800.png'
    if (x?.approval_status === 'REVERTED') return 'https://s.huiyunban.cn/1769633373178826752.png'
    if (x?.approval_status === 'DIRECT_NOT_APPROVED') {
      return 'https://s.huiyunban.cn/1768555548472512512.png'
    }
    return ''
  }

  const mapStatus: Record<string, string> = {
    APPROVED: '已同意',
    NOT_ARPROVED: '已拒绝',
    DIRECT_NOT_APPROVED: '直接拒绝',
    DIRECT_APPROVED: '直接同意'
  }

  const mapNodeTypeColor: Record<string, string> = {
    start: 'linear-gradient(180deg, #4C76F4 0%, #3862E7 100%)',
    approver: 'linear-gradient(180deg, #62D5BC 0%, #56BDA7 100%)',
    notifier: 'linear-gradient(180deg, #8A5CEE 0%, #7336F4 100%)'
  }

  const store = useLocalStore(() => dictStore)

  const approvalMode: any[] = store.getOptions('APPROVAL_METHOD') || []
  const [preview, setPreview] = useState<any>([])
  const [flow, setFlow] = useState<any>({})
  const [{ visible, imgurl }, setState] = useSetState({
    visible: false,
    imgurl: ''
  })

  const fetchflowPreview = async () => {
    // const { data, code } = await flowPreviewApi(flowPreView)
    return await flowPreviewApi(flowPreView)
  }

  const fetchFlowInstNode = async () => {
    const { data } = await flowInstNodeApi(flowDetail?.workflow_inst_id)
    setFlow(data)
  }

  useEffect(() => {
    if (
      flowPreView?.apply_category_code === 'ASSIST' &&
      flowPreView?.application_mode === 'AGENT_INITIATED'
    ) {
      return
    }
    // isPreview && flowPreView?.apply_category_code
    if (isPreview && flowPreView?.apply_category_code) {
      fetchflowPreview()
        .then((res: any) => {
          const { code, data } = res
          if (code === 2000) setPreview(data)
        })
        .catch(() => {
          setPreview([])
        })
    }
  }, [flowPreView, flowDetail, isPreview])

  useEffect(() => {
    !isPreview &&
      flowDetail?.workflow_inst_id &&
      flowDetail?.workflow_inst_id !== '0' &&
      fetchFlowInstNode()
  }, [isPreview, flowDetail])

  const renderPreViewNodeName = (item: any) => {
    const { node_type = '', approval_mode = '', processor_list = [], approval_type } = item
    if (node_type === 'start') return '我'
    if (approval_type === 'AUTOMATIC_APPROVAL') return '自动通过'
    if (approval_type === 'AUTOMATIC_REJECTION') return '自动拒绝'
    if (approval_type === 'THIRD_PARTY_APPROVAL') return '第三方审批'
    if (node_type === 'approver') {
      return (
        <span>
          {approvalMode.find((z) => z.value === approval_mode)?.name}：
          {processor_list.map((x) => x.processor_name)?.join('、')}
        </span>
      )
    }
    if (node_type === 'notifier') {
      return <span>{processor_list.map((x) => x.processor_name)?.join('、')}</span>
    }
  }

  const renderEndColor = () => {
    if (flow?.approval_status === 'UNDER_APPROVAL') return '#D8D8D8'
    if (flow?.approval_status === 'APPROVED' || flow?.approval_status === 'DIRECT_APPROVED') {
      return '#52C41A'
    }
    if (
      flow?.approval_status === 'NOT_ARPROVED' ||
      flow?.approval_status === 'DIRECT_NOT_APPROVED'
    ) {
      return '#FF4D4F'
    }
    return '#D8D8D8'
  }

  const renderAddNode = (add_node_infos: any[]) => {
    return (
      <>
        {add_node_infos?.length > 0 &&
          add_node_infos?.map((x, index) => {
            const length: number = x?.processor?.length || 0
            const _tip = (
              <>
                （加签：{x?.processor?.[0]?.name || ''}
                {length > 1 ? <div style={{ color: '#0256FF' }}>{`等${length}人`}</div> : ''}
                审批）
              </>
            )
            return (
              <div key={index} style={{ marginBottom: 5 }}>
                <div>
                  {_tip}加签时间：{x?.handle_time}
                </div>
                <div className='path-item-ii-s'>审批意见：{x?.comment || ''}</div>
                {x?.attachments?.map((y) => (
                  <div key={y?.image_url} className='path-item-ii-a'>
                    <Image
                      onClick={() => setState({ visible: true, imgurl: y.image_url })}
                      className='path-item-ii-a-img'
                      src={y?.image_url}
                    />
                    <div className='path-item-ii-a-file'>
                      <div className='path-item-ii-a-file-name'>{y?.name || ''}</div>
                      <div>{(parseFloat(y?.size) / 1024)?.toFixed(2)}kb</div>
                    </div>
                  </div>
                ))}
              </div>
            )
          })}
      </>
    )
  }

  const renderNodeName = (item: any) => {
    const {
      node_type = '',
      processor_list = [],
      approval_status = '',
      created_at,
      transfer_info = {},
      handle_time,
      revert_processor_name,
      revert2_node_name,
      revert_comment,
      revert_attachments,
      add_node_infos,
      approval_type,
      revert_time
    } = item
    let name = ''
    let tip = ''
    let time = ''
    if (node_type === 'start') {
      name = processor_list?.[0]?.processor_name
      tip = '（已提交）'
      time = `提交时间： ${created_at}`
    }

    if (approval_type === 'AUTOMATIC_APPROVAL') {
      tip = '自动通过'
    }
    if (approval_type === 'AUTOMATIC_REJECTION') {
      tip = '自动拒绝'
    }
    if (approval_type === 'THIRD_PARTY_APPROVAL') {
      tip = '第三方审批'
    }
    if (
      (approval_status === 'APPROVED' ||
        approval_status === 'NOT_ARPROVED' ||
        approval_status === 'DIRECT_NOT_APPROVED' ||
        approval_status === 'DIRECT_APPROVED') &&
      approval_type === 'MANUAL_APPROVAL'
      // approval_mode === 'OR_SIGN'
    ) {
      const renderTitle = (x) => {
        name = x?.processor_name
        tip = `（${mapStatus?.[approval_status] || ''}）`
        time = `审批时间： ${x?.approval_time || ''}`
        if (!x?.is_approved) return name
        return <>{`${name}${tip}${time}`}</>
      }

      const { extend_infos, direct_handle_processor } = flow
      const renderDirectName = (extend_info: any) => {
        const _name: string = extend_info?.direct_handle_processor_name
        const _time: string = `审批时间：${extend_info?.approval_time || ''}`
        const _tip: string = `（${mapStatus?.[approval_status]}）` || ''
        return `${_name}${_time}${_tip}`
      }
      // const _name = extend_info?.direct_handle_processor_name
      // const _time = `审批时间：${extend_info?.approval_time || ''}`
      // const _tip = `（${mapStatus?.[approval_status]}）` || ''

      const _processor_list: any[] =
        processor_list?.filter((x) => x.processor_id !== direct_handle_processor) || []

      return (
        <div>
          {(approval_status === 'DIRECT_APPROVED' || approval_status === 'DIRECT_NOT_APPROVED') && (
            <>
              {extend_infos
                ?.filter((y: any) => y.direct_handle_result_status !== 'REVOKED')
                ?.map((x: any) => (
                  <Fragment key={x.approval_time}>
                    <div>{renderDirectName(x)}</div>
                    <div className='path-item-ii-s van-multi-ellipsis--l2'>
                      审批意见：{x?.comment || ''}
                    </div>

                    {x?.attachments111?.map((x_: any, i: number) => (
                      <div className='path-item-ii-a' key={`path-item-ii-a${i}`}>
                        <Image
                          onClick={() => setState({ visible: true, imgurl: x_.image_url })}
                          className='path-item-ii-a-img'
                          src={x_?.image_url}
                        />
                        <div className='path-item-ii-a-file'>
                          <div className='path-item-ii-a-file-name'>{x_?.name}</div>
                          <div>
                            {(parseFloat(x_?.size) / 1024)?.toFixed(2)}kb{parseFloat(x_?.size)}-
                            {parseFloat(x_?.size) / 1024}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ))}
            </>
          )}

          {renderAddNode(add_node_infos)}

          {_processor_list.map((x) => (
            <Fragment key={x?.id}>
              <div>{renderTitle(x)}</div>
              {x?.is_approved > 0 && (
                <div className='path-item-ii-s'>审批意见：{x?.approval_comment}</div>
              )}
              {x?.is_approved > 0 &&
                x?.attachments?.map((y) => (
                  <div key={y.image_url} className='path-item-ii-a'>
                    <Image
                      onClick={() => setState({ visible: true, imgurl: y.image_url })}
                      className='path-item-ii-a-img'
                      src={y.image_url}
                    />
                    <div className='path-item-ii-a-file'>
                      <div className='path-item-ii-a-file-name'>{y?.name}</div>
                      <div>{(parseFloat(y?.size) / 1024)?.toFixed(2)}kb</div>
                    </div>
                  </div>
                ))}
            </Fragment>
          ))}
        </div>
      )
    }
    // if (
    //   (approval_status === 'APPROVED' ||
    //     approval_status === 'NOT_ARPROVED' ||
    //     approval_status === 'DIRECT_NOT_APPROVED' ||
    //     approval_status === 'DIRECT_APPROVED') &&
    //   approval_mode === 'AND_SIGN'
    // ) {
    //   const renderTitle = (x) => {
    //     name = x?.processor_name
    //     tip = `（${mapStatus?.[approval_status] || ''}）`
    //     time = `审批时间： ${x?.approval_time || ''}`
    //     if (!x?.is_approved) return name
    //     return <>{`${name}${tip}${time}`}</>
    //   }

    //   const _display_processor_list: Array<any> =
    //     approval_status === 'NOT_ARPROVED'
    //       ? processor_list.filter((x) => x?.is_approved)
    //       : processor_list

    //   return (
    //     <div>
    //       {_display_processor_list?.map((x) => (
    //         <Fragment key={x?.parent_id}>
    //           {/* {x?.is_approved > 0 && <div>{`${x?.processor_name}${_tip} 审批时间： ${x?.approval_time || ''}`}</div>} */}
    //           <div>{renderTitle(x)}</div>
    //           {x?.is_approved > 0 && (
    //             <div className='path-item-ii-s'>审批意见：{x?.approval_comment || ''}</div>
    //           )}
    //           {x?.is_approved > 0 &&
    //             x?.attachments?.map((y) => (
    //               <div key={y?.image_url} className='path-item-ii-a'>
    //                 <Image className='path-item-ii-a-img' src={y?.image_url} />
    //                 <div className='path-item-ii-a-file'>
    //                   <div className='path-item-ii-a-file-name'>{y?.name || ''}</div>
    //                   <div>{parseFloat(y?.size / 1024)?.toFixed(2)}kb</div>
    //                 </div>
    //               </div>
    //             ))}
    //         </Fragment>
    //       ))}
    //     </div>
    //   )
    // }

    if (approval_status === 'UNDER_APPROVAL') {
      const renderTitle = (x: any) => {
        if (x?.is_approved) return <>{`（已同意） 审批时间： ${x?.approval_time || ''}`}</>
        if (!x?.is_approved) {
          return (
            <>
              审批中，已等待
              <span style={{ color: '#0256FF' }}>
                {(x?.wait_time !== null && String(x?.wait_time)) || ''}
              </span>
              小时
            </>
          )
        }
      }

      return (
        <div>
          {/* <>
            {add_node_infos?.length > 0 &&
              add_node_infos?.map((x, index) => {
                const length: number = x?.processor?.length || 0
                const _tip = (
                  <>
                    （加签：{x?.processor?.[0]?.name || ''}
                    {length > 1 ? <span style={{ color: '#0256FF' }}>{`等${length}人`}</span> : ''}
                    审批）
                  </>
                )
                return (
                  <div key={index} style={{ marginBottom: 5 }}>
                    <div>
                      {_tip}加签时间：{x?.handle_time}
                    </div>
                    <div className='path-item-ii-s'>审批意见：{x?.comment || ''}</div>
                    {x?.attachments?.map((y) => (
                      <div key={y?.image_url} className='path-item-ii-a'>
                        <Image className='path-item-ii-a-img' src={y?.image_url} />
                        <div className='path-item-ii-a-file'>
                          <div className='path-item-ii-a-file-name'>{y?.name || ''}</div>
                          <div>{parseFloat(y?.size / 1024)?.toFixed(2)}kb</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              })}
          </> */}

          {renderAddNode(add_node_infos)}

          {processor_list?.map((x) => (
            <Fragment key={x?.parent_id}>
              <div style={{ marginBottom: 5 }}>
                {x?.processor_name}
                {renderTitle(x)}
              </div>
              {!!x?.is_approved && (
                <div className='path-item-ii-s'>审批意见：{x?.approval_comment || ''}</div>
              )}
              {!!x?.is_approved &&
                x?.attachments?.map((y) => (
                  <div key={y?.image_url} className='path-item-ii-a'>
                    <Image
                      onClick={() => setState({ visible: true, imgurl: y.image_url })}
                      className='path-item-ii-a-img'
                      src={y?.image_url}
                    />
                    <div className='path-item-ii-a-file'>
                      <div className='path-item-ii-a-file-name'>{x?.name}</div>
                      <div>{(parseFloat(y?.size) / 1024)?.toFixed(2)}kb</div>
                    </div>
                  </div>
                ))}
            </Fragment>
          ))}
        </div>
      )
    }

    if (approval_status === 'TRANSFERED') {
      const _name = processor_list?.find((x) => x?.is_approved)?.processor_name
      const _time = `转签时间：${handle_time || ''}`
      const length: number = transfer_info?.processor?.length || 0
      const _tip = (
        <>
          （转签：{transfer_info?.processor?.[0]?.name || ''}
          {length > 1 ? <div style={{ color: '#0256FF' }}>{`等${length}人`}</div> : ''}审批）
        </>
      )

      const renderTitle = (x: any) => {
        if (x?.is_approved) return <>（{`已同意 审批时间： ${x?.approval_time || ''}`}）</>
        if (!x?.is_approved) return ''
      }

      const _processor_list: any[] =
        processor_list.filter((x) => x?.processor_id !== transfer_info?.handle_processor_id) || []

      return (
        <>
          <div>
            {_name}
            {_tip}
            {_time}
          </div>
          {renderAddNode(add_node_infos)}
          <div className='path-item-ii-s'>审批意见：{transfer_info?.comment || ''}</div>
          {transfer_info?.attachments?.map((x) => (
            <div className='path-item-ii-a'>
              <Image
                onClick={() => setState({ visible: true, imgurl: x?.image_url })}
                className='path-item-ii-a-img'
                src={x?.image_url}
              />
              <div className='path-item-ii-a-file'>
                <div className='path-item-ii-a-file-name'>{x?.name}</div>
                <div>{(parseFloat(x?.size) / 1024)?.toFixed(2)}kb</div>
              </div>
            </div>
          ))}
          <div>
            {_processor_list?.map((x) => (
              <Fragment key={x?.parent_id}>
                <div style={{ marginBottom: 5 }}>
                  {x?.processor_name}
                  {renderTitle(x)}
                </div>
                {!!x?.is_approved && (
                  <div className='path-item-ii-s'>审批意见：{x?.approval_comment || ''}</div>
                )}
                {!!x?.is_approved &&
                  x?.attachments?.map((y) => (
                    <div key={y?.image_url} className='path-item-ii-a'>
                      <Image
                        onClick={() => setState({ visible: true, imgurl: y?.image_url })}
                        className='path-item-ii-a-img'
                        src={y?.image_url}
                      />
                      <div className='path-item-ii-a-file'>
                        <div className='path-item-ii-a-file-name'>{x?.name}</div>
                        <div>{(parseFloat(y?.size) / 1024)?.toFixed(2)}kb</div>
                      </div>
                    </div>
                  ))}
              </Fragment>
            ))}
          </div>
        </>
      )
    }

    if (approval_status === 'REVERTED') {
      const _time = `审批时间：${revert_time || ''}`

      return (
        <>
          <div>
            {revert_processor_name}
            {`（回退至-${revert2_node_name || ''}）`}
            {_time}
          </div>
          {revert_comment && <div className='path-item-ii-s'>审批意见：{revert_comment || ''}</div>}
          {revert_attachments?.map((x) => (
            <div className='path-item-ii-a'>
              <Image
                onClick={() => setState({ visible: true, imgurl: x?.image_url })}
                className='path-item-ii-a-img'
                src={x?.image_url}
              />
              <div className='path-item-ii-a-file'>
                <div className='path-item-ii-a-file-name'>{x?.name}</div>
                <div>{(parseFloat(x?.size) / 1024)?.toFixed(2)}kb</div>
              </div>
            </div>
          ))}
        </>
      )
    }

    // if (approval_status === 'DIRECT_NOT_APPROVED') {

    // }

    if (approval_status === 'IN_PROCESS' || node_type === 'notifier') {
      return (
        <div>
          {processor_list?.map((x) => (
            <div key={x?.parent_id} style={{ marginBottom: 5 }}>
              {x?.processor_name}{' '}
              {approval_status === 'NO_ACTION_REQUIRED' && node_type === 'notifier' && '（已抄送）'}
            </div>
          ))}
        </div>
      )
    }

    if (node_type === 'start' && useRData?.employee_id === processor_list?.[0]?.processor_id) {
      name = '我'
    }
    return `${name}${tip}${time}`
  }

  const renderAvatarName = (item: any) => {
    const { node_type = '', processor_list = [], approval_type } = item
    if (node_type === 'start') return useRData?.user_name.substr(-2)
    if (node_type === 'start' && useRData?.employee_id === processor_list?.[0]?.processor_id) {
      return '我'
    }
    if (approval_type === 'AUTOMATIC_APPROVAL' || approval_type === 'AUTOMATIC_REJECTION') {
      return '自动'
    }
    if (approval_type === 'THIRD_PARTY_APPROVAL') return '三方'
    return processor_list?.[0]?.processor_name?.substr(-2)
  }
  const renderEmptyPreView = () => {
    if (
      flowPreView?.application_mode === 'AGENT_INITIATED' &&
      flowPreView?.employee_ids?.length > 1
    ) {
      return <div className='path-empty'>当前审批流程为申请人流程，提交后可见</div>
    }
    return ''
  }

  const renderDoneMapInfo = (extend_infos: any[], approval_status: string) => {
    return extend_infos?.find((x) => x.direct_handle_result_status === approval_status) || {}
  }

  const renderDoneText = () => {
    const { approval_status = '', extend_infos, updated_at } = flow

    const doneMap = renderDoneMapInfo(extend_infos, approval_status)
    if (
      approval_status === 'REVOKED' &&
      useRData?.employee_id === doneMap?.direct_handle_processor
    ) {
      return `我 （已撤销）撤销时间：${updated_at}`
    }
    if (approval_status === 'REVOKED') {
      return `${doneMap?.direct_handle_processor_name || ''}（已撤销）撤销时间：${updated_at}`
    }
    if (approval_status === 'APPROVED') {
      return '已同意'
    }
    if (approval_status === 'DIRECT_NOT_APPROVED') {
      return `${doneMap?.direct_handle_processor_name} （直接拒绝）`
    }
    if (approval_status === 'DIRECT_APPROVED') {
      return `${doneMap?.direct_handle_processor_name} （直接同意）`
    }
    if (approval_status === 'NOT_ARPROVED') {
      return `${doneMap?.direct_handle_processor_name} （已拒绝）`
    }
    return ''
  }

  console.log(flowPreView, preview, flow, 'flowPreView', toJS(approvalMode), flow, isPreview)

  return (
    <div className='path'>
      {/*
      <div className='path-item'>
        <div className='path-item-i'>
          <div className='path-item-i-avatar'>
            蛮王
            <Image className='path-item-i-avatar-status' src={mapAvatar[1]} />
          </div>
          <div className='path-item-i-detail'>
            <div className='path-item-i-detail-n'>本组织领导</div>
            <div className='path-item-i-detail-d'>黄欣欣（加签：于佳慧）黄欣欣（加签：于佳慧）黄欣欣（加签：于佳慧）黄欣欣（加签：于佳慧）黄欣欣（加签：于佳慧）黄欣欣（加签：于佳慧）黄欣欣（加签：于佳慧）</div>
          </div>
          <div className='path-item-i-time'>2023-12-19 09:00:00</div>
        </div>
        <div className='path-item-ii'>
          <div className='path-item-ii-s'>审批意见：文件不全，在制定时间内上传齐，大大叔</div>
          <div className='path-item-ii-a'>
            <Image
              className='path-item-ii-a-img'
              src='https://s.huiyunban.cn/1764495680585338880.png'
            />
            <div className='path-item-ii-a-file'>
              <div>文件/图片名称.后缀</div>
              <div>82.41KB</div>
            </div>
          </div>
        </div>
        <div className='path-item-line' />
      </div> */}

      {!isPreview &&
        flow?.node_list?.map((x) => (
          <div key={x.id} className='path-item'>
            <div className='path-item-i'>
              <div className='path-item-i-avatar'>
                {renderAvatarName(x)}
                {renderAvatar(x) && (
                  <Image className='path-item-i-avatar-status' src={renderAvatar(x)} />
                )}
              </div>
              <div className='path-item-i-detail'>
                <div className='path-item-i-detail-n'>
                  {x?.node_type === 'start'
                    ? '发起申请'
                    : `${x?.node_name}${x?.is_discard ? '（作废）' : ''}`}
                </div>
                <div className='path-item-i-detail-d'>{renderNodeName(x)}</div>
                <div className='path-item-i-detail-t'>
                  {x?.logs?.map((y: any) => (
                    <div key={y.edit_time}>
                      <div className='path-item-ii-t'>
                        {`${y?.editor}修改表单`} {y?.edit_time}
                      </div>
                      <div
                        key={y.image_url}
                        className='path-item-ii-a'
                        onClick={() => setState({ visible: true, imgurl: y.snapshot_image?.url })}
                      >
                        <Image className='path-item-ii-a-img' src={y.snapshot_image?.url} />
                        <div className='path-item-ii-a-file'>
                          <div className='path-item-ii-a-file-name'>{y?.snapshot_image?.name}</div>
                          <div>{(parseFloat(y?.snapshot_image?.size) / 1024)?.toFixed(2)}kb</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='path-item-i-time'>{x?.approval_time}</div>
            </div>
            <div className='path-item-line' />
          </div>
        ))}

      {isPreview &&
        preview?.map((x) => (
          <div key={x?.id} className='path-item'>
            <div className='path-item-i'>
              <div
                className='path-item-i-avatar'
                style={{ background: mapNodeTypeColor[x?.node_type] }}
              >
                {renderAvatarName(x)}
              </div>
              <div className='path-item-i-detail'>
                <div className='path-item-i-detail-n'>
                  {x?.node_type === 'start' ? '发起申请' : x?.node_name}
                </div>
                <div className='path-item-i-detail-d'>{renderPreViewNodeName(x)}</div>
              </div>
            </div>
            <div className='path-item-ii'></div>
            <div className='path-item-line' />
          </div>
        ))}

      {/* <div className='path-item'>
        <div className='path-item-i'>
          <div className='path-item-i-avatar'>
            蛮王
            <Image className='path-item-i-avatar-status' src={mapAvatar[1]} />
          </div>
          <div className='path-item-i-detail'>
            <div className='path-item-i-detail-n'>本组织领导</div>
            <div className='path-item-i-detail-d'>黄欣欣（加签：于佳慧）</div>
          </div>
          <div className='path-item-i-time'>2023-12-19 09:00:00</div>
        </div>
        <div className='path-item-ii'></div>
      </div> */}
      {isPreview && preview?.length > 0 ? (
        <div className='path-done'>
          <div className='path-done-img' style={{ border: '1px solid #D8D8D8' }}>
            <div className='path-done-img-dot' style={{ background: '#D8D8D8' }} />
          </div>
          <div className='path-done-text'>结束</div>
        </div>
      ) : (
        renderEmptyPreView()
      )}

      {!isPreview && flow?.node_list?.length > 0 && (
        <div className='path-done'>
          <div className='path-done-img' style={{ border: `1px solid ${renderEndColor()}` }}>
            <div className='path-done-img-dot' style={{ background: renderEndColor() }} />
          </div>
          <div className='path-done-text'>结束</div>
        </div>
      )}
      <div className='path-done-text-i'>{renderDoneText()}</div>
      <ImageViewer
        image={imgurl}
        visible={visible}
        onClose={() => setState({ visible: false, imgurl: '' })}
      />
    </div>
  )
}

export default observer(FlowPath)
