import { Button } from 'antd-mobile'
import classNames from 'classnames'
import './index.less'
/**
 * 按钮组
 * @returns FC
 */
const Buttons = ({ dataSource, permission }: { dataSource?: any[]; permission: any }) => {
  const actions = dataSource?.filter((x) => (x.code ? permission?.getCodeFun?.(x.code) : x)) || []
  return (
    actions?.length > 0 && (
      <>
        <div className={classNames('unified-page-btns', { justone: dataSource?.length === 1 })}>
          {dataSource?.map((x, index) => (
            <Button
              {...x}
              className={x.className || 'info'}
              key={`${x.dataIndex}_${index}`}
              onClick={() => x.onClick?.()}
            >
              {x.text}
            </Button>
          ))}
        </div>
        <div className='unified-page-blank' />
      </>
    )
  )
}

export default Buttons
