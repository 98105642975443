import { TTSetting } from './feishu.config'
import drawWatermark from './h5origin/drawWatermark'
import { JSSDK, LocationResponse } from './interface'
import { getFeishuTicket } from './service'
import { callbackToPromise, service, storage } from './utils'

declare const tt: any
/**
 * 飞书api二次封装
 * 参考：https://open.feishu.cn/document/client-docs/h5/
 */
class Feishu implements JSSDK {
  apiAuthStatus: 'pending' | 'done' | 'error' | undefined = 'pending'
  /**
   * 静默登录
   * @param data  { corp_id: corpId, code }
   * @param config ?: { baseURL: string }
   * @returns Promise<IResponseType>
   */
  silenceLogin(data: any, config?: any) {
    return service({
      method: 'post',
      url: '/app/fs/inner/login',
      data: { ...data, app_id: data?.corp_id },
      config
    })
  }
  /**
   * 手机系统权限
   * 文档：https://open.feishu.cn/document/client-docs/gadget/-web-app-api/open-ability/setting/getsetting
   * @param authType 授权类型 location
   * @returns Promise<boolean>
   */
  checkAuth(authType: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        tt.getSetting({
          success: (res: any) => {
            const authSettingType = TTSetting[authType]
            const authSetting = (res.authSetting?.[authSettingType] || false) as boolean
            resolve(authSetting)
          },
          fail: () => reject(new Error('获取系统权限失败'))
        })
      } catch (e) {
        reject(e)
      }
    })
  }
  /**
   * 暂无
   * @param authType 同checkAuth
   * @returns Promise
   */
  authorize(authType: string): Promise<boolean> {
    console.log('authorize.TTSetting[authType]:', TTSetting[authType])
    return callbackToPromise(tt.authorize, { scope: TTSetting[authType] })
  }
  /**
   * 引导打开系统设置钉钉权限
   * @param authType 同checkAuth
   * @returns Promise
   */
  openSystemSetting(param?: any) {
    return callbackToPromise(tt.openSetting, {})
  }
  /**
   * 初始化鉴权接口
   * @param param0 { api: string | string[]; corpId?: string }
   * @returns Promise
   */
  config({ corpId }: { corpId?: string } = {}) {
    if (this.apiAuthStatus === 'done') {
      return Promise.reject(new Error('重复调用'))
    }
    return new Promise((resolve, reject) => {
      const toResolve = (res: any) => {
        this.apiAuthStatus = 'done'
        resolve(res)
      }
      const toReject = (err: any) => {
        this.apiAuthStatus = 'error'
        reject(err)
      }
      const app_id = corpId || storage.get('corp_id')
      console.log('config.app_id', app_id)
      getFeishuTicket({ app_id })
        .then((res: any) => {
          console.log('getDingTicket:', res)
          if (res.success) {
            const jsApiList = [
              'openSetting',
              'getSetting',
              'authorize',
              'scanCode',
              'makePhoneCall',
              'chooseImage',
              'getImageInfo',
              'openLocation',
              'chooseLocation',
              'getLocation',
              'startLocationUpdate',
              'stopLocationUpdate',
              'onLocationUpdate',
              'offLocationUpdate'
            ]
            const configParam = {
              appId: res.data.app_id, // 必填，微应用ID
              timestamp: res.data.timestamp - 0, // 必填，生成签名的时间戳
              nonceStr: res.data.nonce_str, // 必填，自定义固定字符串。
              signature: res.data.signature, // 必填，签名
              jsApiList
            }
            console.log('getDingTicket.param:', configParam)
            // @ts-ignore
            callbackToPromise(window.h5sdk.config, configParam, ['onSuccess', 'onFail'])
              .then((res: any) => {
                console.log('鉴权结果：', res)
                toResolve(res)
              })
              .catch((err: any) => {
                console.log('鉴权错误：', err)
                toReject(err)
              })
          }
        })
        .catch((e: any) => {
          console.log('请求出错:', e)
          toReject(e)
        })
    })
  }
  getAuthCodeByOldVersion({ corpId }: { corpId: string }): Promise<{ code: string }> {
    return new Promise((resolve, reject) => {
      if (tt.requestAuthCode) {
        tt.requestAuthCode({
          appId: corpId,
          success: (info: any) => {
            resolve(info.code)
          },
          fail: (error: any) => {
            reject(error)
          }
        })
      } else {
        reject(new Error('not support'))
      }
    })
  }
  getAuthCode({ corpId }: { corpId: string }): Promise<{ code: string }> {
    return new Promise((resolve, reject) => {
      if (tt.requestAccess) {
        console.error('飞书requestAccess：', { appID: corpId })
        tt.requestAccess({
          // 网页应用 App ID
          appID: corpId,
          scopeList: [],
          success: (res: any) => {
            const code = res.code
            console.error('飞书获取code成功：', code)
            // 用户授权后返回预授权码
            resolve(res)
          },
          fail: (error: any) => {
            console.error('飞书获取code失败：', error)
            // 需要额外根据errno判断是否为 客户端不支持requestAccess导致的失败
            const { errno, errString } = error
            if (errno === 103) {
              // 客户端版本过低，不支持requestAccess，需要改为调用requestAuthCode
              resolve(this.getAuthCodeByOldVersion({ corpId }))
            } else {
              // 用户拒绝授权或者授权失败
              reject(error)
            }
          }
        })
      } else {
        // JSSDK版本过低，不支持requestAccess，需要改为调用requestAuthCode
        resolve(this.getAuthCodeByOldVersion({ corpId }))
      }
    })
  }
  getLocation(): Promise<LocationResponse> {
    console.log('我调用了getLocation:', tt.getLocation)
    return callbackToPromise(tt.getLocation, { type: 'gcj02', accuracy: 'high' })
  }
  getHighLocation() {
    return callbackToPromise(tt.getLocation, { type: 'gcj02', accuracy: 'best' })
  }
  startLocation(): Promise<LocationResponse> {
    return new Promise((resolve, reject) => {
      callbackToPromise(tt.startLocationUpdate, { type: 'gcj02' })
        .then(() => {
          tt.onLocationChange(resolve)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }
  stopLocation() {
    return new Promise((resolve, reject) => {
      callbackToPromise(tt.stopLocationUpdate, { type: 'gcj02' })
        .then(() => {
          tt.offLocationChange()
          resolve(1)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }
  scanCode() {
    return callbackToPromise(tt.scanCode).then((res: any) => res?.result)
  }
  takePhoto(param?: any): Promise<string> {
    return new Promise((resolve, reject) => {
      callbackToPromise(tt.chooseImage, {
        sourceType: ['camera'],
        sizeType: ['compressed'],
        ...param
      })
        .then((res: any) => {
          const path = res.tempFilePaths?.[0]
          const fileSystemManager = tt.getFileSystemManager()
          callbackToPromise(fileSystemManager.readFile, {
            filePath: path,
            encoding: 'base64'
          })
            .then((res: any) => {
              console.error('文件转换', res)
              if (res.data) {
                const base64Data = res.data
                drawWatermark(`data:image/png;base64,${base64Data}`, param?.stickers)
                  .then(resolve)
                  .catch(reject)
              } else {
                reject(new Error('拍照失败'))
              }
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  setTitle(params?: any): Promise<void | {}> {
    // 无需实现，自动使用document.title
    return Promise.resolve()
  }
  getImageInfo(src?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      callbackToPromise(tt.getImageInfo, { src })
        .then((res: any) => {
          console.log('fileSystemManager.readFile:', res.path)
          const fileSystemManager = tt.getFileSystemManager()
          callbackToPromise(fileSystemManager.readFile, {
            filePath: res.path,
            encoding: 'base64'
          })
            .then((res: any) => resolve(`data:image/png;base64,${res.data}`))
            .catch(reject)
        })
        .catch(reject)
    })
  }
}
export default new Feishu()
