import { action, makeObservable, observable } from 'mobx'
import { createContext } from 'react'

import { DefaultState } from './const'
import { IFilterState, IFormState, IPageState, IStageState, ITableState } from './interface'
// import { getDictListGroup } from '../service'

class UnifiedStore {
  constructor() {
    makeObservable(this)
  }
  // page state
  @observable pageState: IPageState = {}
  // filter state
  @observable filterState: IFilterState = {}
  // table state
  @observable tableState: ITableState = {}
  // form state
  @observable formState: IFormState = {}
  // 缓存当前所有state
  @observable stageState: IStageState = DefaultState
  // 一个页面多个UnifieldPage 例: {modal: IStageState}
  @observable extraState: { [key: string]: IStageState } = {}

  @action
  setPageState(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraState[extraName].pageState = {
        ...this.extraState[extraName].pageState,
        ...state
      }
    } else {
      this.pageState = {
        ...this.pageState,
        ...state
      }
    }
  }
  @action
  setFilterState(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraState[extraName].filterState = {
        ...this.extraState[extraName].filterState,
        ...state
      }
    } else {
      this.filterState = {
        ...this.filterState,
        ...state
      }
    }
  }
  @action
  setTableState(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraState[extraName].tableState = {
        ...this.extraState[extraName].tableState,
        ...state
      }
    } else {
      this.tableState = {
        ...this.tableState,
        ...state
      }
    }
  }
  @action
  setFormState(state: { [key: string]: any }, extraName?: string) {
    if (extraName) {
      this.extraState[extraName].formState = {
        ...this.extraState[extraName].formState,
        ...state
      }
    } else {
      this.formState = {
        ...this.formState,
        ...state
      }
    }
  }
  /**
   * 全量设置并缓存
   */
  @action
  setState(state?: IStageState, extraName?: string) {
    if (extraName) {
      this.extraState[extraName] = state
        ? {
            ...this.extraState[extraName],
            ...state
          }
        : DefaultState
    } else if (state) {
      const { pageState, filterState, tableState, formState } = state
      this.pageState = pageState
      this.filterState = filterState
      this.tableState = tableState
      this.formState = formState
      this.stageState = state
    }
  }
  /**
   * 重置state到上一次缓存
   */
  @action
  reset() {
    const { pageState, filterState, tableState, formState } = this.stageState
    this.pageState = pageState
    this.filterState = filterState
    this.tableState = tableState
    this.formState = formState
    this.extraState = {}
  }
  /**
   * 清空state
   */
  @action
  clear() {
    this.pageState = {}
    this.filterState = {}
    this.tableState = {}
    this.formState = {}
    this.stageState = DefaultState
    this.extraState = {}
  }
}
export const unifiedStoreNoContext = new UnifiedStore()

export default createContext(unifiedStoreNoContext)
