import { commonFormJson } from '@/pages/Flow/services'

const useFetchCommonJson = () => {
  const runCommonFormJson = async (form_type: string) => {
    const data = await commonFormJson({ form_type })
    return data
  }

  return { runCommonFormJson }
}

export default useFetchCommonJson
