import { Image, Popup } from 'antd-mobile'
import { FC } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'

const ImagePreview: FC<any> = ({ src = '', size = [120, 120], ...rest }) => {
  const { open, onOpen, onCancel } = usePopup({})

  return (
    <>
      <Image src={src} onClick={onOpen} width={size?.[0]} height={size?.[1]} {...rest} />
      <Popup visible={open} onClose={onCancel}>
        <Image
          src={src}
          onClick={onCancel}
          width='100vw'
          height='100vh'
          fit='contain'
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
        />
      </Popup>
    </>
  )
}

export default ImagePreview
