import { FC } from 'react'

import { AvatarColor } from '@/components'

import AttendanceResult from '../AttendanceResult'
import CardItemTitle from '../CardItemTitle'

import './index.less'

const resultMap: any = {
  0: {
    className: 'warning',
    text: '待锁定'
  },
  1: {
    className: 'success',
    text: '已锁定'
  },
  2: {
    className: 'danger',
    text: '已结账'
  }
}
/**
 * 用户卡片
 */
interface IProps {
  tagDataSource?: any[]
  dataSource?: any
  [key: string]: string | any
}
const CardEmployee: FC<IProps> = ({
  tagDataSource = [],
  dataSource = {},
  showStatus = false,
  showTags
}: any) => {
  const currentStatus = resultMap[dataSource.status] || {}
  console.log(dataSource, 'dataSource')
  return (
    <div className='card-employee'>
      <div className='card-employee-inner'>
        <AvatarColor className='card-employee-avatar' name={dataSource?.employee_name} count={2} />
        <div className='card-employee-lt'>
          <CardItemTitle
            dataSource={dataSource}
            columns={[
              { label: '成本归属', value: 'cost_org_name' }
              // { label: '手机号', value: 'mobile' }
            ]}
          />
        </div>
        <div className='card-employee-gt'>
          {showStatus && (
            <div className={`card-employee-status ${currentStatus.className || ''}`}>
              {currentStatus.text || ''}
            </div>
          )}
        </div>
      </div>
      <>
        {showTags && <div className='card-employee-divider'></div>}
        {showTags && <AttendanceResult className='tag-list-wrap' dataSource={tagDataSource} />}
      </>
    </div>
  )
}

export default CardEmployee
