import React from 'react'
// import { Ellipsis } from 'antd-mobile'

import './index.less'

interface LogProps {
  operate_logs: any[]
}

const Log: React.FC<LogProps> = ({ operate_logs }: any) => {
  return (
    <div className='attendance-log'>
      {operate_logs?.length > 0 && <div className='attendance-log-title'>操作日志</div>}
      {operate_logs?.length > 0 &&
        operate_logs?.map((x: any, index: number) => (
          <div key={index} className='attendance-log-item'>
            {`${x?.time}${x?.content}`}
            {/* <Ellipsis
              direction='end'
              content={`${x?.time}${x?.content}`}
              expandText='展开'
              collapseText='收起'
            /> */}
          </div>
        ))}
    </div>
  )
}
export default Log
