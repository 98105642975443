import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'

import { hybStorage } from '../storage'

import { repReject, repResolve, reqReject, reqResolve } from './interceptors'

// 接口环境 map
export const apiEnvMap: Record<string, string> = {
  qa1: 'https://api-qa1.huiyunban.cn',
  qa2: 'https://api-qa2.huiyunban.cn',
  qa3: 'https://api-qa3.huiyunban.cn',
  qa4: 'https://api-qa4.huiyunban.cn',
  sandbox: 'https://api-sandbox.huiyunban.cn',
  formal: 'https://api.huiyunban.cn'
}

export function createAxios(options: AxiosRequestConfig = {}): AxiosInstance {
  console.log(1111111111, __ENV__)
  const defaultApiDomain = __ENV__
    ? apiEnvMap?.[__ENV__]
    : hybStorage.get('dev_env') === 'formal' ||
        window.location.href.indexOf('app.huiyunban.cn') > -1
      ? apiEnvMap.formal
      : apiEnvMap[hybStorage.get('dev_env') || 'qa1']
  const defaultOptions = {
    baseURL: defaultApiDomain || '/',
    timeout: 12000
  }
  const service: AxiosInstance = axios.create({
    ...defaultOptions,
    ...options
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  service.interceptors.request.use(reqResolve, reqReject)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  service.interceptors.response.use(repResolve, repReject)
  return service
}

export const defAxios = createAxios()

export const updateBaseURL = (env: string) => {
  defAxios.defaults.baseURL =
    hybStorage.get('dev_env') === 'formal' || window.location.href.indexOf('app.huiyunban.cn') > -1
      ? apiEnvMap.formal
      : apiEnvMap[env || 'qa1'] || '/'
}
export default defAxios
