import { createErrorBlock } from 'antd-mobile'
import type { FC, ReactElement } from 'react'

import { SvgIcon } from '@/components'
import './index.less'

const ErrorBlock = createErrorBlock({
  /** 未知错误 */
  default: <SvgIcon className='icon' name='no-data' />,
  /** 网络断开 */
  disconnected: <SvgIcon className='icon' name='no-network' />,
  /** 暂无数据 */
  empty: <SvgIcon className='icon' name='no-data' />,
  /** 搜索中 */
  busy: <SvgIcon className='icon' name='searching' />
})

const ERROR_MAP = {
  error: {
    title: '未知错误',
    image: <SvgIcon className='icon' name='no-data' />
  },
  empty: {
    title: '暂无数据',
    image: <SvgIcon className='icon' name='no-data' />
  },
  disconnected: {
    title: '网络断开',
    image: <SvgIcon className='icon' name='no-network' />
  },
  searching: {
    title: '搜索中',
    image: <SvgIcon className='icon' name='searching' />
  },
  success: {
    title: '保存成功',
    image: <SvgIcon className='icon' name='save-success' />
  },
  message: {
    title: '暂无消息',
    image: <SvgIcon className='icon' name='no-message' />
  },
  premission: {
    title: '暂无权限',
    image: <SvgIcon className='icon' name='no-permission' />
  },
  404: {
    title: '未找到访问页',
    image: <SvgIcon className='icon' name='404' />
  },
  502: {
    title: '服务器崩了',
    image: <SvgIcon className='icon' name='502' />
  }
} as const

type Status = keyof typeof ERROR_MAP

// 暂无数据
const HErrorBlock: FC<{
  children?: ReactElement
  className?: string
  status?: Status
  title?: string
  /** small: 无图; middle: 块级; large 页面级 */
  size?: 'small' | 'middle' | 'large'
  description?: string
}> = ({
  children,
  className = '',
  status = 'empty',
  size = 'middle',
  title,
  description = '',
  ...rest
}) => {
  const { title: tempTitle, image } = ERROR_MAP[status] || ERROR_MAP.empty
  return (
    <ErrorBlock
      className={`h-common-error-block h-common-error-block-${size} ${className}`}
      image={size === 'small' ? <></> : image}
      description={description}
      title={title || tempTitle}
      {...rest}
    >
      {children}
    </ErrorBlock>
  )
}

export default HErrorBlock
