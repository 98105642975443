import { useSetState } from 'ahooks'
import { InfiniteScroll, PullToRefresh, Checkbox } from 'antd-mobile'
import classNames from 'classnames'
import { uniq } from 'lodash-es'
import { cloneElement, ReactElement, useEffect, useLayoutEffect, useRef } from 'react'

import { HErrorBlock } from '@/components'
import { isNotEmptyArray } from '@/utils'

import CardItem from '../CardItem'

import './index.less'

/**
 * 滚动区域组件
 * @returns FC
 */
const ScrollList = ({
  className,
  style,
  before,
  after,
  dataSource,
  stateStore
}: {
  className?: string
  style?: any
  before?: ReactElement
  after?: ReactElement
  dataSource: {
    loading?: boolean | null
    noMore?: boolean
    total?: number
    dataList?: any[]
    refresh?: Function
    loadMore: (p?: any) => Promise<any>
    onPullUp?: (p?: any) => Promise<any>
    cardItem?: ReactElement
    renderItem?: (dataSource?: any, trigger?: any) => ReactElement
    bottom?: number
    [key: string]: any
  }
  stateStore?: {
    [x: string]: any
  }
}) => {
  const {
    noMore,
    loading,
    dataList,
    refresh,
    loadMore,
    onPullUp,
    cardItem = <CardItem />,
    renderItem,
    bottom,
    ...tableOptionRest
  } = dataSource
  const scrollEleRef = useRef(null)
  const noDateRef = useRef(null)
  const [{ marginTop, paddingTop }, setState] = useSetState({ marginTop: 0, paddingTop: 0 })

  const { row_selection, row_selection_code = 'id' } = tableOptionRest

  // 是否可批量操作
  const isRowSelection = typeof row_selection === 'function' ? row_selection() : !!row_selection

  // 选中的
  const { selectedKeys = [] } = stateStore?.tableState || {}

  const renderItemList = () => {
    return dataList?.map((_: any, index) => {
      const extraData = {
        key: `${_.id}_${index}`,
        dataSource: _,
        refresh,
        index,
        isRowSelection,
        ...tableOptionRest
      }
      return renderItem
        ? renderItem(_, { key: extraData.key, refresh, index, isRowSelection, ...tableOptionRest })
        : cloneElement(cardItem, extraData)
    })
  }

  const hasData = isNotEmptyArray(dataList)

  // 获取安全区的高度，包括顶部和底部的安全区
  const getSafeAreaHeight = () => {
    const docEle = getComputedStyle(document.documentElement)
    const topInset = parseFloat(docEle.getPropertyValue('env(safe-area-inset-top)')) || 0
    const bottomInset = parseFloat(docEle.getPropertyValue('env(safe-area-inset-bottom)')) || 0
    return window.innerHeight - topInset - bottomInset
  }

  const calcMarginTop = () => {
    const jsUnifiedFilter = document.getElementById('unifiedFilterWrap')
    if (scrollEleRef?.current) {
      const { offsetHeight: filterHeight = 0 } = jsUnifiedFilter || {}
      const { offsetHeight } = noDateRef.current || {}
      const clientHeight = getSafeAreaHeight()

      setState({
        paddingTop: filterHeight,
        marginTop: (clientHeight - (jsUnifiedFilter?.offsetHeight || 0) - offsetHeight || 0) / 2
      })
    }
  }

  useLayoutEffect(() => {
    if (!hasData && loading === false) {
      calcMarginTop()
    }
  }, [loading, hasData])

  useLayoutEffect(() => {
    const jsUnifiedFilter = document.getElementById('unifiedFilterWrap')
    // ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      calcMarginTop()
    })

    if (jsUnifiedFilter) {
      resizeObserver.observe(jsUnifiedFilter)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div className='unified-scroll-list-wrap' style={{ paddingTop: paddingTop }} ref={scrollEleRef}>
      <PullToRefresh
        onRefresh={() => {
          onPullUp?.()
          return refresh?.()
        }}
      >
        <div
          className={classNames('scroll-list-wrapper', className, {
            'scroll-list-no-data-wrapper': !hasData
          })}
          style={style}
        >
          {before}

          {isRowSelection ? (
            <Checkbox.Group
              value={selectedKeys}
              onChange={(values) => {
                const tempValues = uniq(values)
                stateStore?.setTableState?.({
                  selectedKeys: tempValues,
                  selectedItems: dataList?.filter((item) =>
                    tempValues.includes(item[row_selection_code])
                  )
                })
              }}
            >
              {renderItemList()}
            </Checkbox.Group>
          ) : (
            renderItemList()
          )}

          {after}

          {/* {noMore ? <span>--- 我是有底线的 ---</span> : null} */}
          {hasData ? (
            <InfiniteScroll hasMore={!noMore} loadMore={loadMore} threshold={300} />
          ) : (
            loading === false && (
              <div
                className='unified-scroll-list-no-data'
                ref={noDateRef}
                style={{ marginTop: `${marginTop}px` }}
              >
                <HErrorBlock size='large' />
              </div>
            )
          )}
        </div>
      </PullToRefresh>
    </div>
  )
}

export default ScrollList
