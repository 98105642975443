export const APP_NAME = '慧云班人效云'

// product
export const PRD_HOST = 'app.huiyunban.cn'

// 接口环境 map
export const apiEnvMap: Record<string, string> = {
  qa1: 'https://api-qa1.huiyunban.cn',
  qa2: 'https://api-qa2.huiyunban.cn',
  qa3: 'https://api-qa3.huiyunban.cn',
  qa4: 'https://api-qa4.huiyunban.cn',
  sandbox: 'https://api-sandbox.huiyunban.cn',
  formal: 'https://api.huiyunban.cn'
}
