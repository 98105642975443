/* eslint-disable react-hooks/exhaustive-deps */
import { useSetState } from 'ahooks'
import { Form } from 'antd-mobile'
import { toJS } from 'mobx'
// import { FormInstance } from 'antd-mobile/es/components/form'
import { useLocalStore, observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'

import EditForm from '@/components/NewEditForm'

// import comstore from '@/pages/Component/Store'

import flowStore from '@/pages/Flow/Store'
import { FiledArrayCalculate } from '@/utils/index'
import { storage } from '@/utils/storage'

import { json } from './enum'
import { movementFieldApi, movementFieldValueApi } from './services'

import './index.less'

interface ReshuffleState {
  filed: any[]
  filedValue: string
  filedValueList: any[]
  loding: boolean
}
interface ReshuffleProps {
  userId: string
  onClose: () => void
  is_show: boolean
}
const ChangedFieldName: string[] = ['POST', 'HRM_POSITION', 'POSITION_RANK']
const ReshuffleFrom: React.FC<ReshuffleProps> = (props) => {
  const { userId = '', is_show = true, onClose } = props
  const employee_id = storage.get('userData')?.employee_id || ''
  // const [formIt] = Form.useForm()
  const [formIt] = Form.useForm()
  const formRef = useRef<any>()
  const { reshuffleStatus, changeReshuffle, reshuffle } = useLocalStore(() => flowStore)
  const [{ filed, filedValueList, filedValue, loding }, setState] = useSetState<ReshuffleState>({
    filed: [],
    filedValue: '',
    filedValueList: [],
    loding: false
  })
  const fetchmovementField = async () => {
    setState({ loding: true })
    const { data } = await movementFieldApi()
    setState({ filed: data, loding: false })
    const setFieldValues = () => {
      console.log(reshuffleStatus, 'reshuffleStatus---->>>>')
      const fields = reshuffle?.find((x: any) => x?.active)
      const fieldValues: any = {
        original_value: fields?.original_value_name,
        changed_value: FiledArrayCalculate(fields?.changed_value, [
          { name: fields?.changed_value_name, id: fields?.changed_value }
        ])
      }
      setState({ filedValue: fields?.movement_field })
      // 岗位 职位 职级
      if (ChangedFieldName.includes(fields?.movement_field)) {
        fieldValues.changed_value = {
          [fields?.movement_field]: [
            {
              name: fields?.changed_value_name,
              id: fields?.changed_value
            }
          ]
        }
      }
      fields?.movement_field && (fieldValues.movement_field = fields?.movement_field)
      formIt.setFieldsValue(fieldValues)
    }
    reshuffleStatus === 'Edit' && setFieldValues()
  }

  const fetchMovementFieldValue = async (userId?: any) => {
    const { data, code } = await movementFieldValueApi(userId || employee_id)
    if (code === 2000) {
      setState({ filedValueList: data })
    }
  }

  const deleteInfo = () => {
    reshuffleStatus === 'Edit' && changeReshuffle(reshuffle.filter((x) => !x.active))
    // Taro.navigateBack()
    onClose()
  }

  const onFinish = (fieldValues: any) => {
    const { movement_field, original_value, changed_value } = fieldValues
    console.log(fieldValues, movement_field, 'fieldValues', filed, filedValueList)
    const field = {
      ...fieldValues,
      movement_field_name: filed?.find((x) => x.type === movement_field)?.type_name,
      original_value_name: original_value,
      original_value: filedValueList.find((x) => x.movement_field === movement_field)
        ?.original_value_id,

      changed_value:
        filedValue === 'ORG'
          ? changed_value?.[0].id
          : ChangedFieldName.includes(filedValue)
            ? changed_value?.[filedValue]
            : changed_value.changed_value,

      changed_value_name:
        filedValue === 'ORG'
          ? changed_value?.[0].name
          : ChangedFieldName.includes(filedValue)
            ? filed
              .find((x) => x.type === movement_field)
              ?.details?.find((y) => y.value === changed_value?.[filedValue])?.name
            : filed
                .find((x) => x.type === movement_field)
                ?.details?.find((y) => y.value === changed_value.changed_value)?.name
    }
    console.log(field, 'params:')

    reshuffleStatus === 'Add' && changeReshuffle([...reshuffle, field])

    reshuffleStatus === 'Edit' &&
      changeReshuffle(
        reshuffle.map((x: any) => {
          if (x.active) return field
          return x
        })
      )
    onClose()
  }
  const handleChange = (event: any) => {
    setState({ filedValue: event })
    formIt.setFieldsValue({ changed_value: '' })
  }

  useEffect(() => {
    fetchmovementField()
    if (is_show && userId) {
      fetchMovementFieldValue(userId)
    } else if (!is_show) {
      fetchMovementFieldValue()
    }
  }, [])
  const saveSubmit = () => {
    formIt.submit()
  }
  useEffect(() => {
    const value: string = filedValueList.find(
      (x) => x.movement_field === filedValue
    )?.original_value
    console.log(value, 'value---234324', filedValueList, formIt)
    formIt.setFieldsValue({ original_value: value || '-' })
  }, [filedValue, filedValueList, reshuffleStatus])

  console.log(filedValueList, 'Reshuffleform>>>>', filedValue)

  return (
    <div className='reshuffle-edit-form'>
      <EditForm
        // className='reshuffle-edit-form'
        layout='horizontal'
        formInfo={json({
          filed,
          filedValue,
          handleChange,
          reshuffleStatus
        })}
        ref={formRef}
        // labelWidth={200}
        formInst={formIt}
        onFinish={onFinish}
      />
      <div onClick={saveSubmit} className='save-form-button'>
        保存
      </div>
      {reshuffleStatus === 'Edit' && (
        <div onClick={deleteInfo} className='delete-form-button'>
          删除
        </div>
      )}
    </div>
  )
}

export default observer(ReshuffleFrom)
