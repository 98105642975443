import { http } from '@/utils'
const ApplicationFlowMapUrl: any = {
  ASK_FOR_LEAVE: 'leave',
  REISSUE_CLOCK: 'clock',
  ASSIST: 'assist',
  BUSINESS_TRIP: 'business_trip',
  OVERTIME: 'overtime',
  SYSTEM_CHANGE: 'change',
  SYSTEM_DEMOTION: 'change',
  SYSTEM_PROMOTION: 'change',
  SYSTEM_JOB_TRANSFER: 'change',
  SYSTEM_RESIGNATION: 'resignation',
  SYSTEM_TRANSFER: 'transfer',
  SYSTEM_EMPLOYMENT: 'employment',
  TIAO_SCHEDULE: 'change_schedule',
  HUAN_SCHEDULE: 'exchange_schedule'
}
export const commonFormJson = (data: any): any => http.get(`/common/form_json`, { params: data })
export const employeeSelectorApi = (data: any): any =>
  http.post(`/selector/`, { data: { selector_code: 'employee', ...data } })

export const getemployeeApi = (data: any): any => http.post(`/hrm/employees`, { data })

export const alcHourApi = (data: any): any =>
  http.post(`/personal/center/application/calc/hour`, data)

export const applicationDurationApi = (data: any): any =>
  http.post(`/personal/center/application/duration`, data)

export const stockTotalApi = (data: any): any => http.post(`/attendance/stock/stock/total`, data)

export const applicationLeaveApi = (data: any): any => {
  const { type, applyItem, ...params } = data
  return http.post(`/personal/center/application/${ApplicationFlowMapUrl[type]}`, params)
}

export const workFlowTreeApi = (data: any): any => http.post(`/workflow/apply/projects/tree`, data)

export const workFlowApplyApi = (data: any): any =>
  http.post(`/workflow/form/employee`, { ...data, client: 2 })

export const applyUnreadCountApi = (): any => http.post(`/workflow/form/unread_count`, {})

export const flowFormBatchApi = (data: any): any => http.put(`/workflow/form/batch`, data)

export const flowFormSubmitApi = (data: any): any => http.post(`/workflow/form/submit`, data)

export const flowFormUrgeApi = (data: any): any => http.post(`/workflow/form/urge`, data)

export const flowDetailApi = (data: any, params: Record<string, any>): any =>
  http.get(`/workflow/form/${data}`, { params })

export const workFlowAppravalApi = (data: any): any =>
  http.post(`/workflow/approvals`, { ...data, client: 2 })

export const approvalUnreadCountApi = (): any => http.post(`/workflow/unread_count`, {})

export const approvalOperateApi = (data: any): any => http.put(`/workflow/form/batch/approve`, data)

export const workflowInstApi = (data: any): any => http.get(`/workflow/inst/${data}?type=1`, {})

export const appendNodeApi = (data: any): any => http.post(`/workflow/node/append`, data)

export const transferNodeApi = (data: any): any => http.post(`/workflow/node/transfer`, data)

export const revertNodeApi = (data: any): any => http.post(`/workflow/node/revert`, data)

export const flowApplyProjectApi = (data: any): any =>
  http.get(`/workflow/apply/project/${data}`, {})
// 详情 是否复制 /workflow/apply/project/{id}
export const workflowApplyProject = (data: any, id: any) =>
  http.get(`/workflow/apply/project/${id || ''}`, { params: data })

export const movementFieldApi = (): any => http.get(`/hrm/movement/field`, {})

export const movementFieldValueApi = (employeeId: string): any =>
  http.get(`/hrm/movement/field/value?employeeId=${employeeId}`, {})

export const employeeDetailApi = (id: any): any => http.get(`/hrm/employee/${id}`, {})

export const organizationsChild = (data: any): any => http.post(`/hrm/organizations/child`, data)

export const postSearchApi = (data: any) => http.post('/hrm/post/search', data)

export const getScheduleList = (): IResponseType<any> =>
  http.get(`/schedule/shift`, {
    params: { paged: false, enable_status: 1, add_rest_shift: true, with_no_auth: true }
  })

export const getEmployeeSchedule = (data: any): IResponseType<any> =>
  http.post(`/schedule/employee/actual`, { ...data, add_rest_shift: true })
