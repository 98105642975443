import { useSetState } from 'ahooks'
import { Popup } from 'antd-mobile'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useRef } from 'react'
import { Fragment } from 'react/jsx-runtime'

import { SvgIcon } from '@/components'
import RenderNode from '@/components/NewEditForm/renderNode'
import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import unifiedStore from '@/store/unified/unifiedStore'
import { isNotEmptyArray, toPx } from '@/utils'

import BtnGroup from '../BtnGroup'

// function useOutsideAlerter(ref, callback) {
//   useEffect(() => {
//     /**
//      * Alert if clicked on outside of element
//      */
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         typeof callback === 'function' && callback(event.target)
//       }
//     }
//     // Bind the event listener
//     document.addEventListener('mousedown', handleClickOutside)
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener('mousedown', handleClickOutside)
//     }
//   }, [ref])
// }

export default observer(
  ({
    // value,
    onConFirm,
    form,
    form_list,
    globalProps
  }: {
    value?: any
    onConFirm: (value: any) => void
    form: any
    form_list: any[]
    globalProps?: any
  }) => {
    const wrapperRef = useRef(null)
    const btnRef = useRef(null)
    const stateStore = useContext(unifiedStore)
    const [{ formValue }, setState] = useSetState<any>({
      formValue: {}
    })
    const { open, modalData, onOpen, onCancel } = usePopup({
      afterOk: () => {
        onConFirm(modalData.keyword)
        return Promise.resolve()
      }
    })
    const handleChange = (changed: any) => {
      console.log('handleChange:', changed)
      setState({
        formValue: { ...formValue, ...changed }
      })
    }

    // 表单字短名称
    const fieldNameList = isNotEmptyArray(form_list)
      ? form_list
          .map((fieldItem) => fieldItem?.formItemProps?.name || fieldItem?.name)
          .filter((key) => !!key)
      : []

    // 是否有表单数据
    const hasFieldValues = fieldNameList.some((fieldName) => {
      const { filterValues } = stateStore.filterState || {}
      return !isNil(filterValues?.[fieldName])
    })

    // useOutsideAlerter(wrapperRef, (target) => {
    //   if (btnRef.current && !btnRef.current.contains(target)) {
    //     onCancel()
    //   }
    // })

    console.log('expand:', form_list, stateStore.filterState?.filterValues)
    const handleNext = () => {
      stateStore.setFilterState({
        filterValues: { ...stateStore.filterState?.filterValues, ...formValue }
      })
      onCancel()
    }
    const onOkFrist = () => {
      const params = form_list?.reduce(
        (acc: any, fieldItem: any) => {
          const name = fieldItem?.formItemProps?.name || fieldItem?.name
          return { ...acc, [name]: null }
        },
        {} as Record<string, null>
      )
      console.log(params, 'yyyyy')
      stateStore.setFilterState({
        filterValues: { ...stateStore.filterState?.filterValues, ...params }
      })
      setState({
        formValue: {}
      })
    }
    return (
      <>
        <div
          ref={btnRef}
          className={`unified-filter-icon-wrap ${hasFieldValues ? 'activated' : ''}`}
          onClick={() => {
            open ? onCancel() : onOpen()
          }}
        >
          <SvgIcon className='filter-icon' name={`filter${hasFieldValues ? '-color' : ''}`} />
        </div>
        <Popup
          visible={open}
          closeOnMaskClick
          position='top'
          getContainer={document.getElementById('unifiedFilterWrap')}
          className='unified-filter-expand-popup'
          onClose={() => onCancel?.()}
        >
          <div className='unified-filter-expand-wrap' ref={wrapperRef}>
            <div className='unified-filter-expand'>
              {form_list.map((x, index) => (
                <Fragment key={index}>
                  {!globalProps?.hideExec(x?.hide, {}, {}) ? (
                    <div
                      key={index}
                      className={classnames('unified-filter-expand-title', {
                        'button-group': x?.type === 'ButtonGroup'
                      })}
                    >
                      <div className='unified-filter-expand-title-text'>
                        {x?.formItemProps?.label || x?.label}
                      </div>
                      <RenderNode
                        type={x.type}
                        elementProps={{
                          ...(x?.elementProps || {}),
                          ...(x?.disabled ? { disabled: x.disabled } : {}),
                          form,
                          value: formValue?.[x?.formItemProps?.name || x?.name],
                          onConfirm: (v: any) =>
                            handleChange({
                              [x?.formItemProps?.name || x?.name]: v
                            }),
                          globalProps: {
                            state: {},
                            option: {},
                            onAfterChangeExec: globalProps?.onAfterChangeExec
                          }
                        }}
                      />
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </div>
            <BtnGroup
              onOkFrist={() => onOkFrist()}
              onOkNext={() => handleNext()}
              text={['重 置', '筛 选']}
              nextLoading={false}
              style={[
                {
                  background: '#fff',
                  color: '#595960',
                  border: '1px solid #E8E8E9'
                },
                {
                  background: '#0256FF'
                }
              ]}
              code={['', '']}
            />
          </div>
        </Popup>
      </>
    )
  }
)
