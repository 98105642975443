import { Image } from 'antd-mobile'
import { useLocalStore, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import HPopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup'
import flowStore from '@/pages/Flow/Store'

import ReshuffleFrom from './ReshuffleFrom'

import './index.less'

const Reshuffle: React.FC<any> = ({ onConfirm, value, ...elementProps }) => {
  const fstore = useLocalStore(() => flowStore)
  const { changeReshuffle, changeReshuffleStatus } = fstore
  const [visible, setVisible] = useState(false)
  const add = () => {
    if (elementProps?.disabled) return
    changeReshuffleStatus('Add')
    setVisible(true)
  }

  const edit = (index: number) => {
    fstore.reshuffle.map((x: any, i: number) => {
      if (index === i) {
        x.active = 1
      } else x.active = 0
      return x
    })
    changeReshuffleStatus('Edit')
    setVisible(true)
  }

  useEffect(() => {
    if (onConfirm && fstore.reshuffle?.length) {
      onConfirm(fstore.reshuffle)
    }
  }, [fstore.reshuffle])

  useEffect(() => {
    changeReshuffle(value || [])
  }, [value])
  const onClose = () => {
    setVisible(false)
  }

  const _bool: boolean = !elementProps?.disabled && !elementProps?.readed

  return (
    <>
      <div className='reshuffle'>
        {fstore.reshuffle.map((x: any, index: number) => (
          <div key={index} className='reshuffle-content'>
            <div className='reshuffle-content-top'>
              <div className={`reshuffle-content-top-left`}>
                变更为：{x.changed_value_name || '-'}
              </div>
              <div className='reshuffle-content-top-right'>
                {_bool && (
                  <Image
                    onClick={() => edit(index)}
                    className='reshuffle-content-col-2-img'
                    src='https://s.huiyunban.cn/1747191709529280512.png'
                  />
                )}
              </div>
            </div>
            <div className='reshuffle-content-bottom'>
              {x.movement_field_name || '-'}： {x.original_value_name || '-'}
            </div>
          </div>
        ))}
        {!elementProps?.disabled && (
          <div className='reshuffle-tip'>
            <Image
              className='reshuffle-tip-img'
              src='https://s.huiyunban.cn/1747096890261901312.png'
            />
            <div className='reshuffle-tip-title' onClick={() => _bool && add()}>
              添加异动项
            </div>
          </div>
        )}
      </div>
      <HPopup
        title='选择异动项'
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '90vh'
        }}
        visible={visible}
        onCancel={onClose}
        hasOk={false}
      >
        {visible && (
          <ReshuffleFrom
            userId={elementProps?.userId || ''}
            is_show={elementProps?.is_show || ''}
            onClose={onClose}
            // onOk={onOk}
          />
        )}
      </HPopup>
    </>
  )
}

export default observer(Reshuffle)
